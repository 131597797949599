import { useSuspenseQuery } from '@tanstack/react-query';
import { getRouteApi, useRouteContext } from '@tanstack/react-router';
import { useCallback } from 'react';
import { AccountDto } from '@/api/services/account';
import { selectCurrentAccount, UserMeDto, userMeQueryOptions } from '@/api/services/user';

/**
 * Returns the context available in the Root route
 */
export const useRootContext = () => useRouteContext({ from: '__root__' });

/**
 * Returns the current authentication context from the router state.
 */
export const useAuth = () => useRootContext().auth;

export type CurrentAccountUser = UserMeDto & { account: AccountDto };

/**
 * Returns the current account from the /users/me endpoint matching from the accountId.
 *
 * Important: It presumes data to always be present in cache as it is being loaded within a route for the current accountId before this hook is called,
 * otherwise suspense boundaries will be triggered.
 */
export const useAccountUser = (): CurrentAccountUser => {
  const accountId = useAccountId();
  const select = useCallback(
    (data: UserMeDto) => ({ ...data, account: selectCurrentAccount(data, accountId) }),
    [accountId],
  );

  const { data } = useSuspenseQuery({ ...userMeQueryOptions, select });

  return data;
};

const { useParams } = getRouteApi('/authenticated/accounts/$accountId');
const selectAccountIdOpts = { select: (s: { accountId: string }) => s.accountId };
/** Returns the current account ID from the /users/me endpoint. */
export const useAccountId = () => useParams(selectAccountIdOpts);
