import { z } from 'zod';
import {
  ClusterJwtCreateDto,
  ClusterJwtDto,
  ClusterJwtPayloadAccess,
  TokenPayload,
  TokenPayloadAccess,
} from '@/api/services/cluster';
import {
  convertDaysToMS,
  formatDateTimeWithOffset,
  getDateFromUnixTimestamp,
  getMsTimestampFromUnixTimestamp,
  getUnixTimestampFromMs,
} from '@/utils/date-utils';
import { validators } from '@/utils/form-utils';
import { keyValuePairValidator, mapValueKeyPairArrayToObj } from '@/utils/kubernetes-utils';
import { UNREACHABLE_CLUSTER_MSG } from './collections-context';

export const MIN_CLUSTER_VERSION_FOR_RBAC = '1.11.0';

/**
 * Token has cluster-wide access when `access` is not an array of collections
 */
export const hasCollectionAccess = (
  payload: TokenPayload,
): payload is { access: ClusterJwtPayloadAccess[]; exp: number } => Array.isArray(payload.access);

export const getExpiresAt = (token: ClusterJwtDto): string =>
  token.jwt_payload.exp ? formatDateTimeWithOffset({ date: getDateFromUnixTimestamp(token.jwt_payload.exp) }) : '-';

function getCollectionAccessString(access: string) {
  if (access === 'r') {
    return 'READ';
  } else if (access === 'rw') {
    return 'READ/WRITE';
  }
  return '';
}

export function getClusterAccessString(access: TokenPayloadAccess): string {
  if (access === 'r') {
    return 'Read';
  } else if (access === 'm') {
    return 'Manage';
  }
  return '';
}

type TokenStatus = 'Active' | 'Expired' | 'Deleted';
export const getTokenStatusData = (
  token: ClusterJwtDto,
): { status: TokenStatus; color: 'error' | 'warning' | 'success' } => {
  const { jwt_payload: payload } = token;
  const { exp } = payload;
  const now = Date.now();
  if (exp && getMsTimestampFromUnixTimestamp(exp) < now) {
    return { status: 'Expired', color: 'error' };
  }
  return { status: 'Active', color: 'success' };
};

export function getCollectionAccessDataName(collectionData: NonNullable<ClusterJwtPayloadAccess>) {
  return `${getCollectionAccessString(collectionData.access)} ${collectionData.collection}`;
}

export const collectionsSchemaValidator = z.object({
  name: z.string().min(1),
  allowedOperations: z.enum(['rw', 'r']),
  payload: keyValuePairValidator.optional(),
});
export type CollectionsSchema = z.infer<typeof collectionsSchemaValidator>;

/**
 * Max Token Expiry was arbitrarily chosen to put a size limit on the input. And 10 years should suffice.
 */
export const MAX_TOKEN_EXPIRY_DAYS = 3650; // ~ 10 YEARS
export const tokenSchemaValidator = z
  .object({
    // Token name follows the same restrictions as the Cluster name.
    name: validators.clusterName,
    // When defining an optional number with a minimum, it has to be done through `refine` with a
    // string as an entry point (and then parsed inside), to prevent early validation failures.
    // At the same time this has to be combined with a `Controlled`
    expiration: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value == null || value === '') {
            return true;
          }
          const parsedInt = parseInt(value);
          if (isNaN(parsedInt)) {
            return false;
          }

          // To prevent values such as '1.1', '1e3' or '1abcde#' from being accepted, as parse int will return a number
          if (parsedInt.toString() !== value) {
            return false;
          }

          if (parsedInt < 1 || parsedInt > MAX_TOKEN_EXPIRY_DAYS) {
            return false;
          }
          return true;
        },
        { message: `Value must be an integer between 1 and ${MAX_TOKEN_EXPIRY_DAYS} when defined` },
      ),
    accessControl: z.enum(['clusterWide', 'perCollection'], {
      message: "Invalid accessControl, should be 'clusterWide' | 'perCollection'",
    }),
    allowedOperations: z.enum(['m', 'r']).optional(),
    collections: z.array(collectionsSchemaValidator).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.accessControl === 'perCollection') {
      if (!data.collections?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one collection is required',
          path: ['collections'],
        });
      }
    } else {
      if (!data.allowedOperations) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Allowed operations are required',
          path: ['allowedOperations'],
        });
      }
    }
  });

export type TokenSchemaType = z.infer<typeof tokenSchemaValidator>;

const getTokenAccess = (data: TokenSchemaType): TokenPayloadAccess | undefined => {
  if (data.accessControl === 'clusterWide') {
    return data.allowedOperations;
  }

  if (!data.collections) {
    return undefined;
  }

  return data.collections.map((collection) => {
    let payload = mapValueKeyPairArrayToObj(collection.payload, true);
    if (payload && Object.keys(payload).length === 0) {
      payload = undefined;
    }
    return {
      collection: collection.name,
      access: collection.allowedOperations,
      payload,
    };
  });
};

export const getTokenPayload = (data: TokenSchemaType): ClusterJwtCreateDto | undefined => {
  if (data.accessControl === 'perCollection' && !data.collections) {
    return undefined;
  }
  const access = getTokenAccess(data);

  // When access is not defined, the token is not valid.
  if (!access) {
    return undefined;
  }

  const exp = data.expiration ? parseInt(data.expiration) : undefined;
  return {
    name: data.name,
    jwt_payload: {
      access,
      // Expiration (exp) is in unix timestamp format (seconds)
      ...(exp ? { exp: Math.floor(getUnixTimestampFromMs(Date.now() + convertDaysToMS(exp))) } : {}),
    },
  };
};

export const getUnavailableCollectionsReason = (hasError: boolean, clusterIsNotReachable: boolean): string => {
  if (hasError) {
    return 'There was a problem retrieving the collections.';
  } else if (clusterIsNotReachable) {
    return UNREACHABLE_CLUSTER_MSG;
  }
  return 'No collections are available.';
};
