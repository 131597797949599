import { Alert, MenuItem, Select } from '@mui/material';
import { memo, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { ClusterDto } from '@/api/services/cluster';
import { AccountPrivileges } from '@/utils/access-control';
import { getMaxAllowedNodes, isValidConfigForDownscalingHorizontally } from '../helpers';
import { useClusterConfig, useClusterConfigUpdater, useDefaultClusterConfig } from './ClusterConfigurationContext';
import { QdrantConfigurationSchema } from './QdrantConfigurationContext';

export const MIN_NODES = 1;

export const ClusterNodesControl = ({
  cluster,
  disabled,
  accountPrivileges,
}: {
  cluster?: ClusterDto;
  disabled?: boolean;
  accountPrivileges: AccountPrivileges;
}) => {
  const { nodes } = useClusterConfig();
  let { nodes: defaultNodes } = useDefaultClusterConfig();

  if (isValidConfigForDownscalingHorizontally(cluster)) {
    defaultNodes = 1;
  }

  const { getValues } = useFormContext<QdrantConfigurationSchema>();
  const replicationFactor = getValues('collection.replication_factor');
  const haEnforced = Boolean(replicationFactor && replicationFactor > 1);
  const startValue = Math.max(MIN_NODES, defaultNodes ?? 0, haEnforced ? 2 : 0);

  return (
    <ClusterNodesControlImpl
      startValue={startValue}
      value={nodes}
      haEnforced={haEnforced}
      disabled={disabled}
      accountPrivileges={accountPrivileges}
    />
  );
};

const ClusterNodesControlImpl = memo(function ClusterNodesControlImpl({
  value,
  startValue,
  haEnforced,
  disabled,
  accountPrivileges,
}: {
  value: number;
  startValue: number;
  haEnforced: boolean;
  disabled?: boolean;
  accountPrivileges: AccountPrivileges;
}) {
  const update = useClusterConfigUpdater();

  const max_nodes = getMaxAllowedNodes(accountPrivileges);
  if (value > max_nodes) {
    return (
      <>
        <Select value={value} variant="outlined" disabled={true} labelId="cluster-advanced-configuration-nodes-label">
          <MenuItem value={value}>{value} nodes</MenuItem>
        </Select>
        <Alert severity="info" sx={{ mt: 2 }}>
          Number of nodes exceeded. Please contact support if you need more nodes.
        </Alert>
      </>
    );
  }

  const menuItems: ReactElement[] = [];
  for (let i = startValue; i <= max_nodes; i++) {
    menuItems.push(
      <MenuItem value={i} key={i}>
        {`${i} node${i > 1 ? 's' : ''}`}
      </MenuItem>,
    );
  }

  return (
    <>
      <Select<number>
        value={value}
        variant="outlined"
        onChange={(event) => {
          const value = event.target.value as number;
          update({ type: 'nodes', value });
        }}
        disabled={disabled}
        labelId="cluster-advanced-configuration-nodes-label"
      >
        {menuItems}
      </Select>
      {haEnforced && value < 3 && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          Configuring less than 3 nodes is not fully highly available. If one node fails, your cluster will be in a
          degraded state. You will still be able to read and write data, but not make configuration changes.
        </Alert>
      )}
    </>
  );
});
