import { Theme } from '@mui/material';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    white: string;
  }
}

/**
 * returns a function that a style object containing filter property with invert and grayscale filters
 * used on providers images to invert them when the theme is dark
 * and grayscale them when they are inside a disabled button
 */
export const imageFilters = (theme: Theme, isDisabled = false, themed = true) => {
  const invertFilter = themed && theme.palette.mode === 'dark' ? 'invert(1)' : '';
  const greyScaleFilter = isDisabled ? 'grayscale(1)' : '';
  const filter = `${invertFilter} ${greyScaleFilter}`;

  return { filter };
};
