import { AuthorizationParams } from '@auth0/auth0-react';
import { createRoute, redirect } from '@tanstack/react-router';
import { settingsQueryOptions } from '@/api/services/public';
import { head } from '@/router/Head';
import { retrieveUtmParamsSession } from '@/utils/analytics-utils';
import { authRedirectUrl, BASE_URL, qdrantCloudUrl } from '@/utils/constants';
import { captureException } from '@/utils/error-utils';
import { preferredHostForCDN } from '@/utils/url-utils';
import { Route as RootRoute } from '../__root';

type CustomAuthorizationParams = AuthorizationParams & {
  'ext-back_link': string;
  'ext-gtm_id': string;
  'ext-host': string;
  'ext-incident_mode'?: string;
  'ext-aerr'?: string;
  'ext-sso'?: string;
};

export const Route = createRoute({
  head,
  staticData: {
    title: 'Signing up ...',
  },
  getParentRoute: () => RootRoute,
  path: 'signup',
  beforeLoad: async ({ context: { auth, queryClient } }) => {
    if (auth.isAuthenticated) {
      throw redirect({ to: authRedirectUrl.pathname, replace: true });
    }

    const authParams: CustomAuthorizationParams = {
      'ext-back_link': BASE_URL,
      'ext-gtm_id': window.__QDRANT_CLOUD__.gtm_id,
      'ext-host': preferredHostForCDN(qdrantCloudUrl.host),
    };
    // Add utm custom params
    Object.entries(retrieveUtmParamsSession()).forEach(([key, value]) => {
      authParams[`ext-${key}`] = value;
    });

    // The `incident_mode` custom parameter is set when INCIDENT_MODE is active via /settings.json
    const { incident } = await queryClient.fetchQuery(settingsQueryOptions);
    if (incident) {
      authParams['ext-incident_mode'] = 'true';
    }

    try {
      await auth.loginWithRedirect({
        authorizationParams: {
          audience: window.__QDRANT_CLOUD__.auth0.audience,
          scope: window.__QDRANT_CLOUD__.auth0.scope,
          redirect_uri: authRedirectUrl.href,
          screen_hint: 'signup',
          ...authParams,
        },
      });
    } catch (error) {
      captureException(error);
      throw error;
    }
  },
});
