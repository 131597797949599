import { ReactNode } from 'react';
import { useAccessControl } from '@/hooks/useAccessControl';
import { AccountPermissions } from '@/utils/access-control';

export function WithPermissions<
  TPermissions extends AccountPermissions,
  TShow extends 'match' | 'always' = 'match',
>(props: {
  show?: TShow;
  permissions: TPermissions;
  render: TShow extends 'always'
    ? (props: { matches: true; permissions: unknown[] } | { matches: false; permissions: TPermissions }) => ReactNode
    : () => ReactNode;
}) {
  const { render, show = 'match' as TShow } = props;

  const { match: matches, ...missing } = useAccessControl({
    permissions: props.permissions,
  });

  const permissions = ('permissions' in missing ? (missing.permissions ?? []) : []) as TPermissions;

  if (show === 'always') {
    return render({
      matches,
      permissions,
    });
  }

  return matches ? render(undefined as never) : null;
}
