import { createRoute, redirect } from '@tanstack/react-router';
import { getAccountRolesQuery } from '@/api/services/access-management';
import { getUsersByAccountIdQueryOptions } from '@/api/services/user';
import { Route as CloudAccessRoute } from '../cloud-access_';

export const Route = createRoute({
  getParentRoute: () => CloudAccessRoute,
  path: 'roles',
  context: ({ params: { accountId } }) => ({
    rolesQueryOptions: getAccountRolesQuery({ account_id: accountId }),
    userQueryOption: getUsersByAccountIdQueryOptions({ account_id: accountId }),
  }),
  beforeLoad: ({ params, ...rest }) => {
    const isExactMatch = rest.matches.at(-1)?.routeId === Route.id;

    if (isExactMatch) {
      throw redirect({
        to: '/accounts/$accountId/cloud-access/roles/all-users',
        params,
        replace: true,
      });
    }
  },
}).lazy(() => import(/* webpackChunkName: "roles" */ './roles.lazy').then(({ Route }) => Route));
