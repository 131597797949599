import { createRoute } from '@tanstack/react-router';
import { head } from '@/router/Head';
import { Route as AccountDashboardRoute } from '.';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Profile',
  },
  getParentRoute: () => AccountDashboardRoute,
  path: 'profile',
}).lazy(() => import(/* webpackChunkName: "profile" */ './profile.lazy').then(({ Route }) => Route));
