/* eslint-disable @typescript-eslint/only-throw-error */
import { Auth0ContextInterface, OAuthError } from '@auth0/auth0-react';
import { SeverityLevel } from '@sentry/react';
import { QueryKey, queryOptions } from '@tanstack/react-query';
import { redirect } from '@tanstack/react-router';
import isNetworkError from 'is-network-error';
import { authRedirectUrl } from '@/utils/constants';
import { captureException } from '@/utils/error-utils';

export type AuthRefreshTokenDto = { expiresIn: number; sessionToken: string };

export const authRefreshTokenQueryKey = ['auth_refresh_token'] as unknown as QueryKey;

export const FALLBACK_AUTH_ERROR = 'Something went wrong with the authentication process';

const EXPIRES_LEEWAY_MS = 10_000;

/**
 * Securely refresh the access token using Auth0's SDK.
 *
 * This is a critical part of the security of the application. We follow the best practices for SPAs:
 *
 * - we allow the access token lifetime by a couple of minutes only (1-5 mins)
 * - we use rotating refresh tokens with a total of 7 days lifetime, and 2.5 days of inactive lifetime
 *
 * The call `auth.getAccessTokenSilently` refreshes the token automatically to obtain a new access token.
 * We issue this call if expired before any other request, by preflighting any API requests with our OpenAPI JS client middleware,
 * and storing it in memory for the responses' `expires_in` - 10 seconds. Only once a request hits that cache-miss it preflights again.
 * We can guarantee that API requests that need a non-expired, fresh access token will always have a new one available to set its `Authorization` header.
 *
 * @see https://auth0.com/docs/secure/tokens/refresh-tokens/refresh-token-rotation#maintain-user-sessions-in-spas
 */

export const getAuthRefreshTokenQueryOptions = (auth: Omit<Auth0ContextInterface, 'isLoading' | 'user'>) =>
  queryOptions<AuthRefreshTokenDto>({
    queryKey: authRefreshTokenQueryKey,
    queryFn: async () => {
      try {
        const response = await auth.getAccessTokenSilently({
          cacheMode: 'on',
          authorizationParams: {
            redirect_uri: authRedirectUrl.href,
            scope: window.__QDRANT_CLOUD__.auth0.scope,
            audience: window.__QDRANT_CLOUD__.auth0.audience,
          },
          detailedResponse: true,
          timeoutInSeconds: 30,
        });

        return { expiresIn: response.expires_in * 1_000, sessionToken: response.access_token };
      } catch (err) {
        let authError = '';
        // See https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        if (isOAuthError(err)) {
          authError = err.message;
          switch (err.error) {
            case 'login_required':
            case 'invalid_token':
              break;
            case 'invalid_grant': // <-- refresh token is invalid or expired
            case 'missing_refresh_token': // <-- refresh token is missing, e.g.: due to a logout in another tab
              authError = 'Session expired';
              break;
            /**
             * Thrown when network requests to the Auth server timeout.
             * @see https://github.com/auth0/auth0-spa-js/blob/f2e566849efa398ca599daf9ebdfbbd62fcb1894/src/http.ts#L153
             */
            case 'timeout':
            case 'request_error':
              onTokenAccessError(err, 'warning');
              break;
            default:
              onTokenAccessError(err, 'fatal');
              authError = '';
          }
        } else {
          /**
           * `TimeourError` can happen if acquiring a lock between tabs takes too long, see:
           * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L689
           * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L897C50-L897C75
           *
           * Acquiring a lock between tabs can take up 50 secs before throwing, so we
           * set `authorizeTimeoutInSeconds` to 10 secs to minimize the chances of this happening.
           *
           * The error may also happen if a message posted from their iframe takes too long, see:
           * https://github.com/auth0/auth0-spa-js/blob/main/src/utils.ts#L58
           */
          if (isNetworkError(err) || isGenericTimeoutError(err)) {
            throw err;
          }
          onTokenAccessError(err, 'fatal');
        }
        // Finally, throw to redirect to logout after handling edge cases above...
        throw redirect({ to: '/logout', search: { aerr: authError || FALLBACK_AUTH_ERROR } });
      }
    },
    /**
     * getAccessTokenSilently() internally retries 3 times {@link https://github.com/auth0/auth0-spa-js/blob/f2e566849efa398ca599daf9ebdfbbd62fcb1894/src/http.ts#L110}
     * except for network errors, which we will allow a retry once for.
     * */
    retry: function defaultMutationRetry(failureCount: number, error: unknown) {
      if (isNetworkError(error) || isGenericTimeoutError(error)) {
        return failureCount < 1;
      }
      return false;
    },
    /**
     * We want to mark the query as stale after the expiresIn time has passed.
     *
     * We provide a leeway of REQUEST_TIMEOUT_SECONDS to ensure the token is refreshed before it expires,
     * given that internals of the queryClient may be taking some time to set `updatedAt` and the
     * delta could e off by a few milliseconds.
     */
    staleTime: (query) =>
      query.state.data?.expiresIn ? Math.max(query.state.data.expiresIn - EXPIRES_LEEWAY_MS, 0) : 0,
  });

/**
 * Generic error TimeoutError from the Auth0 SDK.
 * @see https://github.com/auth0/auth0-spa-js/blob/main/src/errors.ts#L42
 */
function isGenericTimeoutError(error: unknown) {
  return String(error).toLowerCase().includes('timeout');
}

// Something we didn't account for happened, send to Sentry and proceed.
function onTokenAccessError(error: unknown, level: SeverityLevel = 'error') {
  captureException(error, {
    level,
    tags: { context: 'auth', action: 'get-access-token-silently', route: window.location.toString() },
  });
}

// See: https://github.com/auth0/auth0-react/blob/main/src/utils.tsx#L18
function isOAuthError(err: unknown): err is OAuthError {
  return err != null && typeof err === 'object' && 'error' in err && typeof err.error === 'string';
}
