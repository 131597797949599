import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { client, queryClient } from '@/api/query-client';
import { useRootContext } from '@/router/hooks';
import { endpoints as accountEndpoints } from '../account';
import { HybridCloudEnvDto } from '../hybrid-cloud';
import {
  ComponentSchema,
  FromPathParams,
  FromQueryParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from '../utils';

export type CloudProvider = ComponentSchema<'ClusterIn'>['cloud_provider'];
export type CloudRegion = ComponentSchema<'ClusterIn'>['cloud_region'];
export type KubernetesDistribution = ComponentSchema<'KubernetesDistribution'>;
export type QdrantConfiguration = ComponentSchema<'QdrantConfiguration-Input'>;
export type ClusterConfigurationPatch = ComponentSchema<'PydanticClusterConfigurationPatchIn'>;
export type KubernetesPodLabels = ClusterConfigurationIn['kubernetes_pod_labels'];
export type KubernetesServiceAnnotations = ClusterConfigurationIn['kubernetes_service_annotations'];
export type NodeSelector = ClusterConfigurationIn['node_selector'];
export type Toleration = ComponentSchema<'Toleration'>;
type ClusterConfigurationIn = ComponentSchema<'ClusterConfigurationIn'>;
export type KubernetesServiceType = ClusterConfigurationIn['kubernetes_service_type'];

export const endpoints = {
  clustersByAccountId: '/accounts/{account_id}/clusters',
  clusterById: '/accounts/{account_id}/clusters/{cluster_id}',
  clusterLogs: '/accounts/{account_id}/clusters/{cluster_id}/logs',
  clusterMetrics: '/accounts/{account_id}/clusters/{cluster_id}/metrics',
  clusterScalable: '/accounts/{account_id}/clusters/{cluster_id}/scalable',
  clusterJwts: '/accounts/{account_id}/clusters/{cluster_id}/auth/jwts',
  clusterJwt: '/accounts/{account_id}/clusters/{cluster_id}/auth/jwts/{cluster_jwt_id}',
  clusterCollections: '/accounts/{account_id}/clusters/{cluster_id}/query/collections',
  clusterQdrantConfig: '/accounts/{account_id}/clusters/{cluster_id}/qdrant_configuration',
  renameCluster: '/accounts/{account_id}/clusters/{cluster_id}/rename',
  uniqueName: '/unique-name',
  upscaleCluster: '/accounts/{account_id}/clusters/{cluster_id}/package',
  events: '/accounts/{account_id}/clusters/{cluster_id}/events',
} as const;

export type ClusterDto = QueryData<typeof endpoints.clusterById>;
export type ClusterScalableDto = QueryData<typeof endpoints.clusterScalable>;
export type ClusterCollectionsDto = QueryData<typeof endpoints.clusterCollections>;
export type ClusterJwtsDto = QueryData<typeof endpoints.clusterJwts>;
export type ClusterJwtDto = ClusterJwtsDto[number];
export type ClusterJwtCreateDto = MutationOptions<typeof endpoints.clusterJwts, 'post'>['body'];
export type ClusterJwtPayloadAccess = ComponentSchema<'PydanticClusterJWTPayloadAccess'>;
export type QdrantDbCollectionsResponse = ComponentSchema<'QdrantDbCollectionsResponse'>;
export type TokenPayload = ClusterJwtDto['jwt_payload'];
export type TokenPayloadAccess = TokenPayload['access'];
export type ClusterLogsDto = QueryData<typeof endpoints.clusterLogs>;
export type ClusterMetrics = QueryData<typeof endpoints.clusterMetrics>;
export type UniqueName = QueryData<typeof endpoints.uniqueName>;
/** @deprecated Use HybridCloudEnvDto directly */
export type PrivateRegion = HybridCloudEnvDto;

// ... or: Exclude<ClusterJwtDto['jwt_payload']['access'], 'r' | 'm'>
export type ClusterEventsDto = QueryData<typeof endpoints.events>;

export const getClusterScalableQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterScalable>>) =>
  queryOptions<ClusterScalableDto>({ queryKey: queryKey.get(endpoints.clusterScalable, params) });

export const getClusterCollectionsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterCollections>>) =>
  queryOptions<ClusterCollectionsDto>({ queryKey: queryKey.get(endpoints.clusterCollections, params) });

export const getClusterJwtsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterJwts>>) =>
  queryOptions<ClusterJwtsDto>({
    queryKey: queryKey.get(endpoints.clusterJwts, params),
  });

export const getClusterByIdQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterById>>) =>
  queryOptions<ClusterDto>({
    queryKey: queryKey.get(endpoints.clusterById, params),
    queryFn: async () => {
      const { data } = await client.GET(endpoints.clusterById, {
        ...toPathParams(params),
      });

      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.clustersByAccountId, { account_id: params.account_id }),
      });

      return data!;
    },
  });

export const getClustersByAccountIdQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.clustersByAccountId>>,
  query?: FromQueryParams<QueryOptions<typeof endpoints.clustersByAccountId>>,
) =>
  queryOptions<ClusterDto[]>({
    queryKey: queryKey.get(endpoints.clustersByAccountId, params, query),
    queryFn: async () => {
      const { data } = await client.GET(endpoints.clustersByAccountId, {
        params: {
          path: params,
          query,
        },
      });
      return data!;
    },
  });

export const getClusterLogsByClusterIdQuery = (params: FromPathParams<QueryOptions<typeof endpoints.clusterLogs>>) =>
  queryOptions<ClusterLogsDto>({ queryKey: queryKey.get(endpoints.clusterLogs, params) });

export const getClusterMetricsByClusterIdQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.clusterMetrics>>,
  query?: FromQueryParams<QueryOptions<typeof endpoints.clusterMetrics>>,
) =>
  queryOptions<ClusterMetrics>({
    queryKey: queryKey.get(endpoints.clusterMetrics, params, query),
    queryFn: async () => {
      const { data } = await client.GET(endpoints.clusterMetrics, {
        params: {
          path: params,
          ...(query && {
            query: {
              ...query,
            },
          }),
        },
      });
      return data!;
    },
  });

export const getUniqueNameQuery = () => queryOptions<UniqueName>({ queryKey: queryKey.get(endpoints.uniqueName) });

export const getClusterMetricsLastXMinutesByClusterIdQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.clusterMetrics>>,
  query: FromQueryParams<QueryOptions<typeof endpoints.clusterMetrics>> & {
    minutesWindow: number;
  },
) =>
  queryOptions<ClusterMetrics>({
    queryKey: queryKey.get(endpoints.clusterMetrics, params, query),
    queryFn: async () => {
      const until = Math.floor(new Date().getTime() / 1000);
      const since = until - 60 * query.minutesWindow; // x minutes before "until"
      const { data } = await client.GET(endpoints.clusterMetrics, {
        params: {
          path: params,
          ...(query && {
            query: {
              query_type: 'load_summary',
              ...query,
              since,
              until,
            },
          }),
        },
      });
      return data!;
    },
  });

export const getClusterEventsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.events>>) =>
  queryOptions<QueryData<typeof endpoints.events>>({
    queryKey: queryKey.get(endpoints.events, params),
  });

export const useDeleteClusterJwtMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterJwt, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.delete(endpoints.clusterJwt, params),
    mutationFn: async (args: Pick<TParams, 'cluster_jwt_id' | 'cluster_id'>) => {
      const { data } = await client.DELETE(endpoints.clusterJwt, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: args.cluster_id,
            cluster_jwt_id: args.cluster_jwt_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async (_data, { cluster_id }) => {
      // Invalidate the cluster jwts query and the account's.
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterJwts, { ...params, cluster_id }) }),
        queryClient.invalidateQueries({
          queryKey: [accountEndpoints.accountJwts, toPathParams(params, { filterKeys: ['account_id'] })],
        }),
      ]);
      await router.invalidate();
    },
  });
};

export const useCreateClusterJwtMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterJwts, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id' | 'cluster_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.post(endpoints.clusterJwts, params),
    mutationFn: async ({ disableInvalidate, ...body }: ClusterJwtCreateDto & { disableInvalidate?: boolean }) => {
      const { data } = await client.POST(endpoints.clusterJwts, {
        ...toPathParams(params),
        body,
      });
      return data!;
    },
    onSuccess: async (_, { disableInvalidate }) => {
      // Invalidate the cluster jwts query and the account's.
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterJwts, params) }),
        queryClient.invalidateQueries({
          queryKey: [accountEndpoints.accountJwts, toPathParams(params, { filterKeys: ['account_id'] })],
        }),
      ]);

      // For some cases, we don't want to invalidate the router directly but do from outside in a later stage to
      // avoid premature invalidation and re-mounts.
      if (!disableInvalidate) {
        await router.invalidate();
      }
    },
  });
};

type CreateClusterPayload<T extends MutationOptions<typeof endpoints.clustersByAccountId, 'post'>['body']> = Omit<
  T,
  'version' | 'configuration'
> & {
  configuration: Omit<T['configuration'], 'kubernetes_service_type'> &
    Partial<Pick<T['configuration'], 'kubernetes_service_type'>>;
};

export const useCreateClusterMutation = <
  TOptions extends MutationOptions<typeof endpoints.clustersByAccountId, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: CreateClusterPayload<TVariables>) => {
      const { data } = await client.POST(endpoints.clustersByAccountId, {
        ...toPathParams(params),
        body: body as unknown as TVariables, // Satisfying TS due to the above type constraints
      });
      return data!;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.clustersByAccountId, {
            account_id: params.account_id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.uniqueName),
        }),
      ]);

      await router.invalidate();
    },
  });
};

export const useDeleteClusterByIdMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'delete'>,
  TParams extends FromPathParams<TOptions>,
  TArgs extends FromQueryParams<TOptions>,
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (args: TArgs) => {
      const { data } = await client.DELETE(endpoints.clusterById, {
        params: {
          path: params,
          ...(args?.delete_backups ? { query: { delete_backups: true } } : undefined),
        },
      });
      return data!;
    },
    onMutate: () => queryClient.cancelQueries({ queryKey: queryKey.get(endpoints.clusterById, params) }),
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: [endpoints.clustersByAccountId] }), router.invalidate()]),
  });
};

export const useRestartClusterMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterById, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body: {
          status: {
            restarted_at: 'now',
          },
        } as TOptions['body'],
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clustersByAccountId, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) }),
      ]);
      await router.invalidate();
    },
  });
};

export const useUpdateClusterVersionMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterById, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body,
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clustersByAccountId, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) }),
      ]);
      await router.invalidate();
    },
  });
};
export const useUpdateClusterMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: Omit<TVariables, 'rolling'> & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterById, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body: body as unknown as TVariables, // Satisfying TS due to the above type constraints
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clustersByAccountId, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) }),
      ]);
      await router.invalidate();
    },
  });
};

export const useUpdateClusterQdrantConfigMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterQdrantConfig, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterQdrantConfig, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body,
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clustersByAccountId, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) }),
      ]);
      await router.invalidate();
    },
  });
};

export const useUnsuspendClusterMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterById, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body: {
          configuration: {
            num_nodes: 'last',
          },
        } as TOptions['body'],
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clustersByAccountId, params) }),
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) }),
      ]);
      await router.invalidate();
    },
  });
};

export const useUpscaleClusterNodesMutation = <
  TOptions extends MutationOptions<typeof endpoints.clusterById, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: Omit<TVariables, 'rolling'> & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.clusterById, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body: body as unknown as TVariables, // Satisfying TS due to the above type constraints
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.clusterById, {
          account_id: params.account_id,
          cluster_id,
        }),
      });
      await router.invalidate();
    },
  });
};

export const useRenameClusterMutation = <
  TOptions extends MutationOptions<typeof endpoints.renameCluster, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PATCH(endpoints.renameCluster, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body,
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.clusterById, { ...params, cluster_id }) });

      await router.invalidate();
    },
  });
};

export const useUpscaleClusterResourcesMutation = <
  TOptions extends MutationOptions<typeof endpoints.upscaleCluster, 'put'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables & Pick<TParams, 'cluster_id'>) => {
      const { data } = await client.PUT(endpoints.upscaleCluster, {
        params: {
          path: {
            account_id: params.account_id,
            cluster_id: body.cluster_id,
          },
        },
        body,
      });
      return data!;
    },
    onSuccess: async (_, { cluster_id }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.clusterById, {
            account_id: params.account_id,
            cluster_id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.clustersByAccountId, {
            account_id: params.account_id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKey.get(endpoints.clusterMetrics, {
            account_id: params.account_id,
            cluster_id,
          }),
        }),
      ]);

      await router.invalidate();
    },
  });
};
