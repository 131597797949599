import { AuthenticationError } from '@auth0/auth0-react';
import { createRoute, redirect } from '@tanstack/react-router';
import { AuthenticatedErrorComponent } from '@/components/AccountDashboard/AuthenticatedErrorComponent';
import { createRouteErrorComponent } from '@/router/createRouteErrorComponent';
import { captureException } from '@/utils/error-utils';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  id: 'authenticated',
  beforeLoad({ context: { auth } }) {
    if (auth.error) {
      // From all known cases, 'Forbidden' happens when the refresh token expires.
      const isForbiddenAccessError =
        isAuthenticationError(auth.error) &&
        auth.error.error === 'access_denied' &&
        auth.error.error_description === 'Forbidden';

      if (!isForbiddenAccessError) {
        // Something went wrong on the auth0 side (or network request failed), send to Sentry and logout.
        captureException(auth.error, {
          tags: { context: 'auth', action: 'authenticate', route: window.location.toString() },
          level: 'fatal',
          extra: {
            error: isAuthenticationError(auth.error) ? auth.error.error : auth.error.message,
          },
        });
      }
      // Auth errored out, redirect to logout.
      throw redirect({
        to: '/logout',
        search: { aerr: isAuthenticationError(auth.error) ? auth.error.error_description : auth.error.message },
      });
    }
    // If the user is not authenticated, redirect to login.
    if (!auth.isAuthenticated) {
      throw redirect({
        to: '/login',
        replace: true,
      });
    }
  },
  errorComponent: createRouteErrorComponent(AuthenticatedErrorComponent),
});

function isAuthenticationError(err: unknown): err is AuthenticationError {
  return err != null && err instanceof AuthenticationError;
}
