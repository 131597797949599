import { createRoute, redirect } from '@tanstack/react-router';
import { ClusterScalableDto, getClusterScalableQuery } from '@/api/services/cluster';
import { getPaymentInformationQuery } from '@/api/services/payment';
import { FeatureFlagsDto, featureFlagsQueryOptions } from '@/api/services/public';
import { getClusterScalableError } from '@/components/Clusters/hooks/useGetClusterScalable';
import { enqueueSnackbar } from '@/hooks/use-qdrant-snackbar';
import { head } from '@/router/Head';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '@/router/utils';
import { Route as ClusterIdRoute } from './$clusterId';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Scale cluster',
  },
  getParentRoute: () => ClusterIdRoute,
  path: 'scale',
  validateSearch: clusterCreateSearchSchema.and(clusterSetupSearchSchema),
  context: ({ params: { accountId, clusterId } }) => ({
    clusterScalableQueryOptions: getClusterScalableQuery({
      account_id: accountId,
      cluster_id: clusterId,
    }),
    paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
  }),
  async loader({ params, context: { queryClient, clusterScalableQueryOptions } }) {
    await Promise.all([
      ensureClusterCreationEnabled(queryClient.ensureQueryData(featureFlagsQueryOptions), params),
      ensureClusterScalable(queryClient.fetchQuery(clusterScalableQueryOptions), params),
    ]);
  },
}).lazy(() => import(/* webpackChunkName: "cluster-id-scale" */ './$clusterId.scale.lazy').then(({ Route }) => Route));

async function ensureClusterCreationEnabled(
  featureFlags: Promise<FeatureFlagsDto>,
  params: typeof Route.types.allParams,
) {
  const { cluster_creation_enabled: enabled } = await featureFlags;

  if (!enabled) {
    enqueueSnackbar({ message: 'Cluster creation is currently disabled', variant: 'warning' });

    throw redirect({ to: '/accounts/$accountId/clusters', params });
  }
}

async function ensureClusterScalable(
  clusterScalable: Promise<ClusterScalableDto>,
  params: typeof Route.types.allParams,
) {
  try {
    await clusterScalable;
  } catch (err) {
    enqueueSnackbar({ message: getClusterScalableError(err), variant: 'warning' });

    throw redirect({ to: '/accounts/$accountId/clusters/$clusterId', params });
  }
}
