import { createRoute } from '@tanstack/react-router';
import { getPaymentInformationQuery } from '@/api/services/payment';
import { head } from '@/router/Head';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as HybridCloudOnboardingRoute } from '../onboarding_';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: ['Hybrid Cloud', 'Onboarding'],
      permissions: ['read:payment_information'],
    },
    getParentRoute: () => HybridCloudOnboardingRoute,
    path: 'payment',
    context: ({ params: { accountId } }) => ({
      paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
    }),
    loader({ context: { queryClient, paymentInformationQueryOptions } }) {
      void queryClient.prefetchQuery(paymentInformationQueryOptions);
    },
  }),
).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-onboarding-payment" */ './payment.lazy').then(({ Route }) => Route),
);
