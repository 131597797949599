import { QueryClient } from '@tanstack/react-query';
import { Middleware } from 'openapi-fetch';
import { ApiClientError } from './errors';
import { AuthRefreshTokenDto, authRefreshTokenQueryKey } from './services/auth';
import { endpoints as publicEndpoints, settingsQueryOptions } from './services/public';

let defaultQueryClient: QueryClient;
/**
 * Due to circular dependencies, we need to set the default QueryClient.
 */
export const setDefaultQueryClient = (queryClient: QueryClient) => {
  defaultQueryClient = queryClient;
};

/**
 * Middleware to add Authorization header to all requests.
 * The 'token' is set externally by the app.
 */
export const authorizationHeaderMiddleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    if (schemaPath === authRefreshTokenQueryKey[0]) {
      return request;
    }
    const data = await defaultQueryClient.fetchQuery<AuthRefreshTokenDto>({ queryKey: authRefreshTokenQueryKey });
    request.headers.set('Authorization', `Bearer ${data.sessionToken}`);

    return request;
  },
};

/**
 * This middleware appends 'X-Qd-Version' header to all requests,
 * so that we know which UI App version issued the request to the API
 */
const QdVersionRegex = /(?:[0-9]{1,5}\.[0-9]{1,5}\.[0-9]{1,5})/;

export const versionHeaderMiddleware: Middleware = {
  async onRequest({ request, schemaPath }) {
    if (schemaPath === publicEndpoints.settings) {
      return request;
    }
    const { tag } = await defaultQueryClient.ensureQueryData(settingsQueryOptions);

    if (QdVersionRegex.test(tag)) {
      request.headers.set('X-Qd-Version', tag);
    }

    return request;
  },
};

/**
 * Middleware turns error responses into ApiClientError instances.
 */
export const apiClientErrorMiddleware: Middleware = {
  async onResponse({ response }) {
    if (!response.ok) {
      let error: string | object = await response.clone().text();
      try {
        error = JSON.parse(error) as object;
      } catch {
        // noop
      }
      throw new ApiClientError(error, response.status);
    }
  },
};
