import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { WEB_STORAGE_PREFIX } from '@/utils/constants';
import { isModuleNotFoundError, captureException } from '@/utils/error-utils';
import { attempt } from '@/utils/func-utils';

export function DefaultErrorComponent({
  error,
  children,
  onModuleNotFoundError,
}: {
  error: unknown;
  children?: ReactElement | string;
  onModuleNotFoundError?: (error: Error) => void;
}) {
  if (isModuleNotFoundError(error)) {
    // We want to reload one time on module not found error and not enter
    // a reload loop if there is some other issue besides an old deploy.
    // That's why we store our reload attempt in sessionStorage.
    // Use error.message as key because it contains the module path that failed.
    const storageKey = `${WEB_STORAGE_PREFIX}${error.message}`;
    const hasError = attempt(
      () => sessionStorage.getItem(storageKey),
      (error) => {
        // Keep track of errors to know how many sessions DO NOT experience an automatic reload (if any).
        captureException(error, { tags: { webpack: 'ChunkLoadError' } });
        // Return true to mimic that we've already reloaded, as sessionStorage throws, and we can't continue.
        return true;
      },
    );
    // If we haven't reloaded yet, then mark that we are reloading.
    if (!hasError) {
      sessionStorage.setItem(storageKey, '1');
      onModuleNotFoundError?.(error);
      // Return empty component while we wait for window to reload...
      return <></>;
    }
  }

  return (
    children ?? (
      <Box
        role="alert"
        sx={{ position: 'absolute', width: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <Box sx={{ width: '100%', paddingX: '20px' }}>
          <Typography variant="h5" component="p" sx={{ textAlign: 'center', mb: 2 }}>
            An unexpected error has occurred: {String(error)}.
            <br />
            Please try again later.
          </Typography>
        </Box>
      </Box>
    )
  );
}
