import { createRoute, redirect } from '@tanstack/react-router';
import { getClusterByIdQuery } from '@/api/services/cluster';
import { isClusterHybridCloud } from '@/utils/cluster-utils';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';

export const Route = createRoute({
  getParentRoute: () => ClusterIdIndexRoute,
  path: 'requests',
  beforeLoad: async ({ context: { queryClient }, params: { accountId, clusterId } }) => {
    const cluster = await queryClient.fetchQuery(getClusterByIdQuery({ account_id: accountId, cluster_id: clusterId }));

    const isHybridCloudCluster = isClusterHybridCloud(cluster);

    if (isHybridCloudCluster) {
      throw redirect({
        to: '/accounts/$accountId/clusters/$clusterId/overview',
        params: { accountId, clusterId },
        replace: true,
      });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cluster-request" */ './requests.lazy').then(({ Route }) => Route));
