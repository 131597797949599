import { createRoute, redirect } from '@tanstack/react-router';
import { getHybridCloudEnvQuery } from '@/api/services/hybrid-cloud';
import { head } from '@/router/Head';
import { Route as HybridCloudOnboardingRoute } from '../onboarding_';

export const Route = createRoute({
  head,
  staticData: {
    title: ['Hybrid Cloud', 'Onboarding'],
  },
  getParentRoute: () => HybridCloudOnboardingRoute,
  path: 'connection',
  beforeLoad: async ({ context: { queryClient, hybridCloudEnvsQuery }, params: { accountId } }) => {
    const envs = await queryClient.ensureQueryData(hybridCloudEnvsQuery);

    if (envs.length !== 1) {
      throw redirect({ to: '/accounts/$accountId/hybrid-cloud', params: { accountId }, replace: true });
    }

    return {
      hybridCloudEnvQuery: getHybridCloudEnvQuery({ account_id: accountId, hybrid_cloud_env_id: envs[0].id }),
    };
  },
  loader: ({ context: { queryClient, hybridCloudEnvQuery } }) => {
    void queryClient.prefetchQuery(hybridCloudEnvQuery);
  },
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-onboarding-connection" */ './connection.lazy').then(({ Route }) => Route),
);
