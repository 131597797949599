import { useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { ClusterDto, getClusterCollectionsQuery } from '@/api/services/cluster';
import { useAccountId } from '@/router/hooks';
import { isClusterNotReachable } from '@/utils/cluster-utils';

type CollectionsContextType = {
  availableCollections: string[];
  loadingCollections: boolean;
  errorLoadingCollections: boolean;
  refetchCollections: () => Promise<unknown>;
  clusterIsNotReachable: boolean;
};

export const UNREACHABLE_CLUSTER_MSG = "Collections can't be retrieved yet while the cluster is being created.";
export const CollectionsContext = createContext<CollectionsContextType | undefined>(undefined);

const { useParams } = getRouteApi('/authenticated/accounts/$accountId/account-dashboard/clusters/$clusterId');

export const CollectionsProvider = ({ cluster, children }: { cluster: ClusterDto; children: ReactNode }) => {
  const { clusterId } = useParams();
  const accountId = useAccountId();

  const clusterIsNotReachable = isClusterNotReachable(cluster);
  const {
    data: collectionsResponse,
    isFetching: loadingCollections,
    isError: errorLoadingCollections,
    refetch: refetchCollections,
  } = useQuery({
    ...getClusterCollectionsQuery({ account_id: accountId, cluster_id: clusterId }),
    // Stale time is set to 0 to ensure that the collections are always up to date
    staleTime: 0,
    enabled: !clusterIsNotReachable,
  });

  const availableCollections = useMemo(
    () =>
      collectionsResponse?.result ? collectionsResponse.result.collections.map((collection) => collection.name) : [],
    [collectionsResponse],
  );

  return (
    <CollectionsContext.Provider
      value={{
        availableCollections,
        loadingCollections,
        errorLoadingCollections,
        refetchCollections,
        clusterIsNotReachable,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

// Hook for using context
export const useCollections = () => {
  const context = useContext(CollectionsContext);
  if (!context) {
    throw new Error('useCollections must be used within a CollectionsProvider');
  }
  return context;
};
