import { queryOptions } from '@tanstack/react-query';
import { FromQueryParams, QueryData, queryKey, QueryOptions } from './utils';

export const endpoints = {
  bookingPackages: '/booking/packages',
  bookingAvailablePackages: '/booking/available-packages',
  bookingResourceOptions: '/booking/resource-options',
} as const;

export type BookingPackagesDto = QueryData<typeof endpoints.bookingPackages>;
export type BookingPackageDto = BookingPackagesDto[number];
export type BookingAvailablePackagesDto = QueryData<typeof endpoints.bookingAvailablePackages>;
export type BookingResourceOptionsDto = QueryData<typeof endpoints.bookingResourceOptions>;
export type BookingResourceOptionDto = BookingResourceOptionsDto[number];
export type BookingResourceOptionDtoType = BookingResourceOptionDto['resource_type'];

export const getBookingPackagesQuery = (query: FromQueryParams<QueryOptions<typeof endpoints.bookingPackages>> = {}) =>
  queryOptions<BookingPackagesDto>({
    queryKey: queryKey.get(endpoints.bookingPackages, undefined, query),
    placeholderData: (previousData) => previousData,
  });

export const getBookingAvailablePackagesQuery = (
  query: FromQueryParams<QueryOptions<typeof endpoints.bookingAvailablePackages>>,
) =>
  queryOptions<BookingAvailablePackagesDto>({
    queryKey: queryKey.get(endpoints.bookingAvailablePackages, undefined, query),
    placeholderData: (previousData) => previousData,
    meta: {
      isPublic: true,
    },
  });

export const getBookingResourceOptionsQuery = (
  query: FromQueryParams<QueryOptions<typeof endpoints.bookingResourceOptions>>,
) =>
  queryOptions<BookingResourceOptionsDto>({
    queryKey: queryKey.get(endpoints.bookingResourceOptions, undefined, query),
    placeholderData: (previousData) => previousData,
    meta: {
      isPublic: true,
    },
  });
