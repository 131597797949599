import { createRoute } from '@tanstack/react-router';
import { getHybridCloudEnvsQuery } from '@/api/services/hybrid-cloud';
import { head } from '@/router/Head';
import { hybridCloudSearchSchema } from '@/router/utils';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as AccountDashboardRoute } from '..';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: 'Hybrid Cloud',
      permissions: ['read:hybrid_cloud_environments'],
    },
    validateSearch: hybridCloudSearchSchema,
    getParentRoute: () => AccountDashboardRoute,
    path: 'hybrid-cloud',
    context: ({ params: { accountId } }) => ({
      hybridCloudEnvsQuery: getHybridCloudEnvsQuery({ account_id: accountId }),
    }),
    loader: ({ context: { queryClient, hybridCloudEnvsQuery } }) => {
      void queryClient.prefetchQuery(hybridCloudEnvsQuery);
    },
  }),
).lazy(() => import(/* webpackChunkName: "hybrid-cloud" */ './index.lazy').then(({ Route }) => Route));
