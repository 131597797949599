import { createRoute, redirect } from '@tanstack/react-router';
import { ensureAccountUser } from '@/router/accountUser';
import { Route as AccountRoute } from '.';

export const Route = createRoute({
  getParentRoute: () => AccountRoute,
  path: 'profile',
  beforeLoad: async ({ context: { queryClient } }) => {
    const {
      account: { id: accountId },
    } = await ensureAccountUser({ queryClient });

    throw redirect({ to: '/accounts/$accountId/profile', params: { accountId } });
  },
});
