import { Auth0ContextInterface } from '@auth0/auth0-react';
import { DefaultError, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AnyRedirect, AnyRouter, isRedirect } from '@tanstack/react-router';
import isNetworkError from 'is-network-error';
import { Client } from 'openapi-fetch';
import createClient from 'openapi-fetch';
import { z } from 'zod';
import { enqueueSnackbar } from '@/hooks/use-qdrant-snackbar';
import { loginSearchSchema } from '@/router/utils';
import { ONE_HOUR_MILLISECONDS } from '@/utils/constants';
import {
  ApiClientError,
  isJsonApiClientError,
  isPermissionError,
  isSignalTimeoutError,
  isUnauthorizedError,
} from './errors';
import {
  setDefaultQueryClient,
  apiClientErrorMiddleware,
  authorizationHeaderMiddleware,
  versionHeaderMiddleware,
} from './middlewares';
import { getAuthRefreshTokenQueryOptions } from './services/auth';
import { Paths, PublicPaths, AllPaths, QueryOptions, QueryKey, MutationKey, AnyQueryKey } from './services/utils';

declare module '@tanstack/react-query' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Register {
    queryKey: [QueryKey | typeof AnyQueryKey, ...(readonly unknown[])];
    mutationKey: [MutationKey, ...(readonly unknown[])];
    defaultError: ApiClientError | TypeError | DOMException | AnyRedirect;
    queryMeta: { isPublic: true; baseUrl?: `/${string}` };
  }
}

let defaultRouter: AnyRouter;
/**
 * Due to circular dependencies, we need to set the default Router.
 */
export const setDefaultRouter = (router: AnyRouter) => {
  defaultRouter = router;
};

export const client = createClient<Paths>({ baseUrl: '/api/v1', mode: 'same-origin' });
export const publicClient = createClient<PublicPaths>({ baseUrl: '/api/v1', mode: 'same-origin' });

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // This function will be called if some query encounters an error.
    onError(error) {
      return defaultErrorHandler(error);
    },
  }),
  mutationCache: new MutationCache({
    /*
     * This function will be called if some mutation encounters an error.
     * If you return a Promise from it, it will be awaited.
     */
    async onError(error) {
      await defaultErrorHandler(error);
      /**
       * TypeError: Failed to fetch
       * - network errors: failure to connect to the server which can be caused by several reasons,
       *   such as slow network and timeout, for example.
       * - CORS errors: when a domain is not authorized to obtain resources from a different domain.
       * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/fetch#exceptions
       */
      if (error instanceof TypeError) {
        // Display a snackbar notification for mutation errors only; query errors must be handled by catch boundaries,
        // so that they are displayed as a fallback for the fetching component instead.
        enqueueSnackbar(error.message, { variant: 'error', preventDuplicate: true });
      }
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: ONE_HOUR_MILLISECONDS / 2,
      queryFn: async ({ queryKey, signal, meta }) => {
        const [path] = queryKey;
        if (path === AnyQueryKey) {
          throw new Error('Empty path in query key not allowed.');
        }
        const [, pathParams, queryParams] = queryKey as [
          never,
          QueryOptions<typeof path> | undefined,
          QueryOptions<typeof path> | undefined,
        ];
        const _client: Client<AllPaths> = meta?.isPublic ? publicClient : client;
        /**
         * Default queryFn implementation for all GET requests. Global onError handlers implemented above
         * handle fetch errors and unauthorized responses.
         *
         * When the promise resolves, 'data' can be assumed to be there and returned directly.
         * API errors are throw as 'ApiClientError' automatically via middleware (see client.ts)
         */
        const { data, response } = await _client.GET(path, {
          signal: signalWithTimeout(signal),
          params: { ...pathParams?.params, ...queryParams?.params },
          baseUrl: meta?.baseUrl,
        });
        /**
         * Shouldn't return 'undefined' when the response is OK, as it will be treated as an error.
         * @see https://github.com/TanStack/query/discussions/6029#discussioncomment-7033274
         */
        if (data === undefined && response.ok) {
          return null;
        }
        return data;
      },
      retry: defaultQueryRetry,
    },
    mutations: {
      retry: defaultMutationRetry,
    },
  },
});

/**
 * The signal will abort when a query is cancelled {@link https://tanstack.com/query/latest/docs/framework/react/guides/query-cancellation#default-behavior}
 * or 30 seconds {@link https://github.com/qdrant/qdrant-cloud/blob/main/environments/production/apps/qdrant-cloud-cluster-api/agent-cluster-api-release.yaml#L36} are up,
 * whichever is sooner. It shall induce a retry earlier than a network/server timeout,
 * and therefore increase the chances of a successful response in a quasi-reasonable amount of time.
 */
function signalWithTimeout(signal: AbortSignal, timeoutMs = 30_000) {
  if ('any' in AbortSignal) {
    return AbortSignal.any([signal, AbortSignal.timeout(timeoutMs)]);
  }
  return signal;
}

function defaultQueryRetry(failureCount: number, error: DefaultError) {
  if (isUnauthorizedError(error) || isSignalTimeoutError(error) || isNetworkError(error)) {
    return failureCount < 1;
  }
  return false;
}

function defaultMutationRetry(failureCount: number, error: DefaultError) {
  if (isUnauthorizedError(error)) {
    return failureCount < 1;
  }
  return false;
}

function defaultErrorHandler(error: DefaultError): Promise<void> | void {
  /**
   * API unauthorized response errors are treated as a logout event.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
   */
  if (isUnauthorizedError(error)) {
    const search: z.infer<typeof loginSearchSchema> = {
      aerr: 'Login required',
    };
    if (isJsonApiClientError(error)) {
      // If we had a 'detail' in a 401 response, it could take the form of a URL.
      const authErr = String(error.error.detail);
      const url = URL.parse(authErr);
      if (url) {
        Object.assign(search, url.searchParams);
      } else {
        search.aerr = authErr;
      }
    }
    // Redirect to the logout screen with the error message in the URL.
    return defaultRouter.navigate({ to: '/logout', search });
  }
  /**
   * Check if the error is a redirect object
   * @see https://tanstack.com/router/v1/docs/framework/react/api/router/isRedirectFunction
   */
  if (isRedirect(error)) {
    // If the redirect ocurred while a route is fetching, let it do its thing ...
    if (!defaultRouter.state.matches.some((m) => m.isFetching)) {
      // ... but if the redirect occured in a component-mounted query, we want to make sure it is navigated to.
      return defaultRouter.navigate(error);
    }
  }

  if (isPermissionError(error)) {
    enqueueSnackbar('You have insufficient permissions to perform this action.', {
      variant: 'warning',
      preventDuplicate: true,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  }
}

/**
 * Due to circular dependencies, we need to set this with `auth` from outside.
 */
export const setRefreshTokenQueryDefaults = (auth: Omit<Auth0ContextInterface, 'isLoading' | 'user'>) => {
  const { queryKey, ...queryOptions } = getAuthRefreshTokenQueryOptions(auth);

  queryClient.setQueryDefaults(queryKey, queryOptions);
};

setDefaultQueryClient(queryClient);

client.use(authorizationHeaderMiddleware);
client.use(versionHeaderMiddleware);
client.use(apiClientErrorMiddleware);

publicClient.use(versionHeaderMiddleware);
publicClient.use(apiClientErrorMiddleware);
