import { createRoute, redirect } from '@tanstack/react-router';
import { ensureAccountUser } from '@/router/accountUser';
import { hasPermission } from '@/utils/access-control';
import { Route as AccountRoute } from '../_account';

export const Route = createRoute({
  getParentRoute: () => AccountRoute,
  path: 'auth/callback',
  beforeLoad: async ({ context: { queryClient } }) => {
    const userAccount = await ensureAccountUser({ queryClient });
    const params = { accountId: userAccount.account.id };

    if (hasPermission({ permissions: ['read:clusters'], userAccount })) {
      throw redirect({ to: '/accounts/$accountId/clusters', params, replace: true });
    }
    throw redirect({ to: '/accounts/$accountId/profile', params, replace: true });
  },
});
