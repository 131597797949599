import { createRoute, redirect } from '@tanstack/react-router';
import { head } from '@/router/Head';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as HybridCloudRoute } from '../hybrid-cloud_';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: ['Hybrid Cloud Environment', 'Create'],
      permissions: ['write:hybrid_cloud_environments'],
    },
    getParentRoute: () => HybridCloudRoute,
    path: 'create',
    beforeLoad: async ({ context: { queryClient, hybridCloudEnvsQuery }, params: { accountId } }) => {
      const envs = await queryClient.ensureQueryData(hybridCloudEnvsQuery);

      if (envs.length < 1) {
        throw redirect({ to: '/accounts/$accountId/hybrid-cloud', params: { accountId } });
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "hybrid-cloud-create" */ './create.lazy').then(({ Route }) => Route));
