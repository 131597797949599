import { createRoute } from '@tanstack/react-router';
import { getBookingAvailablePackagesQuery } from '@/api/services/booking';
import { calculatorSearchSchema } from '@/components/Calculator/utils';
import { head } from '@/router/Head';
import { Route as RootRoute } from '../__root';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Calculator',
  },
  getParentRoute: () => RootRoute,
  path: 'calculator',
  validateSearch: calculatorSearchSchema,
  beforeLoad: ({ search: { region, provider } }) => ({
    bookingAvailablePackagesQueryOptions: getBookingAvailablePackagesQuery({ provider, region }),
  }),
  loader: ({ context: { queryClient, bookingAvailablePackagesQueryOptions } }) => {
    void queryClient.prefetchQuery(bookingAvailablePackagesQueryOptions);
  },
}).lazy(() => import(/* webpackChunkName: "calculator" */ './calculator.lazy').then(({ Route }) => Route));
