import { styled, AppBar, Box, IconButton, Toolbar, AppBarProps } from '@mui/material';
import { memo } from 'react';
import { Menu as MenuIcon } from '@/icons/menu';
import { IncidentBanner } from '../Common/IncidentBanner';
import { accountDashboardSidebarWidth } from './AccountDashboardSidebar';
import { AccountMenu } from './AccountDashboardToolbar/AccountMenu';
import { UserMenu } from './AccountDashboardToolbar/UserMenu';

export const AccountDashboardAppBar = styled((props: AppBarProps) => (
  <AppBar
    {...props}
    elevation={1}
    sx={{
      width: { lg: `calc(100% - ${accountDashboardSidebarWidth})` },
    }}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const AccountDashboardNavbar = memo(function AccountDashboardNavbar({
  onOpenSidebar,
}: {
  onOpenSidebar: () => void;
}) {
  return (
    <AccountDashboardAppBar>
      <IncidentBanner />
      <Toolbar
        disableGutters={true}
        sx={{
          left: 0,
          px: 4,
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <AccountMenu />
        <Box sx={{ flexGrow: 1 }} />
        <UserMenu />
      </Toolbar>
    </AccountDashboardAppBar>
  );
});
