import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  MenuItemProps,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { memo, ReactNode, useCallback } from 'react';
import { AccountDto } from '@/api/services/account';
import { useUpdateDefaultAccountMutation } from '@/api/services/user';
import { WithPermissions } from '@/components/AccessManagement/WithPermissions';
import { useSnackbar } from '@/hooks/use-qdrant-snackbar';
import { CreditCardOutlined } from '@/icons/credit-card-outlined';
import { MembersOutlined } from '@/icons/members-outlined';
import { SettingsGearOutlined } from '@/icons/settings-gear-outlined';
import { MenuItemLink } from '@/router/Link';
import { useAccountUser } from '@/router/hooks';

const StyledMenuGroupName = styled((props: MenuItemProps) => (
  <Typography component="div" variant="caption" color="text.disabled" {...props} />
))(({ theme }) => ({
  paddingBlock: theme.spacing(1),
}));

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  height: 'auto',
  minHeight: theme.spacing(6),
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(0.5),
  },
  '&.Mui-disabled': {
    opacity: 1,
  },
  '@media (min-width:600px)': {
    minHeight: theme.spacing(6),
  },
})) as typeof MenuItemLink;

const StyledListItemText = (props: ListItemTextProps<'span', 'span'>) => (
  <ListItemText
    primaryTypographyProps={{ noWrap: true, component: 'span' }}
    secondaryTypographyProps={{ noWrap: true, component: 'span' }}
    {...props}
  />
);

export const AccountMenuItems = ({ onClose }: { onClose: () => void }) => {
  const {
    id: userId,
    email: userEmail,
    accounts,
    account: { id: accountId },
    default_account_id: defaultAccountId,
  } = useAccountUser();

  const sortedAccounts = accounts?.sort(({ is_default }) => (is_default ? -1 : 0));

  // TODO Remove the next block once https://github.com/qdrant/cloud-pm/issues/3036 ships.
  // Fix to have one default account while we are not able to set default account in the backend
  if (!defaultAccountId) {
    const shouldBeDefaultAccount = sortedAccounts?.find((account) => account.owner_email === userEmail);
    if (shouldBeDefaultAccount) {
      shouldBeDefaultAccount.is_default = true;
    }
  }
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: setDefaultAccount } = useUpdateDefaultAccountMutation();
  const handleMakeDefault = useCallback(
    async (accountId: string) => {
      onClose();
      try {
        await setDefaultAccount({ default_account_id: accountId, user_id: userId });
        enqueueSnackbar('Default account changed successfully.', { variant: 'success' });
      } catch {
        enqueueSnackbar('There was a problem changing your default account. Try again later.', { variant: 'error' });
      }
    },
    [enqueueSnackbar, onClose, setDefaultAccount, userId],
  );

  return (
    <>
      <WithPermissions
        permissions={['read:users']}
        render={() => (
          <StyledMenuItemLink
            onClick={onClose}
            to="/accounts/$accountId/cloud-access/roles/all-users"
            params={{ accountId }}
            activeProps={{ selected: true }}
            activeOptions={{ exact: true }}
          >
            <ListItemIcon>
              <MembersOutlined />
            </ListItemIcon>
            <StyledListItemText>Access Management</StyledListItemText>
          </StyledMenuItemLink>
        )}
      />
      <WithPermissions
        permissions={['read:payment_information']}
        render={() => (
          <StyledMenuItemLink
            onClick={onClose}
            to="/accounts/$accountId/billing"
            params={{ accountId }}
            activeProps={{ selected: true }}
            activeOptions={{ exact: true }}
          >
            <ListItemIcon>
              <CreditCardOutlined />
            </ListItemIcon>
            <StyledListItemText>Billing</StyledListItemText>
          </StyledMenuItemLink>
        )}
      />
      <StyledMenuItemLink
        onClick={onClose}
        to="/accounts/$accountId/profile"
        params={{ accountId }}
        activeProps={{ selected: true }}
        activeOptions={{ exact: true }}
      >
        <ListItemIcon>
          <SettingsGearOutlined />
        </ListItemIcon>
        <StyledListItemText>Account Settings</StyledListItemText>
      </StyledMenuItemLink>
      {sortedAccounts ? (
        <>
          <Divider />
          <StyledMenuGroupName>My Accounts</StyledMenuGroupName>
          {sortedAccounts.map((account) => (
            <AccountMenuItem
              key={account.id}
              account={account}
              isMatch={account.id === accountId}
              onMakeDefault={handleMakeDefault}
            />
          ))}
        </>
      ) : null}
    </>
  );
};

const sxButton: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.main,
  background: 'transparent',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  pointerEvents: 'auto',
  fontSize: (theme) => theme.typography.body2.fontSize,
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
};

const AccountMenuItem = memo(function AccountMenuItem({
  account: { id, name, is_default: isDefault, permissions, privileges },
  isMatch,
  onMakeDefault,
}: {
  account: AccountDto;
  isMatch: boolean;
  onMakeDefault: (accountId: string) => void;
}) {
  const to =
    privileges.includes('CLOUD_RBAC') && permissions.length === 1 && permissions[0] === 'read:profile'
      ? '/accounts/$accountId/profile'
      : '.';

  let secondaryText: ReactNode;
  if (isDefault) {
    secondaryText = 'Default Account';
  } else if (isMatch) {
    secondaryText = (
      <Box sx={sxButton} component="button" onClick={() => onMakeDefault(id)}>
        Make Default
      </Box>
    );
  }

  return (
    <StyledMenuItemLink disabled={isMatch} selected={isMatch} params={{ accountId: id }} to={to}>
      <StyledListItemText primary={name} secondary={secondaryText} data-sentry-mask={true} />
      {isMatch && <CheckIcon fontSize="small" color="primary" />}
    </StyledMenuItemLink>
  );
});
