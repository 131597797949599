import { createRoute } from '@tanstack/react-router';
import { head } from '@/router/Head';
import { loginSearchSchema } from '@/router/utils';
import { Route as RootRoute } from '../__root';
import { LogoutComponent } from './logout';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Signing out ...',
  },
  getParentRoute: () => RootRoute,
  path: 'logout',
  validateSearch: loginSearchSchema,
  component: LogoutComponent,
});
