import { onlineManager } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSnackbar } from '@/hooks/use-qdrant-snackbar';

export function NetworkStatus() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    () =>
      onlineManager.subscribe((isOnline) => {
        if (isOnline) {
          closeSnackbar('network-status-offline');
          enqueueSnackbar('You are now online', {
            variant: 'info',
            key: 'network-status-online',
            preventDuplicate: true,
            persist: true,
          });
        } else {
          closeSnackbar('network-status-online');
          enqueueSnackbar('You are now offline', {
            variant: 'info',
            key: 'network-status-offline',
            preventDuplicate: true,
            persist: true,
          });
        }
      }),
    [enqueueSnackbar, closeSnackbar],
  );

  return null;
}
