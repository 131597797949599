import { AnyRouteMatch, HeadContent } from '@tanstack/react-router';
import { createPortal } from 'react-dom';
import { MIDDOT_SYMBOL } from '@/utils/constants';

/**
 * @see https://github.com/TanStack/router/discussions/1056#discussioncomment-12306354
 */
export function Head() {
  const container = document.querySelector('head');
  if (!container) {
    return;
  }
  container.querySelector('title')?.remove();
  return createPortal(<HeadContent />, container);
}

/**
 * @see https://github.com/TanStack/router/discussions/1056#discussioncomment-12272328
 */
export function head(ctx: { matches: AnyRouteMatch[] }) {
  const parts = ctx.matches.flatMap((m) => m.staticData.title).filter(Boolean);

  return {
    meta: [{ title: parts.join(` ${MIDDOT_SYMBOL} `) }],
  };
}
