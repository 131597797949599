import { Skeleton } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { getAccountJwts } from '@/api/services/account';
import { getAccountApiKeysQuery } from '@/api/services/authentication';
import { getClustersByAccountIdQuery } from '@/api/services/cluster';
import { featureFlagsQueryOptions } from '@/api/services/public';
import { head } from '@/router/Head';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as CloudAccessRoute } from './cloud-access_';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: 'Data Access Control',
      permissions: ['read:api_keys'],
    },
    getParentRoute: () => CloudAccessRoute,
    path: 'database-api-keys',
    context: ({ params: { accountId } }) => ({
      accountApiKeysQueryOptions: getAccountApiKeysQuery({ account_id: accountId }),
      clusterQueryOptions: getClustersByAccountIdQuery({ account_id: accountId }),
    }),
    beforeLoad: async ({ context: { queryClient }, params: { accountId } }) => {
      const { qdrant_clusters_jwt_authentication_enabled: jwtsEnabled } =
        await queryClient.ensureQueryData(featureFlagsQueryOptions);

      if (!jwtsEnabled) {
        return { accountJwtQueryOptions: null };
      }

      return { accountJwtQueryOptions: getAccountJwts({ account_id: accountId }) };
    },
    loader: async ({
      context: { queryClient, accountJwtQueryOptions, accountApiKeysQueryOptions, clusterQueryOptions },
    }) => {
      await Promise.all([
        queryClient.fetchQuery(clusterQueryOptions),
        queryClient.fetchQuery(accountApiKeysQueryOptions),
        ...(accountJwtQueryOptions ? [queryClient.fetchQuery(accountJwtQueryOptions)] : []), // is this correct?
      ]);
    },
    pendingComponent: () => <Skeleton variant="rectangular" height={240} />,
  }),
).lazy(() => import(/* webpackChunkName: "data-access" */ './data-access.lazy').then(({ Route }) => Route));
