import { ReactNode, useEffect } from 'react';
import { useTrackPageView } from '@/hooks/use-event-tracking';

export function AnalyticsRoot({ children }: { children: ReactNode }) {
  const trackPageView = useTrackPageView();

  // SPA Page View Logic
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return children;
}
