import { Alert, Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { NotFoundRouteProps } from '@tanstack/react-router';
import { ReactElement } from 'react';
import { ExclamationCircle } from '@/icons/exclamation-circle';
import { isRouteAccessControlError } from '@/router/withRouteAccessControl';
import { isDevelopmentEnv } from '@/utils/utils';
import { AccountDashboardMain } from '../AccountDashboard/AccountDashboardMain';

export const DefaultNotFoundComponent = ({ data, children }: NotFoundRouteProps & { children?: ReactElement }) => (
  <AccountDashboardMain>
    <Box pt={10} display="flex" justifyContent="center">
      <Card variant="outlined" role="alert">
        <CardContent>
          {children ?? (
            <Stack direction="column" spacing={2} alignItems="center">
              <Stack direction="row" spacing={1}>
                <ExclamationCircle color="error" />
                <Typography variant="body1">Sorry we couldn't find what you were looking for</Typography>
              </Stack>
              {isRouteAccessControlError(data) && (
                <>
                  {data.data.privileges?.length ? (
                    <Typography variant="caption">
                      You'll need: <strong>{data.data.privileges.join(', ')}</strong> privilege(s) to access this page
                    </Typography>
                  ) : null}
                  {data.data.permissions?.length ? (
                    <Typography variant="caption">
                      You'll need: <strong>{data.data.permissions.join(', ')}</strong> permission(s) to access this page
                    </Typography>
                  ) : null}
                </>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
    </Box>
    {!isDevelopmentEnv && isRouteAccessControlError(data) && (
      <Stack spacing={2} marginTop={2}>
        <Alert severity="error">
          <Typography variant="h5">👋 Developer</Typography>
          {data.data.privileges?.length ? <li>Missing privilege(s): {data.data.privileges.join(', ')}</li> : null}
          {data.data.permissions?.length ? <li>Missing permission(s): {data.data.permissions.join(', ')}</li> : null}
        </Alert>
        {!children && (
          <Alert severity="info">
            By default, when a <code>'notFound()'</code> error is thrown due a privilege and/or permission missing, we
            render what's defined in the Router: <code>'defaultNotFoundComponent(...)'</code>.
            <br />
            <br />
            You can further customize this logic if needed by rendering a custom component using:{' '}
            <code>'notFound: createRouteAccessErrorComponent(...)'</code>.<br />
            Inside it, you may render a custom error message, <code>'{`<Navigate>`}'</code> to a different route, or
            anything else you would do inside a function component.
          </Alert>
        )}
      </Stack>
    )}
  </AccountDashboardMain>
);
