import { qdrantCloudUrl } from './constants';

/**
 * Based on the current host, return the preferred host for the CDN to be passed to the Auth0 login page.
 * This is necessary to ensure that either staging or production's CDN is used - they are the only CDNs we have setup.
 * If the host does not match production, it will fallback to the staging CDN.
 */
export function preferredHostForCDN(host: string): string {
  if (host.startsWith('localhost')) {
    return 'staging-cloud.qdrant.io';
  }
  if (!host.includes('cloud.qdrant.')) {
    throw new Error('preferredHostForCDN: Invalid host.');
  }
  return host.includes('-cloud.') || host.includes('.cloud.')
    ? host.replace(/.+cloud(.+)/, 'staging-cloud$1')
    : host.replace(/.+\.(qdrant\..+)/, 'cloud.$1');
}

export const cdnBaseUrl = qdrantCloudUrl.host.startsWith('localhost')
  ? qdrantCloudUrl.origin
  : `https://cdn.${preferredHostForCDN(qdrantCloudUrl.host)}`;
