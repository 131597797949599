import { ParamsOption, RequestOptions } from 'openapi-fetch';
import { HttpMethod, PathsWithMethod, SuccessResponseJSON } from 'openapi-typescript-helpers';
import { components, paths } from '@/api-schema';
import { objectEntries, objectFromEntries } from '@/utils/func-utils';

type PublicPath = '/config/feature_flags' | '/booking/available-packages';

export type PublicPaths = Pick<paths, PublicPath> & {
  '/settings.json': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get: {
      parameters: {
        header?: {
          'x-qd-version'?: string | null;
        };
        query?: never;
        path?: never;
        cookie?: never;
      };
      requestBody?: never;
      responses: {
        /** @description Successful Response */
        200: {
          headers: Record<string, unknown>;
          content: {
            'application/json': { incident: boolean; tag: string };
          };
        };
        /** @description Validation Error */
        default: {
          headers: Record<string, unknown>;
          content: {
            'application/json': unknown;
          };
        };
      };
    };
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
};

export type Paths = Omit<paths, keyof PublicPaths>;

export type AllPaths = PublicPaths & Paths;

export type QueryData<
  TPaths extends PathsWithMethod<AllPaths, TMethod>,
  TMethod extends HttpMethod = 'get',
> = AllPaths[TPaths][TMethod] extends object ? SuccessResponseJSON<AllPaths[TPaths][TMethod]> : never;

export type QueryOptions<
  TPath extends PathsWithMethod<AllPaths, TMethod>,
  TMethod extends HttpMethod = 'get',
> = ParamsOption<AllPaths[TPath][TMethod]>;

export type MutationOptions<
  Path extends PathsWithMethod<AllPaths, Method>,
  Method extends HttpMethod = 'post',
> = RequestOptions<AllPaths[Path][Method]>;

export type FromPathParams<T> = T extends {
  params: {
    path: Record<string, unknown>;
  };
}
  ? T['params']['path']
  : never;

export type FromQueryParams<T> = T extends {
  params?: {
    query?: Record<string, unknown>;
  };
}
  ? T extends {
      params?: {
        query?: never;
      };
    }
    ? never
    : Exclude<NonNullable<T['params']>['query'], undefined>
  : never;

type FromParams<T> =
  FromPathParams<T> extends never
    ? [void, FromQueryParams<T> extends never ? void : FromQueryParams<T> extends never ? void : FromQueryParams<T>]
    : FromQueryParams<T> extends never
      ? [FromPathParams<T>]
      : [FromPathParams<T>, FromQueryParams<T> | void];

export const AnyQueryKey = Symbol('AnyQueryKey');

export type QueryKey = PathsWithMethod<AllPaths, 'get'>;
export type MutationKey =
  | PathsWithMethod<AllPaths, 'post'>
  | PathsWithMethod<AllPaths, 'put'>
  | PathsWithMethod<AllPaths, 'patch'>
  | PathsWithMethod<AllPaths, 'delete'>;

export function toPathParams<TParams extends Record<string, unknown>>(
  params: TParams,
): {
  params: { path: TParams };
};
export function toPathParams<TKey extends string, TParams extends Record<TKey, unknown>>(
  params: TParams,
  options?: { filterKeys: TKey[] },
): {
  params: { path: Pick<TParams, TKey> };
};
export function toPathParams<TKey extends string, TParams extends Record<TKey, unknown>>(
  params: TParams,
  options?: { filterKeys: TKey[] },
) {
  if (!options || options.filterKeys.length === 0) {
    return { params: { path: params } };
  }
  return {
    params: {
      path: objectFromEntries(objectEntries(params).filter(([key]) => options.filterKeys.includes(key as TKey))),
    },
  };
}

export function toQueryParams<TQuery extends Record<string, unknown>>(query: TQuery) {
  return { params: { query } };
}

export const queryKey = {
  post: <TPath extends PathsWithMethod<AllPaths, 'post'>, TParameters = ParamsOption<AllPaths[TPath]['post']>>(
    path: TPath,
    ...[params, query]: FromParams<TParameters>
  ) => {
    const queryKey: [MutationKey, ...(readonly unknown[])] = [
      path,
      params ? toPathParams(params) : undefined,
      query ? toQueryParams(query) : undefined,
    ];
    return queryKey;
  },
  delete: <TPath extends PathsWithMethod<AllPaths, 'delete'>, TParameters = ParamsOption<AllPaths[TPath]['delete']>>(
    path: TPath,
    ...[params, query]: FromParams<TParameters>
  ) => {
    const queryKey: [MutationKey, ...(readonly unknown[])] = [
      path,
      params ? toPathParams(params) : undefined,
      query ? toQueryParams(query) : undefined,
    ];
    return queryKey;
  },
  put: <TPath extends PathsWithMethod<AllPaths, 'put'>, TParameters = ParamsOption<AllPaths[TPath]['put']>>(
    path: TPath,
    ...[params, query]: FromParams<TParameters>
  ) => {
    const queryKey: [MutationKey, ...(readonly unknown[])] = [
      path,
      params ? toPathParams(params) : undefined,
      query ? toQueryParams(query) : undefined,
    ];
    return queryKey;
  },
  patch: <TPath extends PathsWithMethod<AllPaths, 'patch'>, TParameters = ParamsOption<AllPaths[TPath]['patch']>>(
    path: TPath,
    ...[params, query]: FromParams<TParameters>
  ) => {
    const queryKey: [MutationKey, ...(readonly unknown[])] = [
      path,
      params ? toPathParams(params) : undefined,
      query ? toQueryParams(query) : undefined,
    ];
    return queryKey;
  },
  get: <TPath extends QueryKey, TParameters = ParamsOption<AllPaths[TPath]['get']>>(
    path: TPath,
    ...[params, query]: FromParams<TParameters>
  ) => {
    const queryKey: [QueryKey, ...(readonly unknown[])] = [
      path,
      params ? toPathParams(params) : undefined,
      query ? toQueryParams(query) : undefined,
    ];
    return queryKey;
  },
};

export type ComponentSchema<K extends keyof components['schemas']> = components['schemas'][K] extends infer S
  ? S
  : never;
