import { User } from '@auth0/auth0-react';
import { TrackEventProperties } from '@qdrant/qdrant-analytics-events';
import { captureException } from '@sentry/react';
import { eventQueue } from '../components/Analytics/AnalyticsProvider';
import { retrieveUtmParamsSession } from './analytics-utils';

/**
 * Track the login event when the user logs in or signs up.
 * It is necessary to do it here instead of Auth0 actions for AdWord conversion tracking to function properly.
 */
export function onExecutePostLogin(user: User) {
  eventQueue.enqueue(() => {
    if (!(user.sub && user.email && user.loginCount)) {
      captureException(new Error('Auth0 user missing sub or claims'), {
        level: 'fatal',
      });
      return;
    }

    const authProviderName = getAuthenticationType(user.sub);
    if (!authProviderName) {
      captureException(
        new Error(
          // eslint-disable-next-line @stylistic/js/max-len
          `Unsupported authentication string type. Silence this error by implementing handling for this new type - check Additional Data: sub`,
        ),
        {
          level: 'fatal',
          extra: { sub: user.sub },
        },
      );
      return;
    }

    const eventName: 'login' | 'signup' = user.loginCount < 2 ? 'signup' : 'login';
    const properties = {
      authentication_type: authProviderName,
      ...retrieveUtmParamsSession(),
    } satisfies TrackEventProperties<'login'>;

    if (eventName === 'signup') {
      window.dataLayer = window.dataLayer ?? [];
      (window.dataLayer as Record<string, string>[]).push({
        event: eventName,
        email: user.email,
      });
    }

    return window.analytics.track(eventName, properties, { traits: { email: user.email } });
  });
}

/* eslint-disable @cspell/spellchecker */
export type AuthenticationType =
  | 'google-oauth2'
  | 'github'
  | 'email'
  /** @see https://community.auth0.com/t/sub-formatting-in-the-jwt-id-token-for-enterprise-connections/182837/3 */
  | 'ad'
  | 'adfs'
  | 'auth0-adldap'
  | 'google-apps'
  | 'ip'
  | 'office365'
  | 'oidc'
  | 'pingfederate'
  | 'samlp'
  | 'sharepoint'
  | 'waad'
  // custom ...
  | 'okta';

export function getSubjectClaim(sub: string) {
  return sub.split('|')[0] as AuthenticationType | undefined;
}

export function getAuthenticationType(sub: string): TrackEventProperties<'login'>['authentication_type'] | void {
  const type = getSubjectClaim(sub);
  if (!type) {
    return;
  }

  const authTypeMap: Record<AuthenticationType, TrackEventProperties<'login'>['authentication_type']> = {
    'google-oauth2': 'Google',
    github: 'GitHub',
    email: 'Email',
    ad: 'LDAP',
    adfs: 'ADFS',
    'auth0-adldap': 'Auth0 AD/LDAP',
    'google-apps': 'Google Workspace',
    ip: 'IP',
    office365: 'Office 365',
    oidc: 'OIDC',
    pingfederate: 'PingFederate',
    samlp: 'SAML',
    sharepoint: 'SharePoint',
    waad: 'Windows Azure AD',
    // @ts-expect-error - custom ...
    okta: 'Okta',
  };

  return authTypeMap[type];
}

/* eslint-enable @cspell/spellchecker */
