import { createRoute, redirect } from '@tanstack/react-router';
import { getBookingPackagesQuery } from '@/api/services/booking';
import { getClustersByAccountIdQuery } from '@/api/services/cluster';
import { getPaymentInformationQuery } from '@/api/services/payment';
import { featureFlagsQueryOptions } from '@/api/services/public';
import { enqueueSnackbar } from '@/hooks/use-qdrant-snackbar';
import { head } from '@/router/Head';
import { ensureAccountUser } from '@/router/accountUser';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '@/router/utils';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { getMaxAllowedClusters, isFreeTierCreated } from '@/utils/cluster-utils';
import { Route as ClustersRoute } from '../clusters_';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: 'Create Cluster',
      permissions: ['write:clusters'],
    },
    getParentRoute: () => ClustersRoute,
    path: 'create',
    validateSearch: clusterCreateSearchSchema.and(clusterSetupSearchSchema),
    loaderDeps: ({ search: { provider, region, template } }) => ({ provider, region, template }),
    context: ({ params: { accountId }, deps: { provider, region } }) => ({
      paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
      clustersByAccountIdQuery: getClustersByAccountIdQuery({ account_id: accountId }),
      bookingQueryOptions: getBookingPackagesQuery({
        account_id: accountId,
        provider,
        region,
      }),
    }),
    beforeLoad: async ({ context: { queryClient, clustersByAccountIdQuery }, params, search }) => {
      const [featureFlags, accountUser, clusters] = await Promise.all([
        queryClient.ensureQueryData(featureFlagsQueryOptions),
        ensureAccountUser({ queryClient, accountId: params.accountId }),
        queryClient.fetchQuery(clustersByAccountIdQuery),
      ]);

      if (search.template === 'free' && isFreeTierCreated(clusters)) {
        throw redirect({
          to: '/accounts/$accountId/clusters/create',
          params,
          search: { ...search, template: 'standard' },
          replace: true,
        });
      }

      if (!featureFlags.cluster_creation_enabled) {
        enqueueSnackbar({ message: 'Cluster creation is currently disabled.', variant: 'warning' });
        throw redirect({ to: '/accounts/$accountId/clusters', params });
      }

      const accountPrivileges = accountUser.account.privileges;

      if (accountPrivileges) {
        if (clusters.length >= getMaxAllowedClusters(accountPrivileges)) {
          enqueueSnackbar({
            message: (
              <>
                You have reached the maximum number of clusters allowed for your account.
                <br />
                Please contact support if you wish to increase your cluster limit.
              </>
            ),
            variant: 'warning',
          });
          throw redirect({ to: '/accounts/$accountId/clusters', params });
        }
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-create" */ './create.lazy').then(({ Route }) => Route));
