import { createRoute, redirect } from '@tanstack/react-router';
import { isNotFoundError } from '@/api/errors';
import { getPaymentAvailableQuery } from '@/api/services/payment';
import { head } from '@/router/Head';
import { Route as HybridCloudRoute } from '@/routes/(account-dashboard)/hybrid-cloud_';
import { RootRouteContext } from '@/routes/__root';
import { captureException } from '@/utils/error-utils';

export const Route = createRoute({
  head,
  staticData: {
    title: ['Hybrid Cloud', 'Onboarding'],
  },
  getParentRoute: () => HybridCloudRoute,
  path: 'onboarding',
  async beforeLoad({ context: { queryClient, hybridCloudEnvsQuery }, params, matches }) {
    const envs = await queryClient.ensureQueryData(hybridCloudEnvsQuery);
    // If there are 2 or more environments, redirect to the hybrid-cloud page
    if (envs.length > 1) {
      throw redirect({ to: '/accounts/$accountId/hybrid-cloud', params, replace: true });
    }
    let redirectObj = null;
    // If there are existing environments when navigating to /onboarding, redirect to the connection page
    if (envs.length === 1) {
      redirectObj = redirect({
        to: '/accounts/$accountId/hybrid-cloud/onboarding/connection',
        params,
        replace: true,
      });
    } else {
      // If there are no existing environments, check if the account has payment information; the permissions are checked in the payment route
      const paymentInformationAvailable = await hasPaymentInformation(queryClient, params.accountId);
      if (!paymentInformationAvailable) {
        redirectObj = redirect({
          to: '/accounts/$accountId/hybrid-cloud/onboarding/payment',
          params,
          replace: true,
        });
      } else {
        // If the account has payment information, redirect to the configuration page
        redirectObj = redirect({
          to: '/accounts/$accountId/hybrid-cloud/onboarding/configuration',
          params,
          replace: true,
        });
      }
    }
    // If the last match is not the redirect path, throw the redirect object
    if (matches.at(-1)?.fullPath !== redirectObj?.to) {
      throw redirectObj;
    }
  },
}).lazy(() => import(/* webpackChunkName: "hybrid-cloud-onboarding" */ './index.lazy').then(({ Route }) => Route));

async function hasPaymentInformation(queryClient: RootRouteContext['queryClient'], accountId: string) {
  try {
    await queryClient.fetchQuery(getPaymentAvailableQuery({ account_id: accountId }));
    return true;
  } catch (error) {
    if (!isNotFoundError(error)) {
      captureException(new Error('Internal server error while checking if payment information exists'));
    }
  }
  return false;
}
