import { createRoute, LinkProps, notFound, redirect } from '@tanstack/react-router';
import { head } from '@/router/Head';
import { ensureAccountUser } from '@/router/accountUser';
import { CurrentAccountUser } from '@/router/hooks';
import { hasPermission, hasPrivilege } from '@/utils/access-control';
import { Route as AccountDashboardRoute } from '..';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Cloud UI Access',
  },
  getParentRoute: () => AccountDashboardRoute,
  path: 'cloud-access',
  beforeLoad: async ({ context: { queryClient }, params, ...rest }) => {
    const userAccount = await ensureAccountUser({ queryClient, accountId: params.accountId });
    const redirectionRoute = resolveRedirectPath(userAccount);

    if (!redirectionRoute) {
      throw notFound();
    }

    const activeURL = rest.matches.at(-1)?.fullPath as string;

    if (activeURL && doesURLExist(activeURL)) {
      const isValid = isCurrentURLValid(userAccount, activeURL);
      if (!isValid) {
        throw notFound();
      }
    }

    const isExactMatch = rest.matches.at(-1)?.routeId === Route.id;

    if (isExactMatch) {
      throw redirect({ to: redirectionRoute, params });
    }
  },
}).lazy(() => import(/* webpackChunkName: "cloud-access" */ './index.lazy').then(({ Route }) => Route));

type RouteConfig = {
  path: LinkProps['to'];
  check: (userAccount: CurrentAccountUser) => boolean;
  priority: number;
};

export const ACCESS_ROUTES: RouteConfig[] = [
  {
    path: '/accounts/$accountId/cloud-access/roles/all-users',
    check: (userAccount) => {
      const hasUserAccess = hasPermission({
        permissions: ['read:users'],
        userAccount,
      });
      return hasUserAccess;
    },
    priority: 1,
  },
  {
    path: '/accounts/$accountId/cloud-access/cloud-management-keys',
    check: (userAccount) =>
      hasPrivilege({ privileges: ['PROGRAMMATIC_ACCESS'], userAccount }) &&
      hasPermission({ permissions: ['read:management_keys'], userAccount }),
    priority: 2,
  },
  {
    path: '/accounts/$accountId/cloud-access/database-api-keys',
    check: (userAccount) => hasPermission({ permissions: ['read:api_keys'], userAccount }),
    priority: 3,
  },
];

const resolveRedirectPath = (userAccount: CurrentAccountUser): string => {
  const route = ACCESS_ROUTES.sort((a, b) => a.priority - b.priority).find((route) => route.check(userAccount));

  return route?.path ?? '';
};

export const isCurrentURLValid = (userAccount: CurrentAccountUser, path: string): boolean => {
  const route = ACCESS_ROUTES.find((route) => route.path === path);
  return route?.check(userAccount) ?? false;
};

export const doesURLExist = (path: string): boolean => Boolean(ACCESS_ROUTES.find((route) => route.path === path));
