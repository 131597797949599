import { BookingPackageDto } from '@/api/services/booking';
import { ClusterDto } from '@/api/services/cluster';
import { ComponentSchema } from '@/api/services/utils';
import { BOOKING_PACKAGE_TYPE_FREE } from '@/components/Clusters/ClusterSetup/helpers';
import { CLOUD_PROVIDER_MAP } from '@/components/Clusters/constants';
import { AccountPrivileges } from './access-control';

export type HybridCloudEnvConfigIn = ComponentSchema<'PydanticPrivateRegionConfigIn'>;
export type PrivateRegionStatus = ComponentSchema<'PydanticPrivateRegionStatus'>;
export type PrivateRegionComponentPhase = ComponentSchema<'PrivateRegionStatusComponentStatusPhase'>;
export type BackupSchedule = ComponentSchema<'PydanticSchedule'>;
export type Backup = ComponentSchema<'PydanticBackup'>;
export type ClusterResources = ComponentSchema<'ClusterOut'>['resources'];
export type ClusterResource = ComponentSchema<'ClusterResources'>;
export type QdrantConfiguration = ComponentSchema<'QdrantConfiguration-Output'>;
export type LogLevels = ComponentSchema<'PydanticPrivateRegionConfigIn'>['log_level'];
export type ClusterUsageMetrics = ComponentSchema<'ClusterUsageMetricsResponse'>;
export type ClusterMetricsOverview = ComponentSchema<'ClusterMetricOverviewResponse'>;
export type MetricsResponse = ClusterUsageMetrics | ClusterMetricsOverview;

export type NodesUsageMetrics = ClusterUsageMetrics['nodes'];

export type NodesMetrics = ClusterMetricsOverview['nodes'];
export type NodeMetrics = NonNullable<NodesMetrics>[number];
export type MetricType = Exclude<keyof NodeMetrics, 'node_id'>;

export function getFreeTierPackage(packages: BookingPackageDto[]) {
  return packages.find((entry) => entry.type === BOOKING_PACKAGE_TYPE_FREE);
}

/**
 * Check if the given cluster is a free tier
 */
export function isClusterFreeTier(cluster?: ClusterDto) {
  const bookingPackage = cluster?.configuration?.node_configuration?.package;
  if (!bookingPackage) {
    return false;
  }
  return bookingPackage.type === BOOKING_PACKAGE_TYPE_FREE;
}

/**
 * Check if user already created a free tier cluster
 */
export function isFreeTierCreated(clusters?: ClusterDto[]) {
  if (!clusters?.length) {
    return false;
  }
  return clusters.some(isClusterFreeTier);
}

export function getNodeNumber(clusterId: string, nodeId: string): string {
  return nodeId.replace(`qdrant-${clusterId}-`, '');
}

/**
 * Check if the given cluster is a free tier
 */
export const isClusterHybridCloud = (cluster?: ClusterDto) => cluster?.cloud_provider === CLOUD_PROVIDER_MAP.PRIVATE;

export const getNonHybridClusters = (clusters: ClusterDto[] = []) =>
  clusters.filter((cluster) => !isClusterHybridCloud(cluster));

export const getClusterEndpoint = (cluster?: ClusterDto, fallback = '') => cluster?.state?.endpoint ?? fallback;

export const isClusterHealthy = (cluster: ClusterDto | null) => cluster?.state?.phase === 'Healthy';

/**
 * For now we consider a cluster as not reachable if it is in the 'Creating' state. But this logic should be extended
 * to consider other states that might indicate that the cluster is not reachable.
 */
export const isClusterNotReachable = (cluster: ClusterDto | null) => cluster?.state?.phase === 'Creating';

export const metricsHaveNodesData = (metrics: MetricsResponse) => metrics?.nodes && metrics.nodes.length > 0;

// Type guard for the first type of Response
export const isClusterUsageMetrics = (response: MetricsResponse): response is ClusterUsageMetrics =>
  response?.nodes != null && response.nodes.length > 0 && Array.isArray(response.nodes[0].cpu);

export const hasClusterRbacEnabled = (cluster?: ClusterDto) =>
  cluster?.configuration?.qdrant_configuration?.service?.jwt_rbac ?? false;

export function getMaxAllowedClusters(availablePrivileges: AccountPrivileges) {
  const defaultMaxClusters = 10;
  const requiredPrivileges = ['MAX_NUM_CLUSTERS_20', 'MAX_NUM_CLUSTERS_30', 'MAX_NUM_CLUSTERS_50'] as const;
  const privilegeToMaxClusters = new Map<AccountPrivileges[number], number>([
    ['MAX_NUM_CLUSTERS_20', 20],
    ['MAX_NUM_CLUSTERS_30', 30],
    ['MAX_NUM_CLUSTERS_50', 50],
  ]);

  return requiredPrivileges.reduce(
    (acc, val) => (availablePrivileges.includes(val) ? Math.max(acc, privilegeToMaxClusters.get(val) ?? 0) : acc),
    defaultMaxClusters,
  );
}
