import { QueryClient } from '@tanstack/react-query';
import { createRoute, redirect } from '@tanstack/react-router';
import { isApiCodeError, isForbiddenError, isNotFoundError, QdrantErrorCode } from '@/api/errors';
import { createAccountMutationFn } from '@/api/services/account';
import { userEndpoints } from '@/api/services/accounts-endpoints';
import { FALLBACK_AUTH_ERROR } from '@/api/services/auth';
import { userMeQueryOptions } from '@/api/services/user';
import { router } from '@/router';
import { ensureAccountUser } from '@/router/accountUser';
import { AuthContext } from '@/routes/__root';
import { captureException } from '@/utils/error-utils';
import { Route as AuthenticatedRoute } from '..';

/**
 * Parent route for sub-routes that require the account user data, but DO NOT HAVE the param in the URL.
 */
export const Route = createRoute({
  getParentRoute: () => AuthenticatedRoute,
  id: 'account',
  /**
   * Load the user information, or create a new user if it doesn't exist.
   * Fetch errors are handled in the parent route's error boundary.
   */
  async beforeLoad({ context: { auth, queryClient }, location: { href } }) {
    const data = await fetchAccountUser({ queryClient });
    if (!data) {
      await createAccountUser({ auth, queryClient, pathname: href });
      return;
    }
    // Ensure redirection if the default account id is not part of the account data.
    await ensureAccountUser({ queryClient });
  },
});

/**
 * This async function gets the account user data from the API, please bare in mind:
 *
 * - If the account user data is not available, the user **should** be redirected
 *   to the logout page with {@link import('../../api/services/user').selectCurrentAccount}.
 * - If the account id is not part of the account data, the user is redirected to the default account
 *   or the first in the list.
 */
export async function fetchAccountUser({ queryClient }: { queryClient: QueryClient }) {
  try {
    return await queryClient.fetchQuery(userMeQueryOptions);
    // We're done! The userInfo is available. Pass the current account to the router data loader.
  } catch (err) {
    if (isNotFoundError(err)) {
      // Account user data not available, create the account.
      return null;
    }
    if (isForbiddenError(err)) {
      // User was deleted, this can happen if the user deleted the account.
      throw redirect({ to: '/logout', search: { aerr: 'Account no longer exists' } });
    }
    // This can either be a redirect or a fatal error.
    throw err;
  }
}

/**
 * This async function creates an account for the user if it doesn't exist.
 * The user object is expected to have the 'name' and 'sub' properties.
 * If the account already exists, the user is redirected to the logout page.
 */
export async function createAccountUser({
  queryClient,
  auth,
  pathname,
}: {
  queryClient: QueryClient;
  auth: AuthContext;
  pathname: string;
}) {
  const { user } = auth;
  if (!(user.name && user.sub)) {
    captureException(new Error('Properties `name` or `sub` missing in the Auth0 user object'), {
      level: 'fatal',
      tags: { context: 'auth', action: 'account_create', route: pathname },
    });
    throw redirect({ to: '/logout', search: { aerr: FALLBACK_AUTH_ERROR } });
  }

  try {
    const data = await createAccountMutationFn({ name: `${user.name} - Base Account`, owner_id: user.sub });
    // Invalidate /users/me (sanity check) and trigger the router lifecycle to re-fetch the data
    void Promise.all([queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }), router.invalidate()]);
    // Return data (at this point, just for tests)
    return data;
  } catch (err) {
    if (isApiCodeError(err) && err.error.detail === QdrantErrorCode.USER_ACCOUNT_EXISTS) {
      // Account already exists, this can happen if the user deleted the account.
      throw redirect({ to: '/logout', search: { aerr: 'Account already exists' } });
    }
    throw err;
  }
}
