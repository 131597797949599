import './polyfills';
import { Auth0Provider } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { BASE_URL, adblocker, authRedirectUrl } from '@/utils/constants';
import { queryClient } from './api/query-client';
import { DefaultErrorComponent } from './components/Common/DefaultErrorComponent';
import { EnsureCookieSupport } from './components/Common/EnsureCookieSupport';
import { NetworkStatus } from './components/Common/NetworkStatus';
import { VersionAlert } from './components/Common/VersionAlert';
import { Router } from './components/Router';
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context';
import { router } from './router';
import { createTheme } from './theme';
import { onExecutePostLogin } from './utils/auth-utils';
import { isProductionEnv } from './utils/utils';

try {
  Sentry.init({
    release: `cloud-ui@${window.__QDRANT_CLOUD__.version}`,
    dsn: window.__QDRANT_CLOUD__.sentry.dsn,
    environment: window.__QDRANT_CLOUD__.env,
    initialScope: {
      tags: {
        /**
         * Detects if user has adblocker enabled - the p8network.js script is injected into index.html,
         * which in turn injects a div with id="..." into the DOM.
         * @see https://www.detectadblock.com
         */
        adblocker,
      },
    },
    replaysSessionSampleRate: parseFloat(window.__QDRANT_CLOUD__.sentry.replays_session_sample_rate),
    replaysOnErrorSampleRate: parseFloat(window.__QDRANT_CLOUD__.sentry.replays_on_error_sample_rate),
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: parseFloat(window.__QDRANT_CLOUD__.sentry.traces_sample_rate),
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [BASE_URL],
        networkResponseHeaders: ['X-Qd-Request-Id'],
        maskAllText: false,
      }),
      /**
       * Filter out errors originating from third parties, such as browser extensions, code-injecting browsers,
       * or widgets from third-party services that also use Sentry.
       * @see http://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
       */
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['qdrant-cloud-ui'],
        // eslint-disable-next-line @cspell/spellchecker
        behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
      }),
    ],
    /**
     * Filter errors.
     * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
     */
    ignoreErrors: [
      // https://apps.apple.com/us/app/microsoft-edge-browse-with-ai/id1288723196
      "Can't find variable: msDiscoverChatAvailable",
    ],
    beforeSend(event) {
      if (event.tags?.ignored) {
        return null;
      }
      return event;
    },
    // Done thru web UI, see: https://qdrant.sentry.io/settings/projects/cloud-ui/filters/data-filters/
    // It seems to not filter everything, so we specify some filters here as well:
    denyUrls: [
      // Browser extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /safari-(web-)?extension:/i,
    ],
    beforeSendTransaction(event) {
      if (event.request?.url?.endsWith('/settings.json')) {
        return null;
      }
      return event;
    },
  });
} catch (e) {
  // eslint-disable-next-line no-console
  !isProductionEnv && console.warn(e);
}

const root = document.getElementById('root');

if (!root) {
  throw new Error('Root element not found');
}

createRoot(root).render(
  <Sentry.ErrorBoundary fallback={({ error }) => <DefaultErrorComponent error={error} />}>
    <EnsureCookieSupport>
      <Auth0Provider
        domain={window.__QDRANT_CLOUD__.auth0.domain}
        clientId={window.__QDRANT_CLOUD__.auth0.client_id}
        authorizationParams={{
          redirect_uri: authRedirectUrl.href,
          scope: window.__QDRANT_CLOUD__.auth0.scope,
          audience: window.__QDRANT_CLOUD__.auth0.audience,
        }}
        onRedirectCallback={(_, user) => {
          // Defining our own onRedirectCallback means we have to remove the code+state from the URL.
          router.history.replace(router.history.location.pathname);
          if (user) {
            onExecutePostLogin(user);
          }
        }}
        authorizeTimeoutInSeconds={10}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <StyledEngineProvider injectFirst={true}>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    direction: settings.direction,
                    responsiveFontSizes: settings.responsiveFontSizes,
                    theme: settings.theme,
                  })}
                >
                  <CssBaseline />
                  <SnackbarProvider maxSnack={3}>
                    <QueryClientProvider client={queryClient}>
                      <Router router={router} />
                      <VersionAlert />
                      <NetworkStatus />
                    </QueryClientProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </StyledEngineProvider>
      </Auth0Provider>
    </EnsureCookieSupport>
  </Sentry.ErrorBoundary>,
);
