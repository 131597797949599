import { Auth0ContextInterface } from '@auth0/auth0-react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { QueryClient } from '@tanstack/react-query';
import { Outlet, createRootRouteWithContext, redirect } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { featureFlagsQueryOptions } from '@/api/services/public';
import { AnalyticsProvider } from '@/components/Analytics/AnalyticsProvider';
import { AnalyticsRoot } from '@/components/Analytics/AnalyticsRoot';
import { SUGER_ENTITLEMENT_ID_COOKIE_NAME } from '@/components/Billing/SugerRegistration/constants';
import { GlobalAlertProvider } from '@/components/Common/GlobalAlert';
import { CookieHubProvider } from '@/contexts/cookie-hub-context';
import { SupportProvider } from '@/contexts/support-context';
import { head, Head } from '@/router/Head';
import { createRouteErrorComponent } from '@/router/createRouteErrorComponent';
import { rootSearchSchema } from '@/router/utils';
import { getUtmParamsFromSearch, persistUtmParamsSession } from '@/utils/analytics-utils';
import { Cookies } from '@/utils/cookies';

export type AuthContext = Omit<Auth0ContextInterface, 'isLoading' | 'user'> &
  Required<Pick<Auth0ContextInterface, 'user'>>;

export type RootRouteContext = {
  queryClient: QueryClient;
  auth: AuthContext;
};

export const Route = createRootRouteWithContext<RootRouteContext>()({
  head,
  staticData: {
    title: 'Qdrant Cloud',
  },
  validateSearch: rootSearchSchema,
  beforeLoad({ search, context: { queryClient } }) {
    let shouldCleanupSearch = false;

    const utmParams = getUtmParamsFromSearch(search);
    if (utmParams) {
      persistUtmParamsSession(utmParams);
      shouldCleanupSearch = true;
    }

    const { sugerEntitlementId } = search;
    if (sugerEntitlementId) {
      Cookies.set(SUGER_ENTITLEMENT_ID_COOKIE_NAME, sugerEntitlementId);
      shouldCleanupSearch = true;
    }

    if (shouldCleanupSearch) {
      // Clean up url from UTM params.
      throw redirect({
        to: '.',
        search: (prev) => ({
          ...prev,
          ...(utmParams ? Object.keys(utmParams).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}) : null),
          sugerEntitlementId: undefined,
        }),
      });
    }

    void queryClient.prefetchQuery(featureFlagsQueryOptions);
  },
  component: RootComponent,
  errorComponent: createRouteErrorComponent(),
});

const TanStackDevToolsLazy = lazy(() =>
  import(/* webpackChunkName: 'tanstack-dev-tools' */ '../router/TanStackDevTools').then(({ RouterDevTools }) => ({
    default: RouterDevTools,
  })),
);

function RootComponent() {
  return (
    <>
      <Head />
      <GTMProvider state={{ id: window.__QDRANT_CLOUD__.gtm_id }}>
        <CookieHubProvider>
          {({ allowedAnalytics }) => (
            <AnalyticsProvider disabled={!allowedAnalytics}>
              <GlobalAlertProvider>
                <AnalyticsRoot>
                  <SupportProvider>
                    <Outlet />
                  </SupportProvider>
                </AnalyticsRoot>
              </GlobalAlertProvider>
            </AnalyticsProvider>
          )}
        </CookieHubProvider>
        {window.__QDRANT_CLOUD__.env === 'dev' && (
          <Suspense>
            <TanStackDevToolsLazy />
          </Suspense>
        )}
      </GTMProvider>
    </>
  );
}
