import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { CreateThemeSettings } from '../contexts/settings-context';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

export const createTheme = (config: Partial<CreateThemeSettings>) => {
  const { direction } = config;
  let theme = createMuiTheme(baseThemeOptions, config.theme === 'dark' ? darkThemeOptions : lightThemeOptions, {
    direction,
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
