import { queryOptions } from '@tanstack/react-query';
import { FromPathParams, QueryData, queryKey, QueryOptions } from './utils';

export const endpoints = {
  databaseReleases: '/accounts/{account_id}/releases',
  databaseUpdateCandidates: '/accounts/{account_id}/clusters/{cluster_id}/update-candidates',
} as const;

export type DatabaseReleasesDto = QueryData<typeof endpoints.databaseReleases>;
export type DatabaseUpdateCandidatesDto = QueryData<typeof endpoints.databaseUpdateCandidates>;

export const getDatabaseReleasesQuery = (params: FromPathParams<QueryOptions<typeof endpoints.databaseReleases>>) =>
  queryOptions<DatabaseReleasesDto>({
    queryKey: queryKey.get(endpoints.databaseReleases, params),
  });

export const getDatabaseUpdateCandidatesQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.databaseUpdateCandidates>>,
) =>
  queryOptions<DatabaseUpdateCandidatesDto>({
    queryKey: queryKey.get(endpoints.databaseUpdateCandidates, params),
  });
