import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { merge } from 'ts-deepmerge';

type ClusterInfo = {
  clusterVersion?: string;
};

type SupportData = ClusterInfo;

type SupportContextType = {
  supportData: SupportData;
  setSupportData: (info: Partial<SupportData>) => void;
};

export const SupportContext = createContext<SupportContextType | undefined>(undefined);

export const SupportProvider = ({ children }: { children: ReactNode }) => {
  const [supportData, setData] = useState<SupportData>({});

  const setSupportData = useCallback((info: Partial<SupportData>) => {
    setData((prev) => {
      const newInfo = merge({}, prev, info) as SupportData;
      setData(newInfo);
      return newInfo;
    });
  }, []);

  return <SupportContext.Provider value={{ supportData, setSupportData }}>{children}</SupportContext.Provider>;
};

// Hook for using context
export const useSupportData = () => {
  const context = useContext(SupportContext);
  if (!context) {
    throw new Error('useSupport must be used within a SupportProvider');
  }
  return context;
};
