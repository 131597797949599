import { QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';
import { DefaultNotFoundComponent } from '@/components/Common/DefaultNotFoundComponent';
import { LoadingIndicator } from '@/components/Common/LoadingIndicator';
import { AuthContext } from '@/routes/__root';
import { AccountPermissions, AccountPrivileges } from '@/utils/access-control';
import { ProfileRouteMask, routeTree } from './routeTree';

export const router = createRouter({
  routeTree,
  routeMasks: [ProfileRouteMask],
  context: {
    queryClient: {} as QueryClient,
    auth: {} as AuthContext,
  },
  // defaultPendingMinMs: 500,
  // defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  // By default, TanStack Router will show a pending component for loaders that take longer than 1 second to resolve.
  defaultPendingComponent: LoadingIndicator,
  defaultNotFoundComponent: DefaultNotFoundComponent,
  // Note: do not specify a default error component, use the createRouteErrorComponent instead.
  defaultErrorComponent: undefined,
});

/* eslint-disable @typescript-eslint/naming-convention */
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }

  interface HistoryState {
    returnTo?: string;
  }

  interface StaticDataRouteOption {
    privileges?: AccountPrivileges;
    permissions?: AccountPermissions;
    title?: string | string[];
  }
}
