import { AuthorizationParams } from '@auth0/auth0-react';
import { createRoute, redirect } from '@tanstack/react-router';
import { settingsQueryOptions } from '@/api/services/public';
import { head } from '@/router/Head';
import { loginSearchSchema } from '@/router/utils';
import { retrieveUtmParamsSession } from '@/utils/analytics-utils';
import { authRedirectUrl, BASE_URL, qdrantCloudUrl } from '@/utils/constants';
import { captureException } from '@/utils/error-utils';
import { preferredHostForCDN } from '@/utils/url-utils';
import { Route as RootRoute } from '../__root';

type CustomAuthorizationParams = AuthorizationParams & {
  'ext-back_link': string;
  'ext-gtm_id': string;
  'ext-host': string;
  'ext-incident_mode'?: string;
  'ext-aerr'?: string;
  'ext-sso'?: string;
};

export const Route = createRoute({
  head,
  staticData: {
    title: 'Logging in ...',
  },
  getParentRoute: () => RootRoute,
  path: 'login',
  validateSearch: loginSearchSchema,
  beforeLoad: async ({
    context: { auth, queryClient },
    // A redirection with SSO params happens when a normal login connection was used instead, and the API + UI needs to redirect /login with these sso params.
    search: { aerr: authError, sso, sso_connection: ssoConnection, sso_login_hint: ssoLoginHint },
  }) => {
    if (auth.isAuthenticated) {
      throw redirect({ to: authRedirectUrl.pathname, replace: true });
    }

    const authParams: CustomAuthorizationParams = {
      'ext-back_link': BASE_URL,
      'ext-gtm_id': window.__QDRANT_CLOUD__.gtm_id,
      'ext-host': preferredHostForCDN(qdrantCloudUrl.host),
    };
    // Add utm custom params
    Object.entries(retrieveUtmParamsSession()).forEach(([key, value]) => {
      authParams[`ext-${key}`] = value;
    });

    if (sso) {
      authParams['ext-sso'] = 'true';
    }
    if (ssoConnection) {
      /*
       * ssoConnection: SSO connection name/identifier that will be used to redirect the user to the SSO login page
       * ssoLoginHint: SSO email address to fill-in the email field in the signup form
       */
      authParams.connection = ssoConnection;
      authParams.login_hint = ssoLoginHint;
    }
    /*
     * The `authError` query parameter is set by the Auth0 SDK when an error occurs during authentication.
     * It could also happen during the silent refreshing/retrieval of the access token.
     * See: https://auth0.com/docs/api/authentication?javasaddiaddovcript#:~:text=Standard%20Error%20Responses
     */
    if (authError) {
      authParams['ext-aerr'] = authError;
    }

    // The `incident_mode` custom parameter is set when INCIDENT_MODE is active via /settings.json
    const { incident } = await queryClient.fetchQuery(settingsQueryOptions);
    if (incident) {
      authParams['ext-incident_mode'] = 'true';
    }

    try {
      await auth.loginWithRedirect({
        authorizationParams: {
          audience: window.__QDRANT_CLOUD__.auth0.audience,
          scope: window.__QDRANT_CLOUD__.auth0.scope,
          redirect_uri: authRedirectUrl.href,
          screen_hint: 'login',
          ...authParams,
        },
      });
    } catch (error) {
      captureException(error);
      throw error;
    }
  },
});
