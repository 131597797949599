import { Button, ButtonProps, css, Stack, styled, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useCallback } from 'react';
import { CloudProvider, CloudRegion } from '@/api/services/cluster';
import { useAuth } from '@/router/hooks';
import { typedMemo } from '@/utils/func-utils';
import { hasDeveloperRole } from '@/utils/user-utils';
import { isProductionEnv } from '@/utils/utils';
import { CLOUD_PROVIDER_CONFIG, CLOUD_PROVIDER_MAP } from '../constants';
import { ClusterTemplateType } from './ClusterTemplateList';
import { useProviderRegions } from './hooks/use-provider-regions';

const StyledRegion = styled(Button, {
  shouldForwardProp: (prop) => !prop.toString().startsWith('$'),
})<ButtonProps & { $selected?: boolean }>(({ theme: { palette }, $selected }) =>
  $selected
    ? css`
        background: ${alpha(palette.background.selected, 0.1)} !important;
        border-color: ${palette.background.selected} !important;
      `
    : null,
);

export function getDefaultRegion(provider: CloudProvider, preSelectedRegion?: CloudRegion): CloudRegion {
  if (provider === CLOUD_PROVIDER_MAP.PRIVATE) {
    /*
     * TODO: once the old create cluster dialog is removed, the `defaultRegion` value
     * can be changed from '' to 'private' and this can be removed as well.
     */
    return CLOUD_PROVIDER_MAP.PRIVATE;
  }
  const appEnv = isProductionEnv ? 'production' : 'staging';
  const provider_config = CLOUD_PROVIDER_CONFIG.providers[provider][appEnv];
  // If there was a pre-selected region, check if it belongs to the selected provider.
  if (preSelectedRegion && provider_config.availableRegions.some(({ value }) => preSelectedRegion === value)) {
    return preSelectedRegion;
  }
  return provider_config.defaultRegion;
}

export const ClusterRegionList = typedMemo(function ClusterRegionList<TRegion extends CloudRegion>({
  provider,
  region,
  onChange,
  clusterTemplate,
  ariaLabelledBy,
}: {
  provider: Exclude<CloudProvider, 'private'>;
  region: TRegion;
  onChange: (value: TRegion) => void;
  clusterTemplate?: ClusterTemplateType;
  ariaLabelledBy?: string;
}) {
  const { user } = useAuth();
  const clickHandler = useCallback(
    (data: TRegion) => {
      if (data !== region) {
        onChange(data);
      }
    },
    [onChange, region],
  );

  const regions = useProviderRegions({ provider, clusterTemplate });

  return regions.map(([geography, providerRegions]) => (
    <Stack
      key={geography}
      direction="row"
      gap={1}
      sx={{ mb: 1.5 }}
      alignItems="center"
      justifyContent="flex-start"
      flexWrap="wrap"
      aria-labelledby={ariaLabelledBy}
    >
      <Typography variant="body1" color="text.secondary" sx={{ mr: { md: 1.5 }, fontSize: '14px' }}>
        {geography}
      </Typography>
      {providerRegions.map(({ name, flag, value, devOnly }) =>
        !devOnly || hasDeveloperRole(user) ? (
          <StyledRegion
            key={`${geography}_${value}`}
            variant="outlined"
            size="small"
            $selected={region === value}
            onClick={() => clickHandler(value as TRegion)}
          >
            {flag} {name}
          </StyledRegion>
        ) : null,
      )}
    </Stack>
  ));
});
