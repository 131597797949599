import { createRoute } from '@tanstack/react-router';
import { head } from '@/router/Head';
import { Route as HybridCloudOnboardingRoute } from '../onboarding_';

export const Route = createRoute({
  head,
  staticData: {
    title: ['Hybrid Cloud', 'Onboarding'],
  },
  getParentRoute: () => HybridCloudOnboardingRoute,
  path: 'configuration',
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-onboarding-configuration" */ './configuration.lazy').then(
    ({ Route }) => Route,
  ),
);
