import { Button, ButtonProps, ListItemButton, MenuItem, MenuItemProps, Link as MuiLink } from '@mui/material';
import { createLink, CreateLinkProps } from '@tanstack/react-router';
import { forwardRef } from 'react';

export const ButtonLink = createLink(
  forwardRef<HTMLAnchorElement, CreateLinkProps & Omit<ButtonProps<'a'>, 'component'>>((props, ref) => (
    <Button {...props} ref={ref} component={MuiLink} />
  )),
);

export const MenuItemLink = createLink(
  forwardRef<HTMLAnchorElement, CreateLinkProps & Omit<MenuItemProps<'a'>, 'component'>>((props, ref) => (
    <MenuItem {...props} ref={ref} component={MuiLink} />
  )),
);

export const ListItemButtonLink = createLink(
  forwardRef<HTMLAnchorElement, CreateLinkProps & Omit<MenuItemProps<'a'>, 'component'>>((props, ref) => (
    <ListItemButton {...props} ref={ref} component={MuiLink} />
  )),
);

export const Link = createLink(MuiLink);
