import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useRootContext } from '@/router/hooks';
import { client } from '../query-client';
import {
  ComponentSchema,
  FromPathParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from './utils';

export const endpoints = {
  hybridCloudEnvs: '/accounts/{account_id}/hybrid-cloud-environments',
  hybridCloudEnv: '/accounts/{account_id}/hybrid-cloud-environments/{hybrid_cloud_env_id}',
  generateHybridCloudEnvInitialInstallationCommand:
    '/accounts/{account_id}/hybrid-cloud-environments/{hybrid_cloud_env_id}/initial-installation-command',
} as const;

export type HybridCloudEnvDto = QueryData<typeof endpoints.hybridCloudEnv>;
export type OperatorV2ConfigurationDto = ComponentSchema<'PydanticPrivateRegionConfigOut'>['operator_v2_config'];

export const getHybridCloudEnvsQuery = (params: FromPathParams<QueryOptions<typeof endpoints.hybridCloudEnvs>>) =>
  queryOptions<QueryData<typeof endpoints.hybridCloudEnvs>>({
    queryKey: queryKey.get(endpoints.hybridCloudEnvs, params),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

export const getHybridCloudEnvQuery = (params: FromPathParams<QueryOptions<typeof endpoints.hybridCloudEnv>>) =>
  queryOptions<HybridCloudEnvDto>({
    queryKey: queryKey.get(endpoints.hybridCloudEnv, params),
  });

export const useCreateHybridCloudEnvMutation = <
  TOptions extends MutationOptions<typeof endpoints.hybridCloudEnvs, 'post'>,
  TParams extends FromPathParams<TOptions>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.hybridCloudEnvs, {
        ...toPathParams(params),
        body,
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.hybridCloudEnvs, params),
      });
      await router.invalidate();
    },
  });
};

export const useUpdateHybridCloudEnvMutation = <
  TOptions extends MutationOptions<typeof endpoints.hybridCloudEnv, 'put'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables & TParams) => {
      const { account_id, hybrid_cloud_env_id, ...rest } = body;
      const { data } = await client.PUT(endpoints.hybridCloudEnv, {
        params: {
          path: {
            account_id,
            hybrid_cloud_env_id,
          },
        },
        body: rest,
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.hybridCloudEnvs, params),
      });
      await router.invalidate();
    },
  });
};

export const useDeleteHybridCloudEnvironmentMutation = <
  TOptions extends MutationOptions<typeof endpoints.hybridCloudEnv, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async () => {
      const { hybrid_cloud_env_id, account_id } = params;
      const { data } = await client.DELETE(endpoints.hybridCloudEnv, {
        params: {
          path: {
            account_id,
            hybrid_cloud_env_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      /**
       * The normal invalidation isn't working and I don't see network requests when this mutation is complete. I think
       * that's because there TanStack could be getting confused because of two queries that have the same
       * query prefix `hybrid-cloud-environments` and the same account_id. The solution is to invalidate the
       * the entire query without the parameter seems to work.
       */
      await queryClient.invalidateQueries({
        queryKey: [endpoints.hybridCloudEnvs],
      });
      await router.invalidate();
    },
  });
};

export const useGenerateHybridCloudEnvInitialInstallationCommandMutation = <
  TOptions extends MutationOptions<typeof endpoints.generateHybridCloudEnvInitialInstallationCommand, 'post'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async () => {
      const { data } = await client.POST(endpoints.generateHybridCloudEnvInitialInstallationCommand, {
        params: {
          path: {
            account_id: params.account_id,
            hybrid_cloud_env_id: params.hybrid_cloud_env_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.hybridCloudEnv, params) });
      await queryClient.invalidateQueries({
        queryKey: queryKey.get(endpoints.hybridCloudEnvs, { account_id: params.account_id }),
      });
      await router.invalidate();
    },
  });
};
