type URLWithParse = typeof URL & {
  parse(url: string, base?: string): URL | null;
};

function parse(url: string, base?: string): URL | null {
  if (typeof URL.canParse === 'function') {
    return URL.canParse(url, base) ? new URL(url, base) : null;
  }

  // Fallback for browsers that don't support URL.canParse
  try {
    return new URL(url, base);
  } catch {
    return null;
  }
}

// Add URL.parse to URL object if not available
if (!('parse' in URL)) {
  (URL as URLWithParse).parse = parse;
}
