import { QueryClient } from '@tanstack/react-query';
import memoize from 'micro-memoize';
import { selectCurrentAccount, userMeQueryOptions } from '../api/services/user';

export const memoizedSelectCurrentAccount = memoize(selectCurrentAccount);

export async function ensureAccountUser({ queryClient, accountId }: { queryClient: QueryClient; accountId?: string }) {
  const data = await queryClient.ensureQueryData(userMeQueryOptions);

  return { ...data, account: memoizedSelectCurrentAccount(data, accountId) };
}
