import { List, ListSubheader, SxProps } from '@mui/material';
import { ReactElement } from 'react';
import { AccountDashboardSidebarItem } from './AccountDashboardSidebarItem';
import { SectionItem } from '.';

const NavItems = ({ items }: { items: SectionItem[]; depth?: number }) => (
  <List role="menu" disablePadding={true}>
    {items.reduce<ReactElement<typeof AccountDashboardSidebarItem>[]>(
      (acc, item) => reduceChildRoutes({ acc, item }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  item,
}: {
  acc: ReactElement<typeof AccountDashboardSidebarItem>[];
  item: SectionItem;
}) => {
  if (item.hidden) {
    return acc;
  }
  acc.push(<AccountDashboardSidebarItem key={item.title} icon={item.icon} title={item.title} href={item.href} />);

  return acc;
};

export const AccountDashboardSidebarSection = ({
  items,
  title,
  sx,
}: {
  items: SectionItem[];
  title: string;
  sx: SxProps;
}) => {
  const areAllItemsHidden = items.every((item) => item.hidden);
  if (areAllItemsHidden) {
    return null;
  }

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters={true}
          disableSticky={true}
          sx={{
            color: 'neutral.500',
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      }
      sx={sx}
    >
      <NavItems items={items} />
    </List>
  );
};
