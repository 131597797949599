import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useRootContext } from '@/router/hooks';
import { client } from '../query-client';
import {
  FromPathParams,
  FromQueryParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from './utils';

export const endpoints = {
  accountApiKeys: '/accounts/{account_id}/auth/api-keys',
  accountApiKey: '/accounts/{account_id}/auth/api-keys/{api_key_id}',
} as const;

export type AccountApiKeysDto = QueryData<typeof endpoints.accountApiKeys>;

export const getAccountApiKeysQuery = (
  params: FromPathParams<QueryOptions<typeof endpoints.accountApiKeys>>,
  query?: FromQueryParams<QueryOptions<typeof endpoints.accountApiKeys>>,
) =>
  queryOptions<AccountApiKeysDto>({
    queryKey: queryKey.get(endpoints.accountApiKeys, params, query),
  });

export const useCreateApiKeyMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountApiKeys, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.post(endpoints.accountApiKeys, params),
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(endpoints.accountApiKeys, {
        ...toPathParams(params),
        body,
      });
      return data!;
    },
    onSuccess: async ({ cluster_id_list }) => {
      const clusterId: string | undefined = cluster_id_list?.[0] ? (cluster_id_list?.[0] as string) : undefined;
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountApiKeys, params) }),
        ...(clusterId
          ? [
              queryClient.invalidateQueries({
                queryKey: queryKey.get(endpoints.accountApiKeys, params, { cluster_id: clusterId }),
              }),
            ]
          : []),
        router.invalidate(),
      ]);
    },
  });
};

export const useDeleteApiKeyByIdMutation = <
  TOptions extends MutationOptions<typeof endpoints.accountApiKey, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();

  return useMutation({
    // Not needed for now: https://github.com/TanStack/query/discussions/6093#discussioncomment-7240423
    // mutationKey: queryKey.delete(endpoints.accountApiKey, params),
    // cluster_id_list is optional, but we need to include it in the params to make it possible to invalidate the cluster-specific query
    mutationFn: async ({ api_key_id }: Pick<TParams, 'api_key_id'> & { cluster_id_list?: string[] }) => {
      const { data } = await client.DELETE(endpoints.accountApiKey, toPathParams({ ...params, api_key_id }));
      return data!;
    },
    onSuccess: async (_, { cluster_id_list }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(endpoints.accountApiKeys, params) }),
        // Invalidate the cluster-specific query if the API key was associated with one or more clusters
        ...(cluster_id_list
          ? cluster_id_list.map((cluster_id) =>
              queryClient.invalidateQueries({
                queryKey: queryKey.get(endpoints.accountApiKeys, params, { cluster_id }),
              }),
            )
          : []),
      ]);
    },
  });
};
