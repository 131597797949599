import { DefaultError } from '@tanstack/react-query';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
// eslint-disable-next-line no-restricted-imports
import { QdrantErrorCode } from '@/api-schema';
import { ComponentSchema } from './services/utils';

// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
export class ApiClientError<T = Object> extends Error {
  static displayName = 'ApiClientError';
  override readonly name = 'ApiClientError';

  constructor(
    public error: T,
    readonly originalStatus: StatusCodes,
  ) {
    super();
  }

  override get message() {
    let message = `(HTTP ${this.originalStatus})`;
    if (isJsonApiResponseError(this.error)) {
      message += `: ${this.error.detail}`;
    }
    return message;
  }

  /**
   * Returns `true` if the error is a 404 or 403 so that the router internally can check for `obj.isNotFound`
   * when the matching runs into {@link https://tanstack.com/router/v1/docs/framework/react/api/router/isNotFoundFunction|isNotFound()}
   * to decide if the nearest ancestor `notFoundComponent` should be rendered.
   *
   * This works during the route loading lifecycle, including useSuspenseQuery throwing (if no data is available).
   * If special handling of 403 or 404 is required, it is advised to do so within the route loading lifecycle.
   */
  get isNotFound() {
    return this.originalStatus === StatusCodes.NOT_FOUND || this.originalStatus === StatusCodes.FORBIDDEN;
  }

  override toString() {
    return getReasonPhrase(this.originalStatus);
  }
}

export function isApiClientError(error: unknown): error is ApiClientError {
  return error instanceof ApiClientError;
}

export function isJsonApiClientError(error: unknown): error is ApiClientError<{ detail: unknown }> {
  return isApiClientError(error) && isJsonApiResponseError(error.error);
}

function isJsonApiResponseError(error: unknown): error is { detail: string } {
  return typeof error === 'object' && error != null && 'detail' in error && typeof error.detail === 'string';
}

export function isApiValidationError<TDetail extends readonly ComponentSchema<'ValidationError'>[]>(
  error: unknown,
): error is ApiClientError<{ detail: TDetail }> {
  if (isJsonApiClientError(error)) {
    const { error: e } = error;
    if (Array.isArray(e.detail)) {
      const val = e.detail as TDetail[number][];
      return val.every((v) => 'type' in v && 'msg' in v && 'loc' in v);
    }
  }
  return false;
}

export function isApiCodeError(
  error: unknown,
): error is ApiClientError<{ detail: ComponentSchema<'QdrantErrorCode'> }> {
  if (isJsonApiClientError(error)) {
    const { error: e } = error;
    return typeof e.detail === 'string' && /^E(\d{4})$/.test(e.detail);
  }
  return false;
}

/**
 * @deprecated Should not be used in new code. Instead use `isApiCodeError`.
 */
export function isErrorWithDataDetail(error: unknown): error is {
  data: { detail: ComponentSchema<'QdrantErrorCode'> };
} {
  return Boolean(
    typeof error === 'object' &&
      error &&
      'data' in error &&
      typeof error.data === 'object' &&
      error.data &&
      'detail' in error.data,
  );
}

/* 404 Not Found */
export function isNotFoundError(error: unknown): error is ApiClientError {
  return isApiClientError(error) && error.originalStatus === StatusCodes.NOT_FOUND;
}

/* 403 Forbidden */
export function isForbiddenError(error: unknown): error is ApiClientError {
  return isApiClientError(error) && error.originalStatus === StatusCodes.FORBIDDEN;
}

/* 403 Forbidden - due to insuffient permissions */
export function isPermissionError(error: DefaultError): error is ApiClientError {
  return isApiCodeError(QdrantErrorCode.PERMISSION_DENIED);
}

/* 401 Unauthorized */
export function isUnauthorizedError(error: DefaultError): error is ApiClientError {
  return isApiClientError(error) && error.originalStatus === StatusCodes.UNAUTHORIZED;
}

/**
 * The client operation timed out.
 * Use case: {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout_static}.
 */
export function isSignalTimeoutError(error: DefaultError): error is DOMException {
  return error instanceof DOMException && error.name === 'TimeoutError';
}

export { QdrantErrorCode };
