import { Box, Button, Typography, styled } from '@mui/material';
import { ErrorComponentProps, useRouter } from '@tanstack/react-router';
import { isModuleNotFoundError } from '@/utils/error-utils';
import { AccountDashboardMain } from './AccountDashboardMain';
import { DASHBOARD_LAYOUT_GAP } from '.';

const StyledAccountDashboardErrorComponent = styled('div')(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing(1)};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - ${DASHBOARD_LAYOUT_GAP});
`,
);

export const AccountDashboardErrorComponent = ({ error, reset }: ErrorComponentProps) => {
  const router = useRouter();

  return (
    <AccountDashboardMain>
      <StyledAccountDashboardErrorComponent role="alert">
        <Typography variant="h5" component="p">
          An error has occurred. Our team is aware of the issue and is working to resolve it.
        </Typography>
        <Typography variant="h5" component="p">
          Please try again later.
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (isModuleNotFoundError(error)) {
                return window.location.reload();
              }

              reset();

              return router.invalidate();
            }}
          >
            Try again
          </Button>
        </Box>
      </StyledAccountDashboardErrorComponent>
    </AccountDashboardMain>
  );
};
