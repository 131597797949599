import { Box, Typography } from '@mui/material';
import { getRouteApi, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuth } from '@/router/hooks';
import { BASE_URL } from '@/utils/constants';

const { useSearch } = getRouteApi('/logout');

export function LogoutComponent() {
  const auth = useAuth();
  const { aerr, sso_connection, sso_login_hint } = useSearch();
  const router = useRouter();

  // The hard-redirect happens inside `useEffect` to avoid blocking the render.
  useEffect(() => {
    const loginUrl = router.buildLocation({ to: '/login', search: { aerr, sso_connection, sso_login_hint } }).href;
    /**
     * Rotating tokens aren't invalidated by the SDK, and there is currently no way to do
     * it manually because they don't expose the `refresh_token` to the outside.
     * @see https://community.auth0.com/t/revoke-refresh-token-on-logout/80698
     */
    void auth.logout({ logoutParams: { returnTo: new URL(loginUrl, BASE_URL).toString() } });
  }, [auth, router, aerr, sso_connection, sso_login_hint]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="20vh">
      <Typography>Signing out ...</Typography>
    </Box>
  );
}
