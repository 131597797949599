import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { accountEndpoints, userEndpoints } from '@/api/services/accounts-endpoints';
import { useRootContext } from '@/router/hooks';
import { client } from '../query-client';
import { FromPathParams, MutationOptions, QueryData, queryKey, QueryOptions, toPathParams } from './utils';

export type RolesDto = QueryData<typeof accountEndpoints.roles>;
export type RoleDto = RolesDto[number];

export const getAccountRolesQuery = (params: FromPathParams<QueryOptions<typeof accountEndpoints.roles>>) =>
  queryOptions<RolesDto>({
    queryKey: queryKey.get(accountEndpoints.roles, params),
  });

export type AccountPermissionsData = QueryData<typeof accountEndpoints.permissions>;

export const getAccountPermissionsQuery = (params: FromPathParams<QueryOptions<typeof accountEndpoints.permissions>>) =>
  queryOptions<AccountPermissionsData>({
    queryKey: queryKey.get(accountEndpoints.permissions, params),
  });

export const usePostAccountRolesMutation = <
  TOptions extends MutationOptions<typeof accountEndpoints.roles>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(accountEndpoints.roles, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(accountEndpoints.roles, params) }),
        queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }),
        router.invalidate(),
      ]),
  });
};

export const useDeleteAccountRoleMutation = <
  TOptions extends MutationOptions<typeof accountEndpoints.detail, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async () => {
      const { data } = await client.DELETE(accountEndpoints.detail, toPathParams(params));
      return data!;
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: [accountEndpoints.roles] }),
        queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }),
        router.invalidate(),
      ]),
  });
};

export const usePatchAccountRoleMutation = <
  TOptions extends MutationOptions<typeof accountEndpoints.detail, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PATCH(accountEndpoints.detail, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: [accountEndpoints.roles] }),
        queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }),
        router.invalidate(),
      ]),
  });
};
