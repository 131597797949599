import { Button, Link } from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { memo } from 'react';
import { useSupportData } from '@/contexts/support-context';
import { useTrackInteractionEvent } from '@/hooks/use-event-tracking';
import { useAccountId } from '@/router/hooks';

export const buildSupportLink = (body?: { accountId?: string; clusterId?: string; clusterVersion?: string }) => {
  const searchParams = new URLSearchParams();
  if (body) {
    if (body.accountId) {
      searchParams.set('account_id', body.accountId);
    }
    if (body.clusterId) {
      searchParams.set('cluster_id', body.clusterId);
      // Only provide cluster version within the clusterId context
      if (body.clusterVersion) {
        searchParams.set('cluster_version', body.clusterVersion);
      }
    }
  }
  return decodeURIComponent(searchParams.toString());
};

export const GetSupportLink = memo(function GetSupportLink() {
  const {
    supportData: { clusterVersion },
  } = useSupportData();
  const url = new URL('https://support.qdrant.io/support/tickets/new');
  const accountId = useAccountId();
  const { clusterId } = useParams({ strict: false });
  url.search = buildSupportLink({ accountId, clusterId, clusterVersion });

  const trackClick = useTrackInteractionEvent({
    label: 'Get Support',
    location: 'Sidebar',
    action: 'clicked',
  });

  return (
    <Link href={url.toString()} rel="noreferrer" target="_blank" underline="none" onClick={() => trackClick()}>
      <Button color="primary" fullWidth={true} variant="outlined">
        Get Support
      </Button>
    </Link>
  );
});
