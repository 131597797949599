import { createRoute, notFound, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { isForbiddenError, isNotFoundError } from '@/api/errors';
import { getHybridCloudEnvQuery } from '@/api/services/hybrid-cloud';
import { head } from '@/router/Head';
import { parsePathParams, isPathParamError } from '@/router/utils';
import { Route as HybridCloudRoute } from '../hybrid-cloud_';

export const Route = createRoute({
  head,
  staticData: {
    title: 'Hybrid Cloud Environment',
  },
  getParentRoute: () => HybridCloudRoute,
  path: '$hybridCloudEnvId',
  params: parsePathParams(
    z.object({
      hybridCloudEnvId: z.string().uuid(),
    }),
  ),
  context: ({ params: { accountId, hybridCloudEnvId } }) => ({
    hybridCloudEnvQuery: getHybridCloudEnvQuery({
      account_id: accountId,
      hybrid_cloud_env_id: hybridCloudEnvId,
    }),
  }),
  beforeLoad: async ({ context: { queryClient, hybridCloudEnvQuery }, params: { accountId } }) => {
    try {
      await queryClient.fetchQuery(hybridCloudEnvQuery);
    } catch (error) {
      if (isForbiddenError(error) || isNotFoundError(error)) {
        throw redirect({
          to: '/accounts/$accountId/hybrid-cloud',
          params: { accountId },
          replace: true,
        });
      }
      throw error;
    }
  },
  onError(err) {
    if (isPathParamError(err)) {
      throw notFound();
    }
  },
}).lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-env-id" */ './$hybridCloudEnvId.lazy').then(({ Route }) => Route),
);
