import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/ModeNight';
import ColorSchemeIcon from '@mui/icons-material/PersonalVideo';
import {
  Chip,
  ChipProps,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useCallback } from 'react';
import { hasDefaultColorScheme, useColorScheme } from '@/components/Profile/hooks/useColorScheme';
import { useCookieHub } from '@/contexts/cookie-hub-context';
import { LogoutOutlined } from '@/icons/logout-outlined';
import { MenuItemLink } from '@/router/Link';
import { useAccountId, useAuth } from '@/router/hooks';
import { getAuthenticationType, AuthenticationType, getSubjectClaim } from '@/utils/auth-utils';

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  height: 'auto',
  minHeight: theme.spacing(6),
  gap: theme.spacing(1),
  '&.Mui-disabled': {
    opacity: 1,
  },
  '@media (min-width:600px)': {
    minHeight: theme.spacing(6),
  },
})) as typeof MenuItemLink;

const StyledListItemText = (props: ListItemTextProps<'span', 'span'>) => (
  <ListItemText
    primaryTypographyProps={{ noWrap: true, component: 'span' }}
    secondaryTypographyProps={{ noWrap: true, component: 'span' }}
    {...props}
  />
);

const StyledMenuItemText = styled(({ children, ...props }: MenuItemProps) => (
  <MenuItem component="div" {...props}>
    <ListItemText primaryTypographyProps={{ variant: 'body2', sx: { color: (theme) => theme.palette.text.secondary } }}>
      {children}
    </ListItemText>
  </MenuItem>
))({
  paddingInline: 0,
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

const StyledMenuItemButtonGroup = styled(({ children, ...props }: MenuItemProps) => (
  <MenuItem component="div" {...props}>
    {children}
  </MenuItem>
))(({ theme }) => ({
  color: theme.palette.text.disabled,
  paddingInline: 0,
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

const StyledAuthProviderChip = styled(
  ({ type, children, ...props }: Omit<ChipProps, 'color' | 'outlined' | 'small'> & { type: AuthenticationType }) => (
    <Chip variant="outlined" size="small" {...props} />
  ),
)(({ type, theme }) => {
  const styles = `
    color: ${theme.palette.text.secondary};
    font-size: 10px;
    height: 20px;
    line-height: 20px;
    font-weight: ${theme.typography.body2.fontWeight};
    font-style: italic;

    .MuiChip-label {
      padding-inline: ${theme.spacing(1)};
    }
  `;
  switch (type) {
    case 'google-oauth2':
      return `
        border: solid 1px transparent !important;
        background: linear-gradient(${theme.palette.background.paper} 0 0) padding-box,
          linear-gradient(90deg, #EB4335 0%, #FBBC05 50%, #34A853 75%, #4285F4 100%) border-box;
        ${styles}
      `;
    case 'github':
      return `border: solid 1px #24292F !important; ${styles}`;
    default:
      return `border: solid 1px ${theme.palette.primary.main} !important; ${styles}`;
  }
});

export const UserMenuItems = ({ onClose }: { onClose: () => void }) => {
  const { user } = useAuth();
  const accountId = useAccountId();
  const cookieHub = useCookieHub();
  const { colorScheme, setColorScheme } = useColorScheme();
  const handleColorSchemeChange = useCallback(
    (_: unknown, [value]: ['dark' | 'light' | '']) => {
      setColorScheme(value || undefined);
    },
    [setColorScheme],
  );
  const handleCookieConsentClick = useCallback(() => {
    cookieHub.openDialog();
    onClose();
  }, [cookieHub, onClose]);

  const subjectClaimValue = user.sub ?? '';
  const authenticationType = getAuthenticationType(subjectClaimValue);
  const subjectClaim = getSubjectClaim(subjectClaimValue);
  const defaultColorScheme = hasDefaultColorScheme();

  return (
    <>
      <StyledMenuItemLink onClick={onClose} to="/accounts/$accountId/profile" params={{ accountId }}>
        <StyledListItemText data-sentry-mask={true} primary={user.name ?? '-'} secondary={user.email ?? '-'} />
        {subjectClaim && authenticationType ? (
          <StyledAuthProviderChip type={subjectClaim} label={authenticationType} />
        ) : null}
      </StyledMenuItemLink>
      <Divider />
      <StyledMenuItemButtonGroup>
        <ToggleButtonGroup
          size="small"
          fullWidth={true}
          aria-label="Color scheme preference"
          color="standard"
          onChange={handleColorSchemeChange}
        >
          <Tooltip title="System default" placement="top" arrow={true}>
            <ToggleButton value="" selected={defaultColorScheme}>
              <ColorSchemeIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Dark" placement="top" arrow={true}>
            <ToggleButton value="dark" selected={!defaultColorScheme && colorScheme === 'dark'}>
              <DarkModeIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Light" placement="top" arrow={true}>
            <ToggleButton value="light" selected={!defaultColorScheme && colorScheme === 'light'}>
              <LightModeIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </StyledMenuItemButtonGroup>
      <Divider />
      <StyledMenuItemLink onClick={onClose} to="/logout">
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </StyledMenuItemLink>
      {cookieHub.isLoaded && (
        <>
          <Divider />
          <StyledMenuItemText onClick={handleCookieConsentClick}>Cookie Consent</StyledMenuItemText>
        </>
      )}
    </>
  );
};
