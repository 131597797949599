import {
  UpdateQueryStringOptions,
  useUpdateQueryStringValueWithoutNavigation,
} from '@/hooks/use-update-query-string-value';
import { ClusterCreateSearchParams, ClusterSetupSearchParams } from '@/router/utils';

type SearchParams = Required<ClusterCreateSearchParams & ClusterSetupSearchParams>;

export function useQueryStringUpdaterForClusterForm<T extends keyof SearchParams>(
  queryKey: T,
  queryValue: SearchParams[T] | null,
  options?: UpdateQueryStringOptions,
) {
  useUpdateQueryStringValueWithoutNavigation(
    queryKey,
    typeof queryValue === 'number' ? String(queryValue) : queryValue,
    options,
  );
}
