import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ListItem, ListItemText, Menu, MenuProps, styled, SxProps, Theme, Fade } from '@mui/material';
import { memo, MouseEvent, useCallback, useState } from 'react';
import { useAccountUser } from '@/router/hooks';
import { AccountMenuItems } from './AccountMenuItems';

const sx: SxProps<Theme> = (theme) => ({
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  gap: theme.spacing(0.5),
  color: theme.palette.text.primary,
  marginBottom: '1px',
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    slotProps={{ paper: { sx: { minWidth: 340, maxWidth: 420, maxHeight: 550 } } }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: 6,
    borderRadius: 4,
    borderWidth: 0,
    boxShadow: '0 6px 38px -18px rgb(0 0 0 / 6%), 0 7px 13px 0 rgb(0 0 0 / 4%)',
  },
  '& .MuiMenu-list': {
    padding: theme.spacing(2, 3),
  },
}));

export const AccountMenu = memo(function AccountMenu() {
  const { account } = useAccountUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ArrowIcon = open ? ArrowDropUpIcon : ArrowDropDownIcon;
  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <ListItem
        role="button"
        tabIndex={0}
        id="account-menu-toggle"
        aria-controls={open ? 'account-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        component="div"
        sx={sx}
        onClick={handleClick}
      >
        <ListItemText data-sentry-mask={true}>{account.name || '-'}</ListItemText>
        <ArrowIcon color="action" fontSize="small" />
      </ListItem>
      <StyledMenu
        id="account-menu"
        MenuListProps={{
          'aria-labelledby': 'account-menu-toggle',
          component: 'div',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <AccountMenuItems onClose={handleClose} />
      </StyledMenu>
    </div>
  );
});
