import { styled } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { IncidentBanner } from '../Common/IncidentBanner';
import { AccountDashboardNavbar, AccountDashboardAppBar } from './AccountDashboardBar';
import { AccountDashboardSidebar, accountDashboardSidebarWidth } from './AccountDashboardSidebar';

export const DASHBOARD_LAYOUT_GAP = '52px';

const AccountDashboardContent = styled('div')`
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  margin-top: ${DASHBOARD_LAYOUT_GAP};
`;

const AccountDashboardBody = styled('div')`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

const sx = {
  ml: { lg: accountDashboardSidebarWidth },
};

/**
 * Each authenticated dashboard page should be wrapped in this component.
 * It provides the layout and the sidebar. An error boundary is also provided for the children.
 */
export const AccountDashboard = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const onOpenSidebar = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);
  const onCloseSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  return (
    <>
      <AccountDashboardContent sx={sx}>
        <AccountDashboardBody>{children}</AccountDashboardBody>
      </AccountDashboardContent>
      <AccountDashboardNavbar onOpenSidebar={onOpenSidebar} />
      <AccountDashboardSidebar onClose={onCloseSidebar} open={isSidebarOpen} />
    </>
  );
};

export const AccountDashboardOutline = ({ children }: { children?: ReactNode }) => (
  <AccountDashboardContent>
    <AccountDashboardBody>
      <IncidentBanner />
      {children}
    </AccountDashboardBody>
    <AccountDashboardAppBar />
  </AccountDashboardContent>
);
