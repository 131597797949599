import { createRoute } from '@tanstack/react-router';
import { getInvoicesQuery, getPaymentInformationQuery, getPaymentMeteringsQuery } from '@/api/services/payment';
import { head } from '@/router/Head';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as AccountDashboardRoute } from '.';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    getParentRoute: () => AccountDashboardRoute,
    path: 'billing',
    staticData: {
      title: 'Billing',
      permissions: ['read:payment_information'],
    },
    beforeLoad: ({ params: { accountId } }) => ({
      paymentInformationQueryOptions: getPaymentInformationQuery({ account_id: accountId }),
      invoicesQueryOptions: getInvoicesQuery({ account_id: accountId }),
      paymentMeteringsQueryOptions: getPaymentMeteringsQuery({ account_id: accountId }),
    }),
    loader: ({ context: { queryClient, paymentInformationQueryOptions, paymentMeteringsQueryOptions } }) => {
      void queryClient.prefetchQuery(paymentInformationQueryOptions);
      void queryClient.prefetchQuery(paymentMeteringsQueryOptions);
    },
  }),
).lazy(() => import(/* webpackChunkName: "billing" */ './billing.lazy').then(({ Route }) => Route));
