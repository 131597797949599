import { useCallback } from 'react';
import { Settings } from '@/contexts/settings-context';
import { useSettings } from '@/hooks/use-settings';

export const useColorScheme = () => {
  const { settings, saveSettings } = useSettings();

  return {
    setColorScheme: useCallback(
      (colorScheme: 'dark' | 'light' | undefined) => {
        saveSettings({ theme: colorScheme });
      },
      [saveSettings],
    ),
    colorScheme: settings.theme,
  };
};

export const hasDefaultColorScheme = () => {
  try {
    const storedData = window.localStorage.getItem('settings');
    if (storedData) {
      return (JSON.parse(storedData) as Settings).theme === undefined;
    }
  } catch {
    // no-op
  }
  return false;
};
