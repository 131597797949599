import { z } from 'zod';
import { Toleration } from '@/api/services/cluster';

export const MAX_KEY_VALUE_PAIRS = 64;
export const MAX_KEY_LENGTH = 63 + 253; // = 316 ->  253 (optional prefix) + 63 (mandatory name);
export const MAX_VALUE_LENGTH = 65536;
export const keyValuePairValidator = z
  .array(
    z.object({
      // See https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations/#syntax-and-character-set
      // TODO validate key format based on the above link. REF issue: https://github.com/qdrant/cloud-pm/issues/953
      key: z.string().min(1, 'Key is required').max(MAX_KEY_LENGTH, `Key must be at most ${MAX_KEY_LENGTH} characters`),
      value: z
        .string()
        .min(1, 'Value is required')
        .max(MAX_VALUE_LENGTH, `Value must be at most ${MAX_VALUE_LENGTH} characters`),
    }),
  )
  .max(MAX_KEY_VALUE_PAIRS, `Maximum number of key/value pairs is ${MAX_KEY_VALUE_PAIRS}`)
  .superRefine((items, ctx) => {
    const uniqueValues = new Map<string, number>();
    for (const [idx, { key }] of items.entries()) {
      const firstAppearanceIndex = uniqueValues.get(key);
      if (firstAppearanceIndex !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Key must be unique`,
          path: [idx, 'key'],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Key must be unique`,
          path: [firstAppearanceIndex, 'key'],
        });
      } else {
        uniqueValues.set(key, idx);
      }
    }
  })
  .optional();

export const tolerationsValidator = z
  .array(
    z.object({
      operator: z.enum(['Equal', 'Exists'], { message: 'Invalid operator', required_error: 'Select an operator' }),
      key: z.string().nullable().optional(),
      effect: z
        .enum(['NoSchedule', 'PreferNoSchedule', 'NoExecute'], { message: 'Invalid effect' })
        .nullable()
        .optional(),
      value: z.string().nullable().optional(),
      tolerationSeconds: z
        .string()
        .optional()
        .nullable()
        .refine((value) => !value || /^[0-9]+$/.test(value), 'Input a number'),
    }),
  )
  .superRefine((items, ctx) => {
    for (const [idx, { operator, value }] of items.entries()) {
      if (operator === 'Equal' && !value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Value is required',
          path: [idx, 'value'],
        });
      } else if (operator === 'Exists' && value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Value must be empty',
          path: [idx, 'value'],
        });
      }
    }
  });

export type TolerationFormField = z.infer<typeof tolerationsValidator>;
export const getKeyValuePairsArray = (data?: Record<string, string> | null) =>
  data ? Object.entries(data).map(([key, value]) => ({ key, value })) : [];

export const mapValueKeyPairArrayToObj = (
  data?: { key?: string; value?: string }[],
  isValid?: boolean,
): Record<string, string> | undefined => {
  /*
      Map should return an empty object (`{}`) if the form is invalid or there is no key value.
      If set to undefined, the field will not be sent as part of the request when clearing the value,
      which is not what we want. And, for the invalid form case, submission should be prevented by
      the validation anyways.
    */
  if (!isValid || !data?.length) {
    return {};
  }

  return data.reduce<Record<string, string>>((acc, { key, value }) => {
    if (key && value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const mapTolerationsToArrayFields = (tolerations?: Toleration[] | null) =>
  tolerations?.map((toleration) => {
    const { toleration_seconds: tolerationSeconds, ...tolerationFields } = toleration;
    return {
      ...tolerationFields,
      tolerationSeconds: tolerationSeconds ? String(tolerationSeconds) : undefined,
    };
  }) ?? [];

export const mapArrayFieldTolerationsToObj = (tolerations?: TolerationFormField, isValid?: boolean) => {
  if (!isValid || !tolerations?.length) {
    return [];
  }

  return tolerations.map((toleration: TolerationFormField[number]) => ({
    ...(toleration.key ? { key: toleration.key } : {}),
    ...(toleration.value ? { value: toleration.value } : {}),
    operator: toleration.operator,
    ...(toleration.effect ? { effect: toleration.effect } : {}),
    ...(toleration.tolerationSeconds ? { toleration_seconds: parseInt(String(toleration.tolerationSeconds)) } : {}),
  }));
};
