// eslint-disable-next-line no-restricted-imports
import { useAuth0 } from '@auth0/auth0-react';
import { AnyRouter, RouterProvider } from '@tanstack/react-router';
import { memo } from 'react';
import { queryClient, setRefreshTokenQueryDefaults, setDefaultRouter } from '@/api/query-client';
import { LoadingIndicator } from '../Common/LoadingIndicator';

export const Router = memo(function Router({ router }: { router: AnyRouter }) {
  const { isLoading, user, ...auth } = useAuth0();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  setDefaultRouter(router);
  setRefreshTokenQueryDefaults(auth);

  return <RouterProvider router={router} context={{ queryClient, auth: { user: user!, ...auth } }} />;
});
