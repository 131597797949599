import { Direction } from '@mui/material/styles';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { merge } from 'ts-deepmerge';
import { captureException } from '../utils/error-utils';

export type CreateThemeSettings = {
  theme: 'light' | 'dark' | undefined;
  direction: Direction;
  responsiveFontSizes: boolean;
};

type AppSettings = {
  dashboardPromps: { newsletter: boolean };
  isWelcomeStepperInProgress?: boolean;
};

export type Settings = CreateThemeSettings & AppSettings;

export const initialSettings: Settings = {
  direction: 'ltr',
  theme: 'light',
  responsiveFontSizes: true,
  dashboardPromps: {
    newsletter: true,
  },
};

const prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');

export const restoreSettings = (): Settings => {
  let settings: Settings = {
    ...initialSettings,
    theme: prefersColorScheme.matches ? 'dark' : 'light',
  };

  try {
    const storedData = window.localStorage.getItem('settings');
    if (storedData) {
      const { dashboardPromps, ...otherSettings } = JSON.parse(storedData) as Settings;
      settings = {
        ...settings,
        dashboardPromps: {
          ...settings.dashboardPromps,
          ...dashboardPromps,
        },
        ...otherSettings,
      };
    }
  } catch (err) {
    captureException(err, { level: 'info', tags: { context: 'settings', action: 'restore-settings' } });
  }

  return settings;
};

/**
 * Do not use directly in components, use `const {saveSettings} = useSettings()` instead.
 */
export const storeSettings = (settings: Partial<Settings>) => {
  try {
    window.localStorage.setItem('settings', JSON.stringify(settings));
  } catch (err) {
    captureException(err, { level: 'info', tags: { context: 'settings', action: 'store-settings' } });
  }
};

export const SettingsContext = createContext<{ settings: Settings; saveSettings: (value: Partial<Settings>) => void }>({
  settings: initialSettings,
  saveSettings: () => undefined,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState(restoreSettings());
  const saveSettings = useCallback(
    (updatedSettings: Partial<Settings>) => {
      setSettings((state) => {
        storeSettings(merge({}, state, updatedSettings) as Settings);
        return restoreSettings();
      });
    },
    [setSettings],
  );
  const value = useMemo(
    () => ({
      settings,
      saveSettings,
    }),
    [saveSettings, settings],
  );

  useEffect(() => {
    const handleChange = () => setSettings(restoreSettings());
    prefersColorScheme.addEventListener('change', handleChange);
    return () => prefersColorScheme.removeEventListener('change', handleChange);
  }, []);

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export const SettingsConsumer = SettingsContext.Consumer;
