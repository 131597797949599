import { z } from 'zod';
import { rootSearchSchema, utmParamsSchema } from '../router/utils';
import { Cookies } from './cookies';

export type UtmParamsSchema = z.infer<typeof utmParamsSchema>;

const UTM_PARAMS_COOKIE_NAME = 'utm_params';

/**
 * Extracts UTM params from a search object and returns them as a UtmParamsSchema object.
 */
export function getUtmParamsFromSearch(search: z.infer<typeof rootSearchSchema>) {
  const names = Object.keys(utmParamsSchema.shape) as (keyof typeof utmParamsSchema.shape)[];
  const matches = names.filter((key) => search[key] !== undefined);

  if (matches.length === 0) {
    return;
  }

  return matches.reduce<{
    [K in keyof UtmParamsSchema]?: UtmParamsSchema[K];
  }>((acc, key) => {
    if (search[key] !== undefined) {
      return { ...acc, [key]: search[key] };
    }
    return acc;
  }, {});
}

/**
 * Persists UTM params in a cookie lasting for a year.
 * The cookie is scoped to the `/api` path, so that api calls can access the UTM params.
 */
export function persistUtmParamsSession(search: z.infer<typeof utmParamsSchema>): void {
  Cookies.set(UTM_PARAMS_COOKIE_NAME, new URLSearchParams(search).toString(), {
    expires: 365,
  });
}

/**
 * Retrieves UTM params from the cookie in the form of a UtmParamsSchema object.
 */
export function retrieveUtmParamsSession() {
  return Object.fromEntries(new URLSearchParams(Cookies.get(UTM_PARAMS_COOKIE_NAME))) as UtmParamsSchema;
}

export function clearUtmParamsSession(): void {
  Cookies.remove(UTM_PARAMS_COOKIE_NAME);
}
