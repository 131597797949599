import { createRoute, redirect } from '@tanstack/react-router';
import { getDatabaseReleasesQuery } from '@/api/services/database';
import { getPaymentSessionByIdQuery, PaymentSessionByIdDto } from '@/api/services/payment';
import { getUserConsentQuery } from '@/api/services/user';
import { ensureAccountUser } from '@/router/accountUser';
import { accountSearchSchema } from '@/router/utils';
import { captureException } from '@/utils/error-utils';
import { Route as AuthenticatedRoute } from '..';
import { createAccountUser, fetchAccountUser } from '../_account';

export const Route = createRoute({
  getParentRoute: () => AuthenticatedRoute,
  path: 'accounts/$accountId',
  validateSearch: accountSearchSchema,
  /**
   * Load the user information, or create a new user if it doesn't exist.
   * Fetch errors are handled in the parent route's error boundary.
   */
  context: ({ params }) => ({
    databaseReleasesQueryOptions: getDatabaseReleasesQuery({ account_id: params.accountId }),
    userConsentQueryOptions: getUserConsentQuery({ consent_type: 'privacy_policy' }),
  }),
  async beforeLoad({
    context: { auth, queryClient },
    params: { accountId: account_id },
    search: { session_id },
    location: { href },
  }) {
    if (session_id) {
      await ensurePaymentSession(queryClient.fetchQuery(getPaymentSessionByIdQuery({ account_id, session_id })));
    }

    const data = await fetchAccountUser({ queryClient });
    if (!data) {
      await createAccountUser({ auth, queryClient, pathname: href });
      return;
    }
    // Ensure redirection if the account id is not part of the account data.
    await ensureAccountUser({ queryClient, accountId: account_id });
  },
  loader: async ({ context: { queryClient, userConsentQueryOptions } }) => {
    await queryClient.ensureQueryData(userConsentQueryOptions);
  },
}).lazy(() => import(/* webpackChunkName: "account" */ './$accountId.lazy').then(({ Route }) => Route));

/**
 * Stripe redirects to the same page with the session_id in the URL.
 * Anytime we get a successful session with succeeded payment intent we must store the payment method.
 * It removes the session_id from the URL and redirects to the same page.
 * @see https://github.com/qdrant/qdrant-cloud-cluster-api/blob/af86f601965d190a20944286390ff742fd7d2cbf/cluster_api/booking/payment/stripe_/service_fn.py#L173
 */
async function ensurePaymentSession(paymentSession: Promise<PaymentSessionByIdDto>): Promise<never> {
  const data = await paymentSession;
  const isSuccess = isStripeSetupIntentObject(data.setup_intent) && data.setup_intent.status === 'succeeded';

  if (!isSuccess) {
    captureException(new Error(`Stripe setup failed: ${JSON.stringify(data)}`), {
      level: 'fatal',
    });
  }

  throw redirect({ to: '.', search: (prev) => ({ ...prev, session_id: undefined }), hash: true, replace: true });
}

type StripeSetupIntent = PaymentSessionByIdDto['setup_intent'];

/**
 * The field `setup_intent` can be expanded into an object with the `expand` request parameter.
 */
function isStripeSetupIntentObject(setupIntent: StripeSetupIntent): setupIntent is Exclude<StripeSetupIntent, string> {
  return typeof setupIntent !== 'string' && typeof setupIntent.status === 'string';
}
