import { createRoute } from '@tanstack/react-router';
import { getInvitesByAccountId } from '@/api/services/user';
import { ensureAccountUser } from '@/router/accountUser';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { hasPermission } from '@/utils/access-control';
import { Route as CloudAccessRolesRoute } from './roles';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => CloudAccessRolesRoute,
    path: 'all-users',
    staticData: {
      permissions: ['read:users'],
    },
    beforeLoad: async ({ context: { queryClient }, params: { accountId } }) => {
      const userAccount = await ensureAccountUser({ queryClient, accountId });

      const hasInvitesReadPermission = hasPermission({ permissions: ['read:invites'], userAccount });
      if (hasInvitesReadPermission) {
        return { invitesQueryOption: getInvitesByAccountId({ account_id: accountId }) };
      }
      return { invitesQueryOption: null };
    },
    loader: async ({ context: { queryClient, invitesQueryOption } }) => {
      if (invitesQueryOption) {
        await queryClient.fetchQuery(invitesQueryOption);
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "all-users" */ './roles.all-users.lazy').then(({ Route }) => Route));
