import { queryOptions } from '@tanstack/react-query';
import { QueryData, queryKey } from './utils';

export const endpoints = {
  settings: '/settings.json',
  featureFlags: '/config/feature_flags',
} as const;

export type SettingsDto = QueryData<typeof endpoints.settings>;
export type FeatureFlagsDto = QueryData<typeof endpoints.featureFlags>;

export const settingsQueryOptions = queryOptions<SettingsDto>({
  queryKey: queryKey.get(endpoints.settings),
  meta: {
    isPublic: true,
    baseUrl: '/',
  },
  refetchInterval: 10_000,
  notifyOnChangeProps: ['data'],
  placeholderData: { incident: false, tag: '' },
});

export const featureFlagsQueryOptions = queryOptions<FeatureFlagsDto>({
  queryKey: queryKey.get(endpoints.featureFlags),
  meta: {
    isPublic: true,
  },
  staleTime: 30_000,
});
