import { createRoute } from '@tanstack/react-router';
import { getClustersByAccountIdQuery } from '@/api/services/cluster';
import { head } from '@/router/Head';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as AccountDashboardRoute } from '.';

export const Route = withRouteAccessControl(
  createRoute({
    head,
    staticData: {
      title: 'Backups',
      permissions: ['read:backups', 'read:clusters'],
    },
    getParentRoute: () => AccountDashboardRoute,
    path: 'backups',
    context: ({ params: { accountId } }) => ({
      clusterQueryOptions: getClustersByAccountIdQuery({ account_id: accountId }),
    }),
  }),
).lazy(() => import(/* webpackChunkName: "backups" */ './backups.lazy').then(({ Route }) => Route));
