import { createRoute } from '@tanstack/react-router';
import { getProgrammaticAccessKeys } from '@/api/services/programmatic-keys';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as CloudAccessRoute } from '../cloud-access_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => CloudAccessRoute,
    path: 'cloud-management-keys',
    staticData: {
      privileges: ['PROGRAMMATIC_ACCESS'],
      permissions: ['read:management_keys'],
    },
    context: ({ params: { accountId } }) => ({
      programmaticKeysQueryOptions: getProgrammaticAccessKeys({ account_id: accountId }),
    }),
    loader: async ({ context: { queryClient, programmaticKeysQueryOptions } }) => {
      await queryClient.prefetchQuery(programmaticKeysQueryOptions);
    },
  }),
).lazy(() =>
  import(/* webpackChunkName: "programmatic-access" */ './programmatic-access.lazy').then(({ Route }) => Route),
);
