import { createRoute, notFound, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { isForbiddenError, isNotFoundError } from '@/api/errors';
import { userEndpoints } from '@/api/services/accounts-endpoints';
import {
  endpoints,
  getClusterByIdQuery,
  getClusterMetricsByClusterIdQuery,
  getClustersByAccountIdQuery,
} from '@/api/services/cluster';
import { getDatabaseUpdateCandidatesQuery } from '@/api/services/database';
import { FromQueryParams, QueryOptions } from '@/api/services/utils';
import { ensureAccountUser } from '@/router/accountUser';
import { parsePathParams, isPathParamError } from '@/router/utils';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { hasPermission } from '@/utils/access-control';
import { Route as ClustersRoute } from '../clusters_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClustersRoute,
    path: '$clusterId',
    staticData: {
      permissions: ['read:clusters'],
    },
    params: parsePathParams(
      z.object({
        clusterId: z.string().uuid(),
      }),
    ),
    validateSearch: z.object({
      createApiKey: z.coerce.boolean().nullish(),
    }),
    context: ({ params: { accountId, clusterId } }) => ({
      clusterQueryOptions: getClusterByIdQuery({ account_id: accountId, cluster_id: clusterId }),
      databaseUpdateCandidatesQueryOptions: getDatabaseUpdateCandidatesQuery({
        account_id: accountId,
        cluster_id: clusterId,
      }),
      clusterQueryByAccountIdOptions: getClustersByAccountIdQuery({
        account_id: accountId,
      }),
      clusterMetricsQueryFunction: (query?: FromQueryParams<QueryOptions<typeof endpoints.clusterMetrics>>) =>
        getClusterMetricsByClusterIdQuery(
          {
            account_id: accountId,
            cluster_id: clusterId,
          },
          {
            ...query,
          },
        ),
    }),
    beforeLoad: async ({
      context: { queryClient, clusterQueryOptions },
      matches,
      params: { accountId, clusterId },
      search,
    }) => {
      try {
        await queryClient.fetchQuery(clusterQueryOptions);
      } catch (error) {
        if (isForbiddenError(error) || isNotFoundError(error)) {
          throw redirect({
            to: '/accounts/$accountId/clusters',
            params: { accountId },
            replace: true,
          });
        }
        throw error;
      }

      const { createApiKey } = search;
      const isApiKeyPath = matches.at(-1)?.pathname?.endsWith('/api-keys');
      if (createApiKey) {
        // When "auto" creating an API key, we want to make sure we check against updated permissions.
        await queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] });
        if (!isApiKeyPath) {
          const userAccount = await ensureAccountUser({ queryClient, accountId });
          const hasApiKeyRWPermission = hasPermission({
            permissions: ['read:api_keys', 'write:api_keys'],
            userAccount,
          });
          if (hasApiKeyRWPermission) {
            throw redirect({
              to: '/accounts/$accountId/clusters/$clusterId/api-keys',
              params: { accountId, clusterId },
              search: { createApiKey },
              replace: true,
            });
          }
        }
      }
    },
    loader: async ({ context: { queryClient, databaseUpdateCandidatesQueryOptions } }) => {
      await queryClient.prefetchQuery(databaseUpdateCandidatesQueryOptions);
    },
    onError(err) {
      if (isPathParamError(err)) {
        throw notFound();
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-id" */ './$clusterId.lazy').then(({ Route }) => Route));
