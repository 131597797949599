import { ReactNode } from 'react';

/**
 * Auth and other parts of the app require cookies to be enabled.
 * Additionally (web storage) session/localStorage is used by Auth0's SDK.
 * Without both of these enabled, the app will not work.
 */
export function EnsureCookieSupport({ children }: { children: ReactNode }) {
  if (!navigator.cookieEnabled) {
    // The browser does not support or is blocking cookies from being set.
    throw new Error('Cookies are required to use this site. Please enable cookies in your browser settings.');
  }
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
  } catch {
    // The browser does not support or is blocking localStorage.
    throw new Error('DOM storage is required to use this site. Please enable DOM storage in your browser settings.');
  }

  return children;
}
