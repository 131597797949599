import { createRoute, Outlet, useChildMatches } from '@tanstack/react-router';
import { getAccountJwts } from '@/api/services/account';
import { getAccountApiKeysQuery } from '@/api/services/authentication';
import { getPaymentMethodsQuery } from '@/api/services/payment';
import { AccountDashboard, AccountDashboardOutline } from '@/components/AccountDashboard';
import { AccountDashboardErrorComponent } from '@/components/AccountDashboard/AccountDashboardErrorComponent';
import { ensureAccountUser } from '@/router/accountUser';
import { createRouteErrorComponent } from '@/router/createRouteErrorComponent';
import { hasPermission, hasPrivilege } from '@/utils/access-control';
import { Route as AccountIdRoute } from '../_authenticated/accounts_/$accountId';

export const Route = createRoute({
  getParentRoute: () => AccountIdRoute,
  id: 'account-dashboard',
  context: ({ params: { accountId } }) => ({
    paymentMethodsQueryOptions: getPaymentMethodsQuery({ account_id: accountId }),
  }),
  beforeLoad: async ({ context: { queryClient }, params: { accountId } }) => {
    const userAccount = await ensureAccountUser({ queryClient, accountId });

    if (hasPrivilege({ privileges: ['CLOUD_RBAC'], userAccount })) {
      const canWriteClusters = hasPermission({ permissions: ['write:clusters'], userAccount });
      const canReadClusters = hasPermission({ permissions: ['read:clusters'], userAccount });
      const canWriteApiKeys = hasPermission({ permissions: ['write:api_keys'], userAccount });
      const canReadAPIKeys = hasPermission({ permissions: ['read:api_keys'], userAccount });

      // The Overview stepper needs all of the following permissions to be able to show the stepper cards
      // the design needs to be thought out more.
      if (!canWriteClusters || !canWriteApiKeys || !canReadAPIKeys || !canReadClusters) {
        return {
          accountApiKeysQueryOptions: null,
          accountJwtsQueryOptions: null,
        };
      }
    }

    return {
      accountApiKeysQueryOptions: getAccountApiKeysQuery({ account_id: accountId }),
      accountJwtsQueryOptions: getAccountJwts({ account_id: accountId }),
    };
  },
  component: function AccountDashboardComponent() {
    if (useChildMatchesClusterSetup()) {
      return <Outlet />;
    }

    return (
      <AccountDashboard>
        <Outlet />
      </AccountDashboard>
    );
  },
  errorComponent: createRouteErrorComponent(function AccountDashboardErrorComponentImpl(props) {
    if (useChildMatchesClusterSetup()) {
      return (
        <AccountDashboardOutline>
          <AccountDashboardErrorComponent {...props} />
        </AccountDashboardOutline>
      );
    }

    return (
      <AccountDashboard>
        <AccountDashboardErrorComponent {...props} />
      </AccountDashboard>
    );
  }),
});

function useChildMatchesClusterSetup() {
  return useChildMatches({
    select: (matches) =>
      matches.find(
        (match) =>
          match.fullPath === '/accounts/$accountId/clusters/$clusterId/scale' ||
          match.fullPath === '/accounts/$accountId/clusters/create',
      ),
  });
}
