import { Box, Button, ListItem } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { useTrackInteractionEvent } from '@/hooks/use-event-tracking';
import { SectionItem } from '.';

export const AccountDashboardSidebarItem = ({
  title,
  href,
  icon,
}: {
  title: string;
} & Pick<SectionItem, 'title' | 'href' | 'icon'>) => {
  const trackClick = useTrackInteractionEvent({
    label: title,
    location: 'Sidebar',
    action: 'clicked',
  });

  return (
    <ListItem
      role="menuitem"
      disableGutters={true}
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      onClick={() => trackClick()}
    >
      <Link
        style={{ display: 'block', width: '100%', textDecoration: 'none' }}
        to={href}
        activeOptions={{ exact: false }}
      >
        {({ isActive }) => (
          <Button
            startIcon={icon}
            disableRipple={true}
            sx={{
              borderRadius: 1,
              color: 'neutral.300',
              justifyContent: 'flex-start',
              pl: `24px`,
              pr: 3,
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              ...(isActive && {
                backgroundColor: 'rgba(255,255,255, 0.08)',
                color: 'secondary.main',
                fontWeight: 'fontWeightBold',
              }),
              '& .MuiButton-startIcon': {
                color: isActive ? 'secondary.main' : 'neutral.400',
              },
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.08)',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
          </Button>
        )}
      </Link>
    </ListItem>
  );
};
