// Restricted import only for outside this component.
// eslint-disable-next-line no-restricted-imports
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar as useNotistackSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { SnackbarDismissButton } from '@/components/Common/Snackbar/SnackbarDismissButton';

export { enqueueSnackbar, closeSnackbar } from 'notistack';

/**
 * Customizes the default behavior of the notistack's enqueueSnackbar hook method
 * to default the error variant to persist and stay visible unless dismissed by the user.
 * This default can be overridden via options. E.g:
 * enqueueSnackbar('your message', { variant: 'error', persist: false });
 */
export const useSnackbar = () => {
  const { enqueueSnackbar: originalEnqueueSnackbar, ...otherUtils } = useNotistackSnackbar();

  const defaultOpts = useMemo<OptionsObject>(
    () => ({
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    }),
    [],
  );

  const enqueueErrorSnackbar = useCallback(
    (msg: SnackbarMessage, options: OptionsObject) => {
      // Unless configured, default persist to true and set a closing button.
      if (options.persist === undefined) {
        options.persist = true;
        if (options.action === undefined) {
          options.action = SnackbarDismissButton;
        }
      }
      return originalEnqueueSnackbar(msg, options);
    },
    [originalEnqueueSnackbar],
  );

  const enqueueSnackbar = useCallback(
    (msg: SnackbarMessage, options?: OptionsObject): SnackbarKey => {
      if (options?.variant === 'error') {
        return enqueueErrorSnackbar(msg, { ...defaultOpts, ...options });
      }
      return originalEnqueueSnackbar(msg, { ...defaultOpts, ...options });
    },
    [enqueueErrorSnackbar, originalEnqueueSnackbar, defaultOpts],
  );

  return {
    enqueueSnackbar,
    ...otherUtils,
  };
};
