import { Box, Button, Typography, styled } from '@mui/material';
import { ErrorComponentProps, getRouteApi, useRouter } from '@tanstack/react-router';
import { userEndpoints } from '@/api/services/accounts-endpoints';
import { ButtonLink } from '@/router/Link';
import { isModuleNotFoundError } from '@/utils/error-utils';
import { AccountDashboardOutline, DASHBOARD_LAYOUT_GAP } from '.';

const StyledAuthenticatedErrorComponent = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
  margin: 0 ${theme.spacing(4)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${DASHBOARD_LAYOUT_GAP});
  text-align: center;
  text-wrap: balance;
`,
);

const { useRouteContext } = getRouteApi('/authenticated');

/**
 * The button "Try again" resets the IAM api state and invalidates the route to reload the loader.
 * This allows any error during account retrival or creation to be retried.
 */
export const AuthenticatedErrorComponent = ({ error, reset }: ErrorComponentProps) => {
  const router = useRouter();
  const { queryClient } = useRouteContext();

  return (
    <AccountDashboardOutline>
      <StyledAuthenticatedErrorComponent role="alert">
        <Typography variant="h5" component="p">
          An error has occurred. Our team is aware of the issue and is working to resolve it.
        </Typography>
        <Typography variant="h5" component="p" sx={{ mb: 2 }}>
          Please try again later.
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (isModuleNotFoundError(error)) {
                return window.location.reload();
              }

              reset();

              return Promise.all([
                // reset IAM api state
                queryClient.resetQueries({ queryKey: [userEndpoints.userMe] }),
                // Invalidate the route to reload the loader, which will also reset the error boundary
                router.invalidate(),
              ]);
            }}
          >
            Try again
          </Button>
          or
          <ButtonLink variant="outlined" to="/logout">
            Logout
          </ButtonLink>
        </Box>
      </StyledAuthenticatedErrorComponent>
    </AccountDashboardOutline>
  );
};
