import { createRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { getAccountApiKeysQuery } from '@/api/services/authentication';
import { getClusterJwtsQuery } from '@/api/services/cluster';
import { featureFlagsQueryOptions } from '@/api/services/public';
import { MIN_CLUSTER_VERSION_FOR_RBAC } from '@/components/Access/RBAC/utils';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { hasClusterRbacEnabled, isClusterHybridCloud } from '@/utils/cluster-utils';
import { isAtLeastVersion } from '@/utils/helpers';
import { Route as ClusterIdIndexRoute } from '../$clusterId.index_';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => ClusterIdIndexRoute,
    path: 'api-keys',
    staticData: {
      permissions: ['read:api_keys'],
    },
    validateSearch: z.object({
      apiKeyDialog: z.coerce.boolean().nullish(),
      createApiKey: z.coerce.boolean().nullish(),
    }),
    context: ({ params: { accountId, clusterId } }) => ({
      accountApiKeysQueryOptions: getAccountApiKeysQuery({ account_id: accountId }, { cluster_id: clusterId }),
    }),
    beforeLoad: async ({
      context: { queryClient, clusterQueryOptions },
      params: { accountId, clusterId },
      search: { createApiKey },
    }) => {
      const [cluster, { qdrant_clusters_jwt_authentication_enabled: jwtsFlagEnabled }] = await Promise.all([
        queryClient.fetchQuery(clusterQueryOptions),
        queryClient.ensureQueryData(featureFlagsQueryOptions),
      ]);

      const isHybridCloudCluster = isClusterHybridCloud(cluster);

      if (isHybridCloudCluster) {
        throw redirect({
          to: '/accounts/$accountId/clusters/$clusterId/overview',
          params: { accountId, clusterId },
          replace: true,
        });
      }

      const isClusterRbacEnabled = hasClusterRbacEnabled(cluster);
      const clusterIsCompatibleWithRbac = isAtLeastVersion(
        MIN_CLUSTER_VERSION_FOR_RBAC,
        cluster.configuration?.version,
      );
      const jwtsEnabled = Boolean(jwtsFlagEnabled && isClusterRbacEnabled && clusterIsCompatibleWithRbac);
      // If the createApiKey flag is set, but the cluster cannot create RBAC tokens, remove the flag.
      if (createApiKey && !jwtsEnabled) {
        throw redirect({
          to: '/accounts/$accountId/clusters/$clusterId/api-keys',
          params: { accountId, clusterId },
          replace: true,
        });
      }

      if (!jwtsEnabled) {
        return { jwtsFlagEnabled, clusterJwtQueryOptions: null };
      }

      return {
        jwtsFlagEnabled,
        clusterJwtQueryOptions: getClusterJwtsQuery({ account_id: accountId, cluster_id: clusterId }),
      };
    },
    loader: async ({ context: { queryClient, clusterJwtQueryOptions, accountApiKeysQueryOptions } }) => {
      await Promise.all(
        [
          queryClient.fetchQuery(accountApiKeysQueryOptions),
          clusterJwtQueryOptions ? queryClient.fetchQuery(clusterJwtQueryOptions) : undefined,
        ].filter(Boolean),
      );
    },
  }),
).lazy(() => import(/* webpackChunkName: "cluster-api-keys" */ './api-keys.lazy').then(({ Route }) => Route));
