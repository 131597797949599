import BackupIcon from '@mui/icons-material/Backup';
import GroupsIcon from '@mui/icons-material/Groups';
import StorageIcon from '@mui/icons-material/Storage';
import { Box, Divider, Drawer, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { memo, ReactElement, useMemo } from 'react';
import { GetSupportLink } from '@/components/Common/GetSupportLink';
import { Logo } from '@/components/Common/Logo';
import { Scrollbar } from '@/components/Common/Scrollbar';
import { useAccessControl } from '@/hooks/useAccessControl';
import { CreditCard as CreditCardIcon } from '@/icons/credit-card';
import { HybridCloudIconFilled } from '@/icons/hybrid-cloud-icon-filled';
import { UserCircle as UserCircleIcon } from '@/icons/user-circle';
import { router } from '@/router';
import { useAccountId } from '@/router/hooks';
import { Route as BackupsRoute } from '@/routes/(account-dashboard)/backups';
import { Route as BillingRoute } from '@/routes/(account-dashboard)/billing';
import { Route as CloudAccessRoute } from '@/routes/(account-dashboard)/cloud-access_';
import { Route as ClustersRoute } from '@/routes/(account-dashboard)/clusters';
import { Route as HybridCloudRoute } from '@/routes/(account-dashboard)/hybrid-cloud_';
import { Route as ProfileRoute } from '@/routes/(account-dashboard)/profile';
import { AccountDashboardSidebarSection } from './AccountDashboardSidebarSection';

export type SectionItem = {
  key?: string;
  title: string;
  href: string;
  icon: ReactElement;
  hidden?: boolean;
};

const SECTIONS = {
  CLUSTERS: 'clusters',
  HYBRID_CLOUD: 'hybrid-cloud',
  BACKUPS: 'backups',
  ACCESS_MANAGEMENT: 'access-management',
  BILLING: 'billing',
  PROFILE: 'profile',
} as const;

const getSections = (
  skippedSections: string[],
  accountId: string,
  constraints: {
    hasAccessToPaymentInformation: boolean;
    hasAccessToBackupsPage: boolean;
    hasClusterReadAccess: boolean;
    hasAccessToHybridCloud: boolean;
    hasAccessToReadRoles: boolean;
    hasCloudRbacPrivilege: boolean;
    hasAPIKeyReadAccess: boolean;
    hasCloudAPIKeyReadAccess: boolean;
    hasAccountUsersReadAccess: boolean;
  },
): { title: string; items: SectionItem[] }[] => [
  {
    title: 'Dashboard',
    items: [
      {
        key: SECTIONS.CLUSTERS,
        title: 'Clusters',
        href: router.buildLocation({ to: ClustersRoute.to, params: { accountId } }).href,
        icon: <StorageIcon fontSize="small" />,
        hidden: !constraints.hasClusterReadAccess,
      },
      {
        key: SECTIONS.HYBRID_CLOUD,
        title: 'Hybrid Cloud',
        href: router.buildLocation({ to: HybridCloudRoute.to, params: { accountId } }).href,
        icon: <HybridCloudIconFilled fontSize="small" />,
        hidden: !constraints.hasAccessToHybridCloud,
      },
      {
        key: SECTIONS.BACKUPS,
        title: 'Backups',
        href: router.buildLocation({ to: BackupsRoute.to, params: { accountId } }).href,
        icon: <BackupIcon fontSize="small" />,
        hidden: !constraints.hasAccessToBackupsPage,
      },
      {
        key: SECTIONS.ACCESS_MANAGEMENT,
        title: 'Access Management',
        href: router.buildLocation({ to: CloudAccessRoute.to, params: { accountId } }).href,
        icon: <GroupsIcon fontSize="small" />,
        hidden:
          !constraints.hasAccessToReadRoles &&
          !constraints.hasCloudAPIKeyReadAccess &&
          !constraints.hasAccountUsersReadAccess &&
          !constraints.hasAPIKeyReadAccess,
      },
    ].filter(({ key }) => !skippedSections.includes(key)),
  },
  {
    title: 'Settings',
    items: [
      {
        key: SECTIONS.BILLING,
        title: 'Billing',
        href: router.buildLocation({ to: BillingRoute.to, params: { accountId } }).href,
        icon: <CreditCardIcon fontSize="small" />,
        hidden: !constraints.hasAccessToPaymentInformation,
      },
      {
        key: SECTIONS.PROFILE,
        title: 'Profile',
        href: router.buildLocation({ to: ProfileRoute.to, params: { accountId } }).href,
        icon: <UserCircleIcon fontSize="small" />,
      },
    ],
  },
];

export const accountDashboardSidebarWidth = '280px';

export const AccountDashboardSidebar = memo(function AccountDashboardSidebar({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) {
  const accountId = useAccountId();
  const { match: hasAccessToPaymentInformation } = useAccessControl({ permissions: ['read:payment_information'] });
  const { match: hasAccessToBackupsPage } = useAccessControl({ permissions: ['read:backups', 'read:clusters'] });
  const { match: hasClusterReadAccess } = useAccessControl({ permissions: ['read:clusters'] });
  const { match: hasAccessToReadRoles } = useAccessControl({ permissions: ['read:roles'] });
  const { match: hasCloudAPIKeyReadAccess } = useAccessControl({ permissions: ['read:management_keys'] });
  const { match: hasAPIKeyReadAccess } = useAccessControl({ permissions: ['read:api_keys'] });
  const { match: hasAccountUsersReadAccess } = useAccessControl({ permissions: ['read:users'] });
  const { match: hasAccessToHybridCloud } = useAccessControl({ permissions: ['read:hybrid_cloud_environments'] });
  const { match: hasCloudRbacPrivilege } = useAccessControl({ privileges: ['CLOUD_RBAC'] });

  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const sections = useMemo(
    () =>
      getSections([], accountId, {
        hasAccessToPaymentInformation,
        hasAccessToBackupsPage,
        hasClusterReadAccess,
        hasAccessToHybridCloud,
        hasAccessToReadRoles,
        hasCloudRbacPrivilege,
        hasCloudAPIKeyReadAccess,
        hasAPIKeyReadAccess,
        hasAccountUsersReadAccess,
      }),
    [
      accountId,
      hasAccessToPaymentInformation,
      hasAccessToBackupsPage,
      hasClusterReadAccess,
      hasAccessToHybridCloud,
      hasAccessToReadRoles,
      hasCloudRbacPrivilege,
      hasCloudAPIKeyReadAccess,
      hasAPIKeyReadAccess,
      hasAccountUsersReadAccess,
    ],
  );

  const content = (
    <Scrollbar
      ariaLabel="Sidebar"
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Stack direction="column" height="100%">
        <Box sx={{ p: 3 }}>
          <Link to={hasClusterReadAccess ? ClustersRoute.to : ProfileRoute.to} params={{ accountId }}>
            <Logo
              style={{
                height: 'auto',
                width: '70%',
              }}
            />
          </Link>
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }} role="navigation" aria-label="Main">
          {sections.map((section) => (
            <AccountDashboardSidebarSection
              key={section.title}
              sx={{
                mt: 2,
                '& + &': {
                  mt: 2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748', // dark divider
          }}
        />
        <Box sx={{ p: 2 }}>
          <Typography color="neutral.100" variant="subtitle2" sx={{ mb: 2 }}>
            Need Help?
          </Typography>
          <GetSupportLink />
        </Box>
      </Stack>
    </Scrollbar>
  );

  const sx = {
    width: accountDashboardSidebarWidth,
  };

  return (
    <Drawer
      anchor="left"
      open={lgUp || open}
      onClose={lgUp ? undefined : onClose}
      variant={lgUp ? 'permanent' : 'temporary'}
      sx={lgUp ? sx : { ...sx, zIndex: (theme) => theme.zIndex.appBar + 100 }}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          borderRightColor: 'divider',
          borderRightStyle: 'solid',
          borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
          color: '#FFFFFF',
          width: accountDashboardSidebarWidth,
        },
      }}
    >
      {content}
    </Drawer>
  );
});
