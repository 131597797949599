import { Box, Link } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { useCookieHub } from '@/contexts/cookie-hub-context';

export const PageFooter = memo(function PageFooter() {
  const theme = useTheme();
  const cookieHub = useCookieHub();

  return (
    <Box component="footer">
      <Box
        component="nav"
        aria-label="Footer"
        textAlign="center"
        sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', rowGap: '6px' }}
      >
        <span>
          <Link
            href="https://status.qdrant.io/"
            target="_blank"
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ mx: 2 }}
          >
            Status
          </Link>
        </span>
        <span>
          <Link
            href="https://qdrant.tech/documentation/cloud/"
            target="_blank"
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ mx: 2 }}
          >
            Documentation
          </Link>
        </span>
        <span>
          <Link
            href="/service-agreement"
            target="_blank"
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ mx: 2 }}
          >
            Terms
          </Link>
        </span>
        <span>
          <Link
            href="/privacy-policy"
            target="_blank"
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ mx: 2 }}
          >
            Privacy
          </Link>
        </span>
        <span>
          <Link href="/sla" target="_blank" variant="body2" color={theme.palette.text.secondary} sx={{ mx: 2 }}>
            SLA
          </Link>
        </span>
        <span>
          <Link
            href="https://qdrant.to/trust-center"
            target="_blank"
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ mx: 2 }}
          >
            Security & Compliance
          </Link>
        </span>
        {cookieHub.isLoaded && (
          <span>
            <Link
              href="#"
              target="_blank"
              variant="body2"
              color={theme.palette.text.secondary}
              sx={{ mx: 2 }}
              onClick={(event) => {
                event.preventDefault();
                cookieHub.openDialog();
              }}
            >
              Cookie consent
            </Link>
          </span>
        )}
      </Box>
    </Box>
  );
});
