import { queryOptions, useMutation } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { userEndpoints } from '@/api/services/accounts-endpoints';
import { useRootContext } from '@/router/hooks';
import { client } from '../query-client';
import {
  ComponentSchema,
  FromPathParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from './utils';

export type AccountDto = ComponentSchema<'UserAccount'>;

export const endpoints = {
  accountJwts: '/accounts/{account_id}/auth/jwts',
  accounts: '/accounts',
  account: '/accounts/{account_id}',
} as const;

export const getAccountJwts = (params: FromPathParams<QueryOptions<typeof endpoints.accountJwts>>) =>
  queryOptions<QueryData<typeof endpoints.accountJwts>>({
    queryKey: queryKey.get(endpoints.accountJwts, params),
  });

/** Mutation only used in Route which cannot leverage useMutation(); also we do not use the returned data for the Cache store for now */
export const createAccountMutationFn = async <
  TOptions extends MutationOptions<typeof endpoints.accounts, 'post'>,
  TVariables extends TOptions['body'],
>(
  body: Pick<TVariables, 'name' | 'owner_id'>,
) => {
  const { data } = await client.POST(endpoints.accounts, {
    body: { ...body, is_business: null, send_alert_to_all: null },
  });
  return data!;
};

export const useDeleteAccountMutation = <
  TOptions extends MutationOptions<typeof endpoints.account, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>() => {
  const router = useRouter();

  return useMutation({
    mutationFn: async (params: TParams) => {
      const { data } = await client.DELETE(endpoints.account, toPathParams(params));
      return data!;
    },
    onSuccess: () => router.commitLocation(router.buildLocation({ to: '/logout' })),
  });
};

export const useUpdateAccountNameMutation = <
  TOptions extends MutationOptions<typeof endpoints.account, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationKey: queryKey.patch(endpoints.account, params),
    mutationFn: async ({ name }: Pick<TVariables, 'name'>) => {
      const { data } = await client.PATCH(endpoints.account, {
        ...toPathParams(params),
        body: { name, is_business: null, send_alert_to_all: null },
      });
      return data!;
    },
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }), router.invalidate()]),
  });
};
