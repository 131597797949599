export const accountEndpoints = {
  roles: '/accounts/{account_id}/auth/roles',
  detail: '/accounts/{account_id}/auth/roles/{role_id}',
  permissions: '/accounts/{account_id}/auth/permissions',
} as const;

export const userEndpoints = {
  userMe: '/users/me',
  userConsent: '/users/consent',
  userInvites: '/users/invites',
  userInvite: '/users/invites/{invite_id}',
  user: '/users/{user_id}',
  userAccount: '/users/{user_id}/accounts/{account_id}',

  accounts: '/accounts',
  accountUsers: '/accounts/{account_id}/users',
  accountUser: '/accounts/{account_id}/users/{user_id}',
  accountUserRoles: '/accounts/{account_id}/auth/users/{user_id}/roles',
  accountInvites: '/accounts/{account_id}/invites',
  accountInvite: '/accounts/{account_id}/invites/{invite_id}',
} as const;
