import { z, ZodType } from 'zod';
import { BookingPackageDto } from '@/api/services/booking';
import { CloudRegion } from '@/api/services/cluster';
import { getDefaultRegion } from '../Clusters/ClusterSetup/ClusterRegionList';
import { getPricedEntityRates, toPackageResourcesObject } from '../Clusters/ClusterSetup/helpers';
import { CLOUD_PROVIDER_MAP, CLOUD_PROVIDERS } from '../Clusters/constants';
import { transformBytesToGigabytes } from '../Clusters/helpers';

const SCALAR_QUANTIZATION_DIVIDER = 4;
const BINARY_QUANTIZATION_DIVIDER = 32;
const PRODUCT_QUANTIZATION_DIVIDER = 64;

export const QUANTIZATIONS = ['Scalar', 'Product', 'Binary', 'None'] as const;
export type Quantization = (typeof QUANTIZATIONS)[number];
export const DEFAULT_QUANTIZATION: Quantization = 'None';

type PackageWithExtractedResources = BookingPackageDto & { memoryResource?: number; cpuResource?: number };

export const findPackageWithMostRAMAndLeastCPU = (packages: BookingPackageDto[]): BookingPackageDto | undefined => {
  let maxRamAmount = 0;
  let packageCPU: number | undefined;
  let packageWithMostRAM: BookingPackageDto | undefined = undefined;

  packages.forEach((pkg) => {
    const { memory: ramAmount = 0, cpu: cpuAmount = 0 } = toPackageResourcesObject(pkg);
    // Find the package with the most RAM and the lowest CPU
    if (ramAmount > maxRamAmount || (ramAmount === maxRamAmount && packageCPU && cpuAmount < packageCPU)) {
      maxRamAmount = ramAmount;
      packageWithMostRAM = pkg;
      packageCPU = cpuAmount;
    }
  });

  return packageWithMostRAM;
};

export const getPackageByMemory = (
  memory?: number,
  packages?: BookingPackageDto[],
): PackageWithExtractedResources | undefined => {
  if (!packages || !memory) {
    return undefined;
  }

  // Filter packages based on those that have a memory resource configuration matching or exceeding the required memory
  const suitablePackages = packages.reduce<PackageWithExtractedResources[]>((acc, pkg) => {
    const memoryResource = pkg.resource_configuration.find(
      (rc) => rc.resource_option?.resource_type === 'ram' && rc.amount >= memory,
    )?.amount;
    const cpuResource = pkg.resource_configuration.find((rc) => rc.resource_option?.resource_type === 'cpu')?.amount;

    // Only packages with defined memoryResource and cpuResource are suitable and thus, included
    if (memoryResource !== undefined && cpuResource !== undefined) {
      acc.push({
        ...pkg,
        memoryResource,
        cpuResource,
      });
    }

    return acc;
  }, []);

  // If no suitable package was found it means the required memory exceeds the maximum memory available in the packages.
  // Return the package with the highest memory available
  if (suitablePackages.length === 0) {
    return findPackageWithMostRAMAndLeastCPU(packages);
  }

  // Group packages by memoryResource (to later find the package with the lowest CPU in each group)
  const groupedByMemory = suitablePackages.reduce<Record<number, PackageWithExtractedResources[]>>((groups, pkg) => {
    const ramGroup = pkg.memoryResource!;
    if (!groups[ramGroup]) {
      groups[ramGroup] = [];
    }
    groups[ramGroup].push(pkg);
    return groups;
  }, {});

  // For each group, find the package with the lowest CPU
  const lowestCPUPackages = Object.values(groupedByMemory).map((group) =>
    // All packages within groupedByMemory have cpuResource defined due to the filtering
    // First package is taken as the initial lowest CPU package by default.
    group.reduce((lowest, pkg) => (pkg.cpuResource! < lowest.cpuResource! ? pkg : lowest)),
  );

  // Sort by memoryResource to prioritize lower memory usage
  lowestCPUPackages.sort((a, b) => a.memoryResource! - b.memoryResource!);

  return lowestCPUPackages[0] || undefined;
};

export const getHighestMemoryAvailable = (packages: BookingPackageDto[]): number => {
  if (!packages.length) {
    return 1;
  }

  const memoryAmounts = packages.map(
    (pkg) =>
      pkg.resource_configuration.find((resourceConfig) => resourceConfig.resource_option?.resource_type === 'ram')
        ?.amount ?? 0,
  );
  return Math.max(...memoryAmounts, 1);
};

const VECTOR_SIZE_IN_BYTES = 4;
const METADATA_AND_OPTIMIZATION_MULTIPLIER = 1.5;

const getBaseRamGB = (vectors: number, dimension: number) =>
  transformBytesToGigabytes(vectors * dimension * VECTOR_SIZE_IN_BYTES * METADATA_AND_OPTIMIZATION_MULTIPLIER);

const getQuantizedValue = (value: number, quantization: Quantization) => {
  switch (quantization) {
    case 'Scalar':
      return value / SCALAR_QUANTIZATION_DIVIDER;
    case 'Product':
      return value / PRODUCT_QUANTIZATION_DIVIDER;
    case 'Binary':
      return value / BINARY_QUANTIZATION_DIVIDER;
    default:
      return value;
  }
};

/**
 * Calculates the minimum required memory for a Qdrant cluster based on the provided
 * configuration (vectors, dimensions and if it's storage optimized).
 * See https://qdrant.tech/documentation/guides/capacity-planning/#basic-configuration for more details.
 */
export const getMinimumRequiredMemory = ({
  vectors,
  dimension,
  storageOptimized,
  storageRAMCachePercentage,
  quantization,
}: {
  vectors: number;
  dimension: number;
  storageOptimized?: boolean;
  storageRAMCachePercentage?: number;
  quantization: Quantization;
}) => {
  // Extra 50% is needed for metadata (indexes, point versions, etc.) as well as for temporary
  // segments constructed during the optimization process.
  let calculatedMemoryInGb = getBaseRamGB(vectors, dimension);
  calculatedMemoryInGb = getQuantizedValue(calculatedMemoryInGb, quantization);

  if (storageOptimized && storageRAMCachePercentage) {
    calculatedMemoryInGb *= storageRAMCachePercentage / 100;
  }

  return Math.ceil(calculatedMemoryInGb);
};

export const getMinimumRequiredDisk = ({
  vectors,
  dimension,
  quantization,
}: {
  vectors: number;
  dimension: number;
  quantization: Quantization;
}) => {
  // Base disk equals the base RAM
  const baseRam = getBaseRamGB(vectors, dimension);
  let requiredDisk = baseRam;
  if (quantization !== 'None') {
    const quantizedRam = getQuantizedValue(baseRam, quantization);
    requiredDisk += quantizedRam;
  }

  return Math.ceil(requiredDisk);
};

export const MAX_VECTORS = 100_000_000_000;
export const MAX_DIMENSIONS = 65_536;
export const MAX_REPLICAS = 100;
export const DEFAULT_CALCULATOR_PROVIDER = CLOUD_PROVIDER_MAP.AWS;

const getTransformFunc = (maxValue: number) => (value: number) => {
  if (value < 0) {
    return 0;
  }

  if (value > maxValue) {
    return maxValue;
  }

  return value;
};

export const calculatorSchema = z.object({
  vectors: z.coerce
    .number()
    .int('Vectors must be an integer')
    .positive('Vectors must be positive')
    .max(MAX_VECTORS)
    .transform(getTransformFunc(MAX_VECTORS)),
  dimension: z.coerce
    .number()
    .int('Dimension must be an integer')
    .positive('Dimension must be positive')
    .max(MAX_DIMENSIONS)
    .transform(getTransformFunc(MAX_DIMENSIONS)),
  replicas: z.coerce
    .number()
    .int('Replicas must be an integer')
    .positive('Replicas must be positive')
    .min(1)
    .max(MAX_REPLICAS)
    .transform(getTransformFunc(MAX_REPLICAS)),
  quantization: z.enum(QUANTIZATIONS, { message: 'Invalid quantization' }),
  storageOptimized: z.boolean(),
  storageRAMCachePercentage: z.coerce
    .number()
    .int('Storage RAM cache percentage must be an integer')
    .min(10)
    .max(100)
    .transform(getTransformFunc(35)),
});

export const getPackagesWithLowestCPUSortedByRAM = (packages: BookingPackageDto[]): BookingPackageDto[] => {
  const groupedPackages = new Map<number, BookingPackageDto>();

  packages.forEach((pkg: BookingPackageDto) => {
    const { memory: ramAmount = 0, cpu: currentPackageCpu = 0 } = toPackageResourcesObject(pkg);

    // Check if there's already a package for this RAM amount
    const existingPackage = groupedPackages.get(ramAmount);
    if (!existingPackage) {
      groupedPackages.set(ramAmount, pkg);
    } else {
      // Compare CPU of the current package with the stored one to replace if it's lower
      const { cpu: existingPackageCpu = 0 } = toPackageResourcesObject(existingPackage);

      if (currentPackageCpu < existingPackageCpu) {
        groupedPackages.set(ramAmount, pkg);
      }
    }
  });

  // Sort packages by RAM
  const sortedPackages = new Map([...groupedPackages].sort((a, b) => a[0] - b[0]));
  // Return the packages with the lowest CPU for each RAM group
  return Array.from(sortedPackages.values());
};

type DBClusterConfiguration = {
  package: BookingPackageDto;
  nodes: number;
  extraDisk: number;
};

export function findOptimalDBClusterConfiguration(
  packages: BookingPackageDto[],
  targetRam: number,
  targetDisk: number,
  replicationFactor: number,
): DBClusterConfiguration | null {
  // Calculate the effective target RAM
  const effectiveTargetRam = targetRam * replicationFactor;
  const effectiveTargetDisk = targetDisk * replicationFactor;

  // Initialize variables to track the best configuration
  let bestConfiguration: DBClusterConfiguration | null = null;
  let leastSpareRam = Infinity;
  let cheapestMonthlyPrice = Infinity;
  let leastNodes = Infinity;

  const minNodes = replicationFactor > 1 ? 3 : 1;

  // Iterate over each RAM package
  for (const pkg of packages) {
    const { memory: ram = 0, disk = 0 } = toPackageResourcesObject(pkg);
    // Calculate the number of nodes needed
    for (let nodes = minNodes; nodes <= MAX_REPLICAS; nodes++) {
      const totalRam = ram * nodes;
      const rates = getPricedEntityRates({ entity: pkg, multiplier: nodes });
      // No rates means no pricing, so this package can't be used
      if (!rates) {
        continue;
      }

      // Check if the total RAM is sufficient
      if (totalRam >= effectiveTargetRam) {
        const spareRam = totalRam - effectiveTargetRam;
        if (
          rates.monthlyTotal < cheapestMonthlyPrice ||
          (nodes < leastNodes && rates.monthlyTotal < cheapestMonthlyPrice * 1.15) ||
          (rates.monthlyTotal === cheapestMonthlyPrice && (nodes < leastNodes || spareRam < leastSpareRam))
        ) {
          // Update the best configuration if this one is the most efficient cost/spare ram-wise
          leastNodes = nodes;
          leastSpareRam = spareRam;
          cheapestMonthlyPrice = rates.monthlyTotal;
          const totalAvailableDisk = disk * nodes;
          bestConfiguration = {
            package: pkg,
            nodes,
            extraDisk:
              totalAvailableDisk >= effectiveTargetDisk ? 0 : Math.ceil((effectiveTargetDisk - disk * nodes) / nodes),
          };
        }

        // Break out of the loop as we don't need more nodes for this RAM package
        break;
      }
    }
  }

  return bestConfiguration;
}

function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}

export const calculatorSearchSchema = z
  .object({
    vectors: z.coerce
      .number()
      .int()
      .min(0)
      .optional()
      .or(fallback(0))
      .pipe(z.number().max(MAX_VECTORS).optional().or(fallback(MAX_VECTORS))),
    dimension: z.coerce
      .number()
      .int()
      .min(0)
      .optional()
      .or(fallback(0))
      .pipe(z.number().max(MAX_DIMENSIONS).optional().or(fallback(MAX_DIMENSIONS))),
    storageOptimized: z.coerce.boolean().or(fallback(false)),
    replicas: z.coerce
      .number()
      .int()
      .min(1)
      .optional()
      .or(fallback(1))
      .pipe(z.number().max(MAX_REPLICAS).optional().or(fallback(MAX_REPLICAS))),
    provider: z.enum(CLOUD_PROVIDERS).or(fallback(DEFAULT_CALCULATOR_PROVIDER)),
    region: z
      .string()
      .or(fallback(undefined))
      .transform((region) => region as CloudRegion),
    quantization: z.enum(QUANTIZATIONS).or(fallback(DEFAULT_QUANTIZATION)),
    storageRAMCachePercentage: z.coerce
      .number()
      .int('Storage RAM cache percentage must be an integer')
      .min(10)
      .max(100)
      .optional()
      .or(fallback(35))
      .pipe(z.number().max(100).optional().or(fallback(100))),
  })
  .transform(({ provider, region, ...rest }) => ({
    provider,
    region: getDefaultRegion(provider, region),
    ...rest,
  }));

export function calculatorCreateSearch<T extends Partial<z.infer<typeof calculatorSearchSchema>>>(params: T) {
  return calculatorSearchSchema.parse(params);
}
