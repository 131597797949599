import { ComponentSchema } from '@/api/services/utils';
import { CurrentAccountUser } from '@/router/hooks';

export type AccountPrivileges = ComponentSchema<'PrivilegeNameEnum'>[];
export type AccountPermissions = ComponentSchema<'UserAccount'>['permissions'];

export function hasPermission({
  permissions: accountPermissions,
  userAccount: {
    account: { permissions = [], privileges = [] },
  },
}: {
  permissions: AccountPermissions;
  userAccount: CurrentAccountUser;
}) {
  if (accountPermissions.length === 0) {
    throw new Error("hasPermission(): 'permissions' are required when using this function.");
  }

  // Check if the user hasn't got the 'CLOUD_RBAC' privilege, and there are missing permissions.
  // Remove this check when we do not depend on the 'CLOUD_RBAC' privilege to apply permission checking.
  if (!privileges.find((p) => p === 'CLOUD_RBAC')) {
    return true;
  }

  return accountPermissions.every((p) => permissions.includes(p));
}

export function hasPrivilege({
  privileges: accountPrivileges,
  userAccount: {
    account: { privileges = [] },
  },
}: {
  privileges: AccountPrivileges;
  userAccount: CurrentAccountUser;
}) {
  if (accountPrivileges.length === 0) {
    throw new Error("hasPrivilege(): 'privileges' are required when using this function.");
  }

  return accountPrivileges.every((p) => privileges.includes(p));
}
