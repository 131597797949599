import { CloudProvider } from '@/api/services/cluster';
import {
  CLOUD_PROVIDER_GEOGRAPHY_MAP,
  FREE_TIER_CLOUD_PROVIDERS,
  FREE_TIER_GEOGRAPHIES,
  ProviderGeography,
  ProviderRegion,
} from '@/components/Clusters/constants';
import { ClusterTemplateType } from '../ClusterTemplateList';

export function useProviderRegions({
  provider,
  clusterTemplate,
}: {
  provider: Exclude<CloudProvider, 'private'>;
  clusterTemplate?: ClusterTemplateType;
}) {
  return (Object.entries(CLOUD_PROVIDER_GEOGRAPHY_MAP[provider]) as [ProviderGeography, ProviderRegion[]][]).filter(
    // For free tiers only allow default region
    ([geography]) =>
      clusterTemplate === undefined ||
      clusterTemplate !== 'free' ||
      (FREE_TIER_CLOUD_PROVIDERS.includes(provider) && FREE_TIER_GEOGRAPHIES.includes(geography)),
  );
}
