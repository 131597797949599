import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo } from 'react';
import { settingsQueryOptions } from '@/api/services/public';

const sxProps: SxProps<Theme> = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.neutral[100],
  padding: theme.spacing(1, 2),
});

export const IncidentBanner = memo(function IncidentBanner() {
  const { data: incident } = useQuery({ ...settingsQueryOptions, select: ({ incident }) => incident });

  if (!incident) {
    return;
  }

  return (
    <Box sx={sxProps}>
      <Typography variant="body2" color="text.primary">
        We are experiencing service disruptions. Please visit our{' '}
        <Link href="https://status.qdrant.io/" target="_blank" variant="body2" underline="always">
          status page
        </Link>{' '}
        for updates.
      </Typography>
    </Box>
  );
});
