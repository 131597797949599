import { PathParamError } from '@tanstack/react-router';
import { z, ZodType } from 'zod';
import { FALLBACK_AUTH_ERROR } from '@/api/services/auth';
import { CloudProvider, CloudRegion } from '@/api/services/cluster';
import { MIN_NODES } from '@/components/Clusters/ClusterSetup/ClusterConfiguration/ClusterNodesControl';
import { getDefaultRegion } from '@/components/Clusters/ClusterSetup/ClusterRegionList';
import { ClusterTemplateType } from '@/components/Clusters/ClusterSetup/ClusterTemplateList';
import { CLOUD_PROVIDER_MAP } from '@/components/Clusters/constants';
import { getRandomFreeTierCloudProvider } from '@/components/Clusters/helpers';

function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}

const template = ['free', 'standard'] as const satisfies readonly ClusterTemplateType[];
const cloudProviders = [
  CLOUD_PROVIDER_MAP.AWS,
  CLOUD_PROVIDER_MAP.GCP,
  CLOUD_PROVIDER_MAP.AZURE,
  CLOUD_PROVIDER_MAP.PRIVATE,
] as const satisfies readonly CloudProvider[];

export const clusterSetupSearchSchema = z.object({
  pid: z.string().optional(),
  nodes: z.coerce.number().min(MIN_NODES).optional().or(fallback(MIN_NODES)),
  disk: z.coerce.number().min(0).optional().or(fallback(0)),
});

export type ClusterSetupSearchParams = z.infer<typeof clusterSetupSearchSchema>;

export const clusterCreateSearchSchema = z
  .object({
    name: z.coerce.string().optional(),
    template: z.enum(template).catch(template[1]),
    provider: z.enum(cloudProviders).catch(getRandomFreeTierCloudProvider()),
    region: z
      .string()
      .catch(undefined as unknown as string)
      .transform((region) => region as CloudRegion),
    hybridCloudEnv: z.string().optional(),
  })
  .transform(({ provider, region, ...rest }) => {
    const result = {
      provider,
      region: getDefaultRegion(provider, region),
      ...rest,
    };
    if (provider !== CLOUD_PROVIDER_MAP.PRIVATE) {
      const { hybridCloudEnv, ...rest } = result;
      return rest as typeof result;
    }
    return result;
  });

export type ClusterCreateSearchParams = z.infer<typeof clusterCreateSearchSchema>;

const clusterCreateSearchDefaultParams: Partial<ClusterCreateSearchParams> = {
  template: 'standard',
};

/**
 * Parses and validates the provided search parameters for cluster creation.
 *
 * @param {string} [params.template='standard'] - The template type for the cluster. Defaults to 'standard'.
 */
export function clusterCreateSearch(params = clusterCreateSearchDefaultParams): ClusterCreateSearchParams {
  return clusterCreateSearchSchema.parse({ ...clusterCreateSearchDefaultParams, ...params });
}

export const utmParamsSchema = z.object({
  /**
   * Google click identifier.
   */
  gclid: z.string().optional(),
  /**
   * gbraid (Google Braid) and wbraid (Web Braid): two new parameters designed to measure ad performance while respecting privacy policies.
   * Google unique identifier to each ad click. wbraid: Web-to-App; gbraid: App-to-App.
   */
  gbraid: z.string().optional(),
  wbraid: z.string().optional(),
  /**
   * Primarily used in Google AdWords campaigns, this parameter informs you which terms were used in search ads, such as “shorts sale” or “deal on shirts.”
   */
  utm_term: z.coerce.string().optional(),
  /**
   * The site (source), where your user is coming from
   */
  utm_source: z.coerce.string().optional(),
  /**
   * The advertising or marketing media used to reach your site
   */
  utm_medium: z.coerce.string().optional(),
  /**
   * The name of the campaign that defines a particular marketing context
   */
  utm_campaign: z.coerce.string().optional(),
  /**
   * Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link
   */
  utm_content: z.coerce.string().optional(),
});

export const loginSearchSchema = z.object({
  /**
   * Authentication error to display to the user on after log-out.
   */
  aerr: z
    .string()
    .trim()
    .max(255)
    .regex(/^[-\w.*~@+ /:]+$/)
    .catch(FALLBACK_AUTH_ERROR)
    .optional(),
  /**
   * Used for redirection to SSO login.
   */
  sso: z.literal<boolean>(true).optional(),
  /**
   * Used for redirection to SSO login.
   */
  sso_connection: z.string().optional(),
  /**
   * Used for redirection to SSO login.
   */
  sso_login_hint: z.string().optional(),
});

export const rootSearchSchema = z
  .object({
    /**
     * Used for storing the suger transactional ID in a cookie to later assign it to his account.
     */
    sugerEntitlementId: z.string().optional(),
  })
  .merge(utmParamsSchema);

export const accountSearchSchema = z.object({
  /**
   * Stripe Session ID.
   */
  session_id: z.string().optional(),
});

export const hybridCloudSearchSchema = z.object({
  showCleanUpCommands: z.literal<boolean>(true).optional().catch(undefined),
});

/**
 * Parses and validates the provided path parameters for the route. If it fails, a PathParamError error instance is thrown.
 *
 * Example usage:
 *
 * ```ts
 *   path: '$clusterId',
 *   params: parsePathParams(
 *     z.object({
 *       clusterId: z.string().uuid()
 *     }),
 *   ),
 *   onError(err) {
 *    if (isPathParamError(err)) {
 *      throw notFound();
 *    }
 *  }
 * ```
 */
export const parsePathParams = <TParams extends Partial<Record<string, unknown>>>(schema: ZodType<TParams>) => ({
  parse: (params: Required<TParams>) => schema.parse(params),
  stringify: (_: Required<TParams>) => _,
});

export function isPathParamError(err: unknown): err is PathParamError {
  return err instanceof PathParamError;
}
