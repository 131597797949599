import { createRoute, redirect } from '@tanstack/react-router';
import { getAccountRolesQuery } from '@/api/services/access-management';
import { withRouteAccessControl } from '@/router/withRouteAccessControl';
import { Route as AccessManagementTabPanelRoute } from './roles';

export const Route = withRouteAccessControl(
  createRoute({
    getParentRoute: () => AccessManagementTabPanelRoute,
    path: '$roleId',
    staticData: {
      privileges: ['CLOUD_RBAC'],
      permissions: ['read:roles'],
    },
    beforeLoad: async ({ context: { queryClient }, params }) => {
      const { roleId, accountId } = params;
      const roleQueryOptions = getAccountRolesQuery({ account_id: accountId });

      const accountRoles = await queryClient.fetchQuery(roleQueryOptions);
      const doesRoleExist = accountRoles.some((role) => role.id === roleId);
      if (!doesRoleExist) {
        throw redirect({
          to: '/accounts/$accountId/cloud-access',
          params: { accountId },
          replace: true,
        });
      }
    },
  }),
).lazy(() => import(/* webpackChunkName: "role-id" */ './roles.$roleId.lazy').then(({ Route }) => Route));
