import { queryOptions, useMutation } from '@tanstack/react-query';
import { redirect, useRouter } from '@tanstack/react-router';
import { accountEndpoints as accessManagementEndpoints, userEndpoints } from '@/api/services/accounts-endpoints';
import { useRootContext } from '@/router/hooks';
import { client } from '../query-client';
import {
  ComponentSchema,
  FromPathParams,
  FromQueryParams,
  MutationOptions,
  QueryData,
  queryKey,
  QueryOptions,
  toPathParams,
} from './utils';

type AccountDto = ComponentSchema<'UserAccount'>;
export type UserMeDto = QueryData<typeof userEndpoints.userMe>;
export type UserInvitesDto = QueryData<typeof userEndpoints.userInvites>;
export type UserInviteDto = UserInvitesDto[number];
export type UserInviteStatus = Extract<UserInviteDto['status'], 'ACCEPTED' | 'DECLINED'>;
export type AccountUserDto = ComponentSchema<'AccountUserOut'>;
export type UserStatusDto = ComponentSchema<'UserStatus'>;

export const userMeQueryOptions = queryOptions<UserMeDto>({
  queryKey: [userEndpoints.userMe],
  staleTime: Infinity, // has to be stale for the lifetime of the app, and should be invalidated manually
});

/**
 * Returns the current account for the user:
 *
 * - If the accountId param is provided, it will return the account with the given ID
 * - else it will return the default account for the user
 * - otherwise if the accountId causes the account to be missing, it will throw a Router redirect error.
 */
export const selectCurrentAccount = (data: UserMeDto, accountId?: string | null) => {
  const id = accountId ?? data.default_account_id;
  const predicate = id
    ? (account: AccountDto) => account.id === id
    : (account: AccountDto) => account.owner_email === data.email;
  const account = data.accounts?.find(predicate);

  if (!account) {
    const defaultAccountId = data.default_account_id ?? data.accounts?.[0]?.id;
    if (defaultAccountId === undefined) {
      throw new Error('No default account found');
    }
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw redirect({
      to: '/accounts/$accountId/clusters',
      params: { accountId: defaultAccountId },
      replace: true,
    });
  }

  return account;
};

export const getUsersByAccountIdQueryOptions = (
  params: FromPathParams<QueryOptions<typeof userEndpoints.accountUsers>>,
) =>
  queryOptions<QueryData<typeof userEndpoints.accountUsers>>({
    queryKey: queryKey.get(userEndpoints.accountUsers, params),
  });

export const getInvitesByAccountId = (params: FromPathParams<QueryOptions<typeof userEndpoints.accountInvites>>) =>
  queryOptions<QueryData<typeof userEndpoints.accountInvites>>({
    queryKey: queryKey.get(userEndpoints.accountInvites, params),
  });

export const invitesByUserId = queryOptions<UserInvitesDto>({
  queryKey: [userEndpoints.userInvites],
});

export const getUserConsentQuery = (query: FromQueryParams<QueryOptions<typeof userEndpoints.userConsent>>) =>
  queryOptions<QueryData<typeof userEndpoints.userConsent>>({
    queryKey: queryKey.get(userEndpoints.userConsent, undefined, query),
  });

export const useUpdateDefaultAccountMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.user, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>() => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async ({ user_id, default_account_id }: TParams & TVariables) => {
      const { data } = await client.PATCH(userEndpoints.user, {
        ...toPathParams({ user_id }),
        body: { default_account_id },
      });
      return data!;
    },
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }), router.invalidate()]),
  });
};

export const useLeaveAccountMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.userAccount, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>({
  user_id,
}: Pick<TParams, 'user_id'>) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async ({ account_id }: Pick<TParams, 'account_id'>) => {
      const { data } = await client.DELETE(userEndpoints.userAccount, toPathParams({ user_id, account_id }));
      return data!;
    },
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }), router.invalidate()]),
  });
};

export const useUpdateUserInviteMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.userInvite, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: { status: UserInviteStatus }) => {
      const { data } = await client.PATCH(userEndpoints.userInvite, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: (_data, { status }) =>
      Promise.all([
        status === 'ACCEPTED' ? queryClient.invalidateQueries({ queryKey: [userEndpoints.userMe] }) : undefined,
        queryClient.invalidateQueries({ queryKey: invitesByUserId.queryKey }),
        router.invalidate(),
      ]),
  });
};

export const useUpdateUserRolesMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.accountUserRoles, 'patch'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.PATCH(userEndpoints.accountUserRoles, { ...toPathParams(params), body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [userEndpoints.accountUsers, toPathParams(params, { filterKeys: ['account_id'] })],
      });
      await queryClient.invalidateQueries({
        queryKey: [accessManagementEndpoints.roles, toPathParams(params, { filterKeys: ['account_id'] })],
      });
      await router.invalidate();
    },
  });
};

export const useRemoveUserFromAccountMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.accountUser, 'delete'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (args: Pick<TParams, 'user_id'>) => {
      const { data } = await client.DELETE(userEndpoints.accountUser, {
        params: {
          path: {
            account_id: params.account_id,
            user_id: args.user_id,
          },
        },
      });
      return data!;
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKey.get(userEndpoints.accountUsers, params) }),
        router.invalidate(),
      ]),
  });
};

export const useInviteUserToAccountMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.accountInvites, 'post'>,
  TParams extends FromPathParams<Pick<TOptions, 'params'>>,
  TVariables extends TOptions['body'],
>(
  params: TParams,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();
  const pathParams = toPathParams(params);

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(userEndpoints.accountInvites, { ...pathParams, body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey.get(userEndpoints.accountInvites, params) });
      await router.invalidate();
    },
  });
};

export const useRevokeInviteUserToAccountMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.accountInvite, 'delete'>,
  TParams extends FromPathParams<TOptions>,
>(
  params: Pick<TParams, 'account_id'>,
) => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (args: Pick<TParams, 'invite_id'>) => {
      const { data } = await client.DELETE(userEndpoints.accountInvite, {
        params: {
          path: {
            account_id: params.account_id,
            invite_id: args.invite_id,
          },
        },
      });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [userEndpoints.accountInvites] });
      await router.invalidate();
    },
  });
};

export const useStoreUserConsentMutation = <
  TOptions extends MutationOptions<typeof userEndpoints.userConsent, 'post'>,
  TVariables extends TOptions['body'],
>() => {
  const { queryClient } = useRootContext();
  const router = useRouter();

  return useMutation({
    mutationFn: async (body: TVariables) => {
      const { data } = await client.POST(userEndpoints.userConsent, { body });
      return data!;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['/users/consent'] });
      await router.invalidate();
    },
  });
};
