import { Options as AnalyticsOptions } from '@segment/analytics-next';
import { useQuery } from '@tanstack/react-query';
import { useState, ReactNode, useEffect, createContext, useContext, useMemo } from 'react';
import { featureFlagsQueryOptions } from '@/api/services/public';
import { useCookieHub } from '@/contexts/cookie-hub-context';
import { retrieveUtmParamsSession } from '@/utils/analytics-utils';

type EnqueuedCallback = (timestamp?: Date /* when the event took place */) => unknown;

const AnalyticsContext = createContext<
  | {
      enqueueEvent: (callback: EnqueuedCallback) => void;
    }
  | undefined
>(undefined);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider');
  }
  return context;
};

export const eventQueue = (function eventQueue(/* Singleton */) {
  let dispatched = false;
  const queue = new Set<[EnqueuedCallback, Date]>();

  return {
    set dispatchQueue(value: boolean) {
      if (value) {
        queue.forEach(([callback, timestamp]) => {
          callback(timestamp);
        });
        queue.clear();
      }
      dispatched = value;
    },
    enqueue: (callback: EnqueuedCallback) => {
      if (dispatched) {
        callback();
      } else {
        queue.add([callback, new Date()]);
      }
    },
  };
})();

export const AnalyticsProvider = ({ children, disabled = false }: { children: ReactNode; disabled?: boolean }) => {
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const allowedAnalytics = !disabled && analyticsLoaded;
  const { data: featureFlagData } = useQuery(featureFlagsQueryOptions);
  const { hasConsented } = useCookieHub();

  useEffect(() => {
    document.addEventListener(
      'gtm_analytics_loaded',
      () => {
        setAnalyticsLoaded(true);
      },
      { once: true },
    );
  }, []);

  useMemo(() => {
    /**
     * Append campaign traits to every event with Segment Analytics.js
     */
    const campaign = {} as NonNullable<NonNullable<AnalyticsOptions['context']>['campaign']>;
    const utmParams = retrieveUtmParamsSession();

    if (utmParams.utm_campaign) {
      campaign.campaign = utmParams.utm_campaign;
    }
    if (utmParams.utm_source) {
      campaign.source = utmParams.utm_source;
    }
    if (utmParams.utm_medium) {
      campaign.medium = utmParams.utm_medium;
    }
    if (utmParams.utm_term) {
      campaign.term = utmParams.utm_term;
    }
    if (utmParams.utm_content) {
      campaign.content = utmParams.utm_content;
    }

    eventQueue.enqueue(() => {
      void window.analytics.identify({ version: window.__QDRANT_CLOUD__.version, ...campaign });
    });
  }, []);

  useEffect(() => {
    if (featureFlagData) {
      /**
       * Append feature flag traits to every event with Segment Analytics.js
       */
      eventQueue.enqueue(() => {
        const featureFlags = Object.entries(featureFlagData).reduce<Record<`ff_${string}`, boolean>>(
          (acc, [key, value]) => {
            acc[`ff_${key}`] = value;
            return acc;
          },
          {},
        );
        return window.analytics.identify(featureFlags);
      });
    }
  }, [featureFlagData]);

  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    document.addEventListener(
      'gtm_cookiehub_analytics_choice',
      () =>
        /** @see https://segment.com/docs/privacy/consent-management/consent-in-unify/#segment-consent-preference-updated-event */
        eventQueue.enqueue(() =>
          window.analytics.track('segment_consent_preference_updated', undefined, {
            context: {
              consent: {
                categoryPreferences: {
                  Analytics: hasConsented('analytics'),
                  Advertising: hasConsented('marketing'),
                  Functional: hasConsented('necessary'),
                  Preferences: hasConsented('preferences'),
                },
              },
            },
          }),
        ),
      { signal: abortController.signal },
    );
    return () => abortController.abort();
  }, [abortController, hasConsented]);

  const value = useMemo(() => {
    eventQueue.dispatchQueue = allowedAnalytics;
    return { enqueueEvent: eventQueue.enqueue };
  }, [allowedAnalytics]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
